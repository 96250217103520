.rempad{
    padding-left: 0 !important;
}
img{max-width: 100%;object-fit: cover;}
.back_items{
    // color: #797979;
    // font-size: 16px;
    // font-weight: 400;
    // width: 100%;
    // float: left;
    // margin-bottom: 44px;
    span{
        text-decoration: underline;
        text-underline-offset: 4px;
        font-weight: 500;
    }
}
button{
    border:none
}
.fntsize{
    font: normal 600 15px/normal Satoshi !important;

}

.excursion_box{
    width: 1128px;
    height: 248px;
    border-radius: 31px;
    background: #FFF;
    box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.26);
    backdrop-filter: blur(4.5px);
    top: 70px;
    position: relative;
}

/// excursion inputs component
.excursion_inputs_parent{
    padding: 58px 30px 29px 47px;
    margin-top: 10px;
    .excursion_inputs_boxes{
       position: relative;
        grid-template-columns: 294px 29.5px 290px 415.5px auto;
       display: grid;
       
  

       .ex_search{
        @include ex_search;
        width: 132px;
        margin-left: 32px;
        &:hover{
            background-color: #FFBA80;
        }
        
    }
    
    
    }
    .newbtn_added{
        grid-template-columns: 261px 22px 261px 241px 132px ;

    }
    .btnblock{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 58px ;
        position: relative;
    .ex_search{
        @include ex_search;
        width: 228px;
        &:hover{
            background: #FFBA80;
            transition: 0.3s;
        }
    }
    }
    
    
}


/// main excursion component
.mainexcursion_parent{
    position: relative;
    height: 100%;
    float: left;
    width: 100%;
    background-image: url("../../images/background/coconut.svg"),url("../../images/background/sun.svg");
    background-size: 32.5vmax,16vmax;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: calc(0% + 4.7vmax) calc(100vh - 30.4vmax),calc(100% + 6.4vmax) calc(0% - 2.65vmax);
    // @include hide-scrollbar-y;
    // overflow-y: scroll;
    // height:calc(100vh - $navbarHeight);
    // translate: -50% -50%;

    .sub_mainexcursion_parent{
    display: grid;
    grid-template-columns:413px auto;
    gap: 0 42px;
    //margin-top: 81px;
    //margin-top: 81px;
    font-style: normal;
    line-height: normal;
    font-family: Satoshi;
    position: relative;

    .filter_parent{
        background: #fff;
        box-shadow: 6px 0px 6px 0px rgba(0, 0, 0, 0.12);
        padding: 80px 52px 30px 52px;
        height: 100%;

        &.hide-blank{
            height:max-content;
            padding-bottom: 0;
        }

        .filter_list{
           
            margin-top: 47.53px;
            float: left;
        }   
    }
    .sticky-left{
        position: fixed;
        width: 413px;
        top:0;
        //height: 850px;
        
    }
    .reached{
        //position: absolute;
       // height: 850px;
        //bottom: 0;
       // width: 415px;
    }
    .sticky-hotelLeft{
        margin-left: 450px;
    }

    .filter_hotels{
        padding: 74px 0 0 0px;
        background-image: url("../../images/search_back.png");
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        width: 100%;
        float: left;
        display: flex;
    flex-direction: column;
        
        .excursion_places{
            margin: $spaceSearchHeaderResult 0 165px 0;
            position: relative;
            float: left;
            max-width: 1001px;
            .places_hdtxt{
                color: #0F7173;
                font-size: 38px;
                font-weight: 700;
               &::before{
                content: "";
                width: 5px;
                height: 30px;
                background: #FFAB63;
                float: left;
                margin: 10px 16px 0 0;
               } 
            }
            .excursion_subfilter{
                width: auto;
                // position: absolute;
                // right: 0;
                // top: 0;
                        }
            .hotel-skeleton{
                background: url("../../images/loading/accomHotels.png") no-repeat 0px 20px;
                height: 80vw;
            }
        }
    }
    }
}
.filter_list_name{
    color: #0F7173;
    font-size: 22px;
    font-weight: 700;
    background-image: url("../../images/filters.png");
    background-repeat: no-repeat;
    background-size: 17px;
    background-position: 0 center;
    padding-left: 30px;
}
.map-img-filter{
    background:url("../../images/mapFilter.png") left center no-repeat;
    background-size: 309px;
    height: 192px;
    display: grid;
    place-items: center;
    width: 309px;
    border-radius: 16px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    span{
        width: 206px;
        float: left;
        background-color: #fff;
        transition: background-color ease-in-out 100ms;
        color: #0F7173;
        text-align: center;
        border-radius: 17px;
        font-size: 16px;
        font-weight: 700;
        padding: 5px;
        cursor: pointer;
        &:hover{
            background-color: #D6D6D6;
        }
    }
}
.filter_type_name{
    color:$paraHeadColor;
    font-size: 18px;
    font-weight: 700;
    margin:0px 0 25px 0;
   
}
.budget-edit span{
    width: 15px;
    height: 15px;
    background:url("../../images/accomodation/icons/budget-edit.png")no-repeat 0 0;
    background-size: 15px;
    position: absolute;
    margin: 5px 0 0 10px;
    // cursor:pointer;
}
.font-budget-info {
    font-family: Satoshi;
    font-weight: 400;
    font-size: 14px;
    line-height: 18.9px;
    margin-bottom: 31px;
    padding-right: 20px;
}
.range-box-min-max{
    display: flex;
    width: 93%;
    margin-bottom:41px;
    .hidden-input {
            // position: absolute;
            // opacity: 0;
            width: 100%;
            height: 100%;
            border: none;
            background: transparent;
            pointer-events: none;
            color: #434343;
            font-family: Satoshi;
            font-weight: 700;
            font-size: 14px;
            appearance: textfield;
            -moz-appearance:textfield;

        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
            }  
            &:focus{
                outline-width: 0; 
                margin-top: 1px !important;
            }

            // &.min{
            //     padding: 0px 15px 0px 65px;
            //     margin-top: -5.9px;
            // }
        }
    
    
        
        
    .container{
        // position: relative;
        display: flex;
        cursor: text;
        // justify-content: center;
        width: 136px;
        height: 30px;
        // gap: 10px;
        border-radius: 4px;
        padding: 5px;
        background-color: #FFFFFF;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);

        &:hover{
            background-color: #EBEBEB;
        }

        &:has(input:focus){
            border-radius: 4px;
            border: 1px solid #8CB5B0;
            background: #EDEAE0;
            box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
            // .font-range{
            //     margin-top: 0.6px;
            // }
        }
    }
    .font-range{
        font-family: Satoshi;
        font-weight: 700;
        font-size: 14px;
        line-height: 18.9px;
        margin-left: 14px;
        width: 55px;
        margin-top: 0.8px;
    }

    span.hidden-value{
        visibility: hidden;
    }
}

.range-input-box{
    display: flex;
    justify-content: space-between;
    
    input{
        width: 120px;
    }
}

.filter-type-name1{
    @extend.filter_type_name;
    margin-bottom: 20px;
}
.filter_boxes_excursion{
    float: left;
    // margin-bottom: 104px;
    .types-of-filter:not(:last-child){
        margin-bottom: 41px; // malikah
    }

   
}
//show hotels
.hotels_parent{ 
    width:100%;
    max-width: 981px;
    color:#000;
    border-radius: 25px;
    background: #FAFAFA;
    $cardPadding:22px;
    padding: $cardPadding;
    box-sizing: border-box;
    display: grid;
    position: relative;
    grid-template-columns: 306px auto; 
    gap:26px;
    // height: 452px; real data is not match so ak changed
    $cardHeight:441px;
    height:$cardHeight;
    margin-top: 34px;
    box-shadow: 0px 2px 5.5px 0px rgba(0, 0, 0, 0.18);
    @include lineHeight;
    &:first-child {
        margin-top: 0; // Supprime la marge
    }
    .hotel_img{
        //width: 207px; comment based on accom
        .img-hotel-card{
            object-fit: cover;
            width: 306px;
            height:calc($cardHeight - $cardPadding * 2);
            border-radius: 25px;
            box-shadow: 0px 2px 5.5px 0px rgba(0, 0, 0, 0.18);
        }
    }
    .hotel_details{
        position: relative;
        width: 100%;
        .hotel_name{
            color: #0F7173;
            font-size: 20px;
            font-weight: 700;
            cursor: pointer;
            &:hover{
                color:#228C8E;
            }
        }
        .addMargin{
            margin-top: 18px;
        }
        
        .discount-logo{
            position: absolute;
            inset: 0 0 auto auto;
        }

        .rating_location{
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 300;
            color:#434343;
            // padding-left: 13px;
            margin-top: 7px;
            // background: url("../../images/accomodation/icons/hotel-location.svg") no-repeat 0 center;
            
            &::before{
                content:"";
                width:12px;
                height:12px;
                background: url("../../images/accomodation/icons/hotel-location.svg") no-repeat;
                background-size:contain;
                background-position: center;
                margin-right: 4px;
            }
            
            .rating_loc_txt{
                border-right: 1px solid #434343;
                padding-right: 10px;
                height: 13.5px;
                display: flex;
                align-items: center;
                margin-right: 9px;
                color:$paraColor;
            }
            .css-1lauo1g-MuiRating-root{
                font-size: 16.81px !important;
                gap: 1.12px;
            }
        }
        .amenities_list .see-more{
            border: 1px solid #3252A4;
            border-radius: 34px;
            background-color: #3252A4;
            padding: 0px 15px 0px 14px;
            height: 23px;
            color: #ffffff;
            font-size: 14px;
        
            &-img{
                position: relative;
                width: 5px;
                height: 13px;
                left: 4px;
            }
            &:hover{
                background-color:#5A75BB;
            }
        }
        .offers-accom{
            font-size: 14px;
            font-weight: 600;
            color:#C16161;
            margin-top: 7px;
        }
        .hotel_type{
            color: #505050;
            font-size: 16px;
            font-weight: 700;
        }
       .hotel_details_body{
            margin: 0px 0 6px 0;
            color: #505050;
            font-family: Satoshi;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            float: left;
            .chk_avaliability{
                .hotel-card-description{
                    max-height: calc(1.3em * 4 + 0.3em);
                    &.limited-nbr-lines{
                        // display: -webkit-box;
                        // -webkit-box-orient: vertical;
                        // -webkit-line-clamp: 4; 
                        // line-clamp: 4;
                        height: calc(1.3em * 4 + 0.3em);
                        overflow: hidden;
                        text-overflow: clip;
                        color:$paraColor;

                        &>div{
                            height: 100%;
                            background: linear-gradient(#161616,#161616 ),linear-gradient(90deg, #161616 50%, transparent);
                            background-size: 100% calc(100% - 1.3em),100% 1.3em;
                            background-repeat: no-repeat,no-repeat;
                            background-position: top,bottom;
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            }
                    }
                
                }
            }
            .room_env{
                border-radius: 15px;
                width: fit-content;
                padding: 8px 10px 8px 23px;
                height: 25px;
                font-size: 12px;
                font-weight: 500;
                line-height: 8px;
            }
            .enable{
                border: 0.5px solid #486A47;
                color: #758D74;
                background: #F4FEF4 url("../../images/accomodation/icons/room-available.png")no-repeat 10px center ;
            }
            .request{
                border: 0.5px solid #B07A4B;
                color: #B07A4B;
                background: #FFF3E9 url("../../images/accomodation/icons/room-request.png")no-repeat 10px center;
            }
            .no-rooms{
              
                border: 0.5px solid #D56868;
                color: #D56868;
                background: #FFDCDC url("../../images/accomodation/icons/no-rooms.png")no-repeat 10px center;
            }
       }
       .remmargin{
        margin: 0px 0 20px 0;
       }
    }
    .old_price{
        position: absolute;
        top:22px;
        right:22px;
        height: 30px;
        background: url("../../images/offerPrice.png") no-repeat right center;
        color: #F67D7D;
        width: 90px;
        font-size: 18px;
        font-weight: 700;
        span{text-decoration-line: line-through}

    }
}
.maphotel{
    grid-template-columns: 249px auto;
    height: auto;
    .hotel_img{
        img{
            height: 271.8px;
        }
    }
    &_img{
        width:249px !important;
        height: 263px !important;

    }
}


//accesibility
.accesibility_parent{
    position: relative;
    float: left;
   
    .access_loop{
            color: #505050;
            font-family: Satoshi;
            float: left;
        .access_loop_box{
            width: auto;
            display: grid;
            grid-template-columns: 115px auto;
            margin-bottom: 15px;
            font-size: 14px;
           .flagloop{
            border: none !important;
            padding: 0 !important;
            width: 16px;
            float: left;
            margin-right: 4px;
           }
            div:nth-child(1){
                border-right: 1px solid #434343;
                padding-left: 18px;
            }
            div:nth-child(2){
                font-weight: 500;
                padding-left: 10px;
            }
            .box_backimg{
                background-repeat: no-repeat;
                background-position: 0 center;
            }
            .box_backimg1{
                background-image: url("../../images/translationImg.png");
            }
            .box_backimg2{
                background-image: url("../../images/clock.png");
            }
            .box_backimg3{
                background-image: url("../../images/meals.png");
            }
            .box_backimg4{
                background-image: url("../../images/accessibility.png");
            }
        }
    }
   
}

//track excursion
.track_parent{
    position: relative;
    float: left;
    .track_parent_txt{
        color:#434343;
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 28px;
    }
    .track_loop_parent{
        display: flex;
        flex-wrap: wrap;
        overflow: auto;
        row-gap: 20px;
        float: left;
       
        .track_loop_div{
            margin-right: 30px;
            display: grid;
            grid-template-columns: 24px auto;
            align-items: flex-start;
            gap:0 10px;
            width: 260px;
           .track_no{
                width: 24px;
                height: 24px;
                border-radius: 50%;
                @include flexCenter;
                color:#fff;
                background-color: #0F7173;
                margin-right: 10px;
                font-weight: 700;
                font-size: 13px;
            }
            .highlights{
                background-image: url("../../images/highlights.png");
                background-repeat: no-repeat;
                background-repeat: no-repeat;
                width: 24px;
                height: 24px;
                background-position: center;
            }
            .track_name{
                color: #4A4A4A;
                font-size: 14px;
                font-weight: 500;
            }
            .track_location{
                font-size: 13px;
                font-weight: 300;
                background-image: url("../../images/exlocation.png");
                background-repeat: no-repeat;
                background-position: 0 center;
                padding-left: 11px;
            }
        }
    }
    //.loop_updated{display: grid; gap: 15px 0;}
}

// excursion filter
.filterbox_loop{
           
    label{ 
        cursor: pointer;
        margin-bottom: 6px; // malikah;
        display: flex;
        //grid-template-columns: 32px 200px 80px;
        align-items: center;
        color: #161616;
      &:hover{
        color:#0F7173;
        transition: 0.3s;
      }  
      span{
        color: #0F7173;
        font-weight: 500; // adrian
        margin-left: 7px;
    }
    }
    input{
        margin:0 19px 0px 0; // malikah
        width:15px;
        height:15px; 
        cursor: pointer; 
    }
    input::before{
        content:"";
        width:15px;
        height:15px;
        background-color: #EFEFEF;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;
        position: absolute;
        border: none;
        border-radius: 2px;
    }

    input:hover::before{
        background-color: #D9D9D9;
    }

    input:checked::before{
        background-image: url("../../images/checkboxtick.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 13px;
        
    }
    
}
.excursion_filter_parent{
    color: #434343;
    font-family: Satoshi;
    font-size: 14px;
    font-weight: 400;
   float: left;

   &.fixed{
    position: fixed;
   }
    .filter_boxes_excursion{
        width:100%;
        
        .show_more_button{
            color: #0F7173;
            transition: color  ease-out 120ms;
            font-size: 15px;
            font-weight: 600;
            width: fit-content;
            cursor: pointer;
            padding: 0 30px 0 0;
           &:hover{
            color: #0A4E50;
            background: url("../../images/accomodation/filter-hover-arr.png")no-repeat 88% 8px;
            background-size: 8px;
           }
        }
        .hide1{
            background: url("../../images/accomodation/hide1-filter.png")no-repeat 83% 8px;
            //background-size: 9px;
            &:hover{
                background: url("../../images/accomodation/filter-hover-arr-top.png")no-repeat 83% 8px;
                
            }
        }
        .show1{
            background: url("../../images/accomodation/show1-filter.png")no-repeat 88% 8px;
            background-size: 9px;
            margin-top: 12px;
           
        }
        
    }

    .filter_boxes_excursion:nth-child(2),.filter_boxes_excursion:nth-child(3),.filter_boxes_excursion:nth-child(4){
        .filter_type_name{
            margin-top: 38px;
        }
    }

    .hotel_star_rating{
        @include flexCenter;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        margin:20px 0 55px;

        &>*:first-child{
            align-self: flex-start;
        }
    }

    .region-filter{
        @include flexCenter;
        &>*:first-child{
            align-self: flex-start;
        }
    }
}

.filter-summarize{
    // margin:4.07vh 0 4.902867715vh 0;
    $resumeHeight:calc(100vh - 4.07vh - 4.902867715vh - $navbarHeight);
    height: $resumeHeight;
    overflow-x: hidden;
    bottom: 4.902867715vh;
    margin-left:24px;
    position:fixed;
    @include flexSpace;
    align-items: center;
    padding: 29px 0 15px 0;
    // padding: 29px 0 29px 27px;
    flex-direction: column;
    border-radius: 24px;
    background: #FFF;
    box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
    width:398px;
    gap:15px;
    transition: bottom 0.3s ease;

    .filters-scroll{
        @include hide-scrollbar-y;
        @include flexCenter;
        align-items: baseline;
        width: 100%;

        &.bottom-shadowed{
            $resumeBoxShadow:5px;
            box-shadow:inset 0px calc(0px - $resumeBoxShadow) $resumeBoxShadow calc(0px - $resumeBoxShadow) rgba(0, 0, 0, 0.25);
            .filters-container{
                margin-bottom:42px;
            }
        }
    }

    &.follow-scroll{
        position: absolute;
        bottom:10px;
    }

    .filters-container{
        position: relative;
        @include flexStart;
        align-items: flex-start;
        flex-direction: column;
        gap:31px;
        flex: 0 0 auto;
        // max-height:calc($resumeHeight - 58px - 47px);
        
    }

    .button-scroll{
        @include flexCenter;
        height: 38px;
        padding: 8px 41px;
        gap: 8px;
        border-radius: 25px;
        border: 1px solid $myColor7;
        background: #FFF;
        box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
        color: $myColor7;
        font-size: 18px;
        font-weight: 500;
        transition:background-color 0.3s ease;
        width: 300px;

        &::after{
            @include generatePseudoElement("../../images/global-icon/go-top.svg",14px,14px);
            transform:translateY(1px);
            transition: transform 0.3s ease;
        }

        &:hover{
            background: #EDEAE0;
            &::after{
                transform:translateY(-4px);
            }
        }
    }
    .hotel_star_rating{
        .star-icon{
            width: 100%;
            &:last-child{
                margin-bottom: 0px;
            }
        }
        
    }
    .filter_type_name{
        margin-bottom: 20px;
    }
    .filterbox_loop{
        font-size: 14px;
    }
}
// //Multicalendar
.rmdp-container{
  div:nth-child(3){transform:unset !important}
  .rmdp-ep-arrow{display: none;}
  .rmdp-wrapper{
    width: 100% !important;
    //height: 470px; it needs to be dynamic so that calander popup sees well
    height: 480px;
    border-radius: 31px;
    background: #F9F9F9;
    padding: 34px 39px 32px 38px;
    box-sizing: border-box;
   
    .rmdp-border-bottom.rmdp-border-top{border-bottom:unset;border-top:unset}
    
    
    .rmdp-calendar  {
        padding: 0;
        //margin-top: 100px;
        .rmdp-day-picker{
            width: 546px;
            padding: 0;
            margin-left: 7px!important;
            min-height:252px;
        }
        .rmdp-header{
            margin-top: 38px;
            padding: 0px 0 0px;
            div.rmdp-header-values{
                color: #434343;
                font-size: 20px;
                font-weight: 700;
               
            }
            div.rmdp-header-values:hover{
                color: #0F7173;
            }
            div.rmdp-header-values:nth-child(1){margin-left: 0;}
            div.rmdp-header-values:nth-child(2){margin-right: 200px; }
            
        }

        .rmdp-day-picker > div:nth-child(1), .rmdp-day-picker > div:nth-child(2) {
            margin-right: 33px !important; 
            margin-left:11px;
            width: 247px;
            .rmdp-day{
                width: 36px;
                height: 36px;
            }
            .rmdp-day.rmdp-today {
                background-color: #F9F9F9 !important;
                color: #877E7E !important;
                pointer-events: none;
                span{
                    background-color: #F9F9F9 !important;
                    color: #877E7E !important;
                }
                // &:hover{
                //     background-color:#DADCDC !important;
                //     color: #fff;
                //     width: 32px;
                //     height: 32px;
                // }
            }
           
            .rmdp-day span {
                color: #000;
                font-family: Satoshi;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                &:hover{
                    background-color: #DADCDC;
                    color: #fff;
                    
                }
            }

            .rmdp-day-hidden span {
               &:hover{
                    background-color: unset;
                }
            }
            .rmdp-disabled span,.rmdp-deactive span{
                color: #877E7E;
                &:hover{
                    background-color: unset;   
                    color:#8798ad;
                    width: unset;
                    height: unset;    
                }
            }
            .rmdp-week-day {
                height: 36px;
                width: 36px;
                color: #434343;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
            
            .rmdp-day.rmdp-range {background-color: #f9f9f9;box-shadow: unset;}
            .rmdp-day.rmdp-range span , .rmdp-day.rmdp-range.start span
            {
                background-color: #ffa75d /* !important */;
                color:#fff;
                &:hover{
                    background-color: #ffa75d /* !important */;
                }
            }
           

        }

        
    }
}
}
.transfer_box{

    .rmdp-container  > div:nth-of-type(3){
        // top: -70px !important;
    }
}
// .delhover:hover{
//     .Calendar_comp__parent{
//         .calander_txt1, .calander_txt2{
//             color: #F67D7D !important;
//         }
//         background-color: #FFD0D0;
//         border: 1px solid transparent;
//         background-image: url("../../images/red_calendar.png");
//     }
    
// }
.delhover{
    .Calendar_comp__parent{
        .calander_txt1, .calander_txt2{
            color: #F67D7D !important;
        }
        background-color: #FFD0D0;
        border: 1px solid transparent;
        background-image: url("../../images/red_calendar.png");
    }
    
}
.transfer-new-design.delhover{
   
    .Calendar_comp__parent{
        .calander_txt1{
            color:#A75555 !important;
        }
         .calander_txt2{
            color: #A75555 !important;
        }
        background-color: #fff;
        border: 1px solid #A75555;
        background-image: url("../../images/red_calendar.png");
    }
    .selectbox2{
        border : 1px solid #A75555;
    }
    .select-container > div > .selectbox2,
    .selectbox2 > div > .selectbox2
    {
        background-image: url("../../images/red_location.png");
    }
    .selectDropdown-pax{
        .numOfPax.numOfPax_sel{
            background-color: #fff;
        }
    }
    .numOfPax{
        border: 1px solid #A75555;
    }

    &:hover{
        .Calendar_comp__parent{
            background-color: #FFC8C8 ;
            .calander_txt1{
                color:#C16161 !important;
            }
        }
        .select-container > div > .selectbox2,
        .selectbox2 > div > .selectbox2{
            background-color: #FFC8C8 !important;
            & > div > .no_selLabel{
                color: #A75555
            }
            & > div > p{
                color: #797979;
            }
        }
        .selectDropdown-pax{
            .numOfPax.numOfPax_sel{
                background-color: #FFC8C8;
                .dropdown-paxmenu .selLabel2{
                    color: #A75555;
                    -webkit-text-fill-color: #A75555;
                }
            }    
        }
        .numOfPax{
            border: 1px solid #A75555 !important;
        }
    }

}
// //single calendar
.single-cal >.rmdp-container{
    div:nth-child(3){transform:unset !important}
    .rmdp-ep-arrow{display: none;}
    .rmdp-wrapper{
      width: 350px !important;
      height: 509px;
      border-radius: 31px;
      background: #F9F9F9;
      padding: 34px 39px 32px 38px;
      box-sizing: border-box;
     
      .rmdp-border-bottom.rmdp-border-top{border-bottom:unset;border-top:unset}
      
      
      .rmdp-calendar  {
          padding: 0;
          //margin-top: 100px;
          .rmdp-day-picker{
              width: 296px;
              padding: 0;
          }
          .rmdp-header{
            margin-top: 38px;
            padding: 0px 0 20px;
            width: 270px;
              div.rmdp-header-values{
                  color: #434343;
                  font-size: 20px;
                  font-weight: 700;
                 
              }
              div.rmdp-header-values:hover{
                  color: #0F7173;
              }
              div.rmdp-header-values:nth-child(1){margin-left: 0;}
              div.rmdp-header-values:nth-child(2){margin: 0;
                width: 100%; }
              
          }
  
          .rmdp-day-picker > div:nth-child(1), .rmdp-day-picker > div:nth-child(2) {
              margin-right: 52px !important; 
              width: 247px;
              .rmdp-day{
                  width: 36px;
                  height: 36px;
              }
              .rmdp-day.rmdp-today {
                  background-color: #F9F9F9 !important;
                  color: #877E7E !important;
                  pointer-events: none;
                  span{
                      background-color: #F9F9F9 !important;
                      color: #877E7E !important;
                  }
                  // &:hover{
                  //     background-color:#DADCDC !important;
                  //     color: #fff;
                  //     width: 32px;
                  //     height: 32px;
                  // }
              }
             
              .rmdp-day span {
                  color: #000;
                  font-family: Satoshi;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  &:hover{
                      background-color: #DADCDC;
                      color: #fff;
                      
                  }
              }
  
              .rmdp-day-hidden {
                 &:hover{
                      background-color: unset;
                  }
              }
              .rmdp-disabled span,.rmdp-deactive span{
                  color: #877E7E;
                  &:hover{
                      background-color: unset;   
                      color:#8798ad;
                      width: unset;
                      height: unset;    
                  }
              }
              .rmdp-week-day {
                  height: 27px;
                  width: 36px;
                  color: #434343;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
              }
              
              .rmdp-day.rmdp-range {background-color: #f9f9f9;box-shadow: unset;}
              .rmdp-day.rmdp-range span , .rmdp-day.rmdp-range.start span
              {
                  background-color: #ffa75d !important;
                  color:#fff;
                  &:hover{
                      background-color: #ffa75d !important;
                  }
              }
             
  
          }
  
          
      }
  }
  }


//calendarcomp ..
.errorContainer {
    border: 1px solid #FF5050  !important;
    background-image: url("../../images/red_calendar.png") !important;
}
.Calendar_comp__parent{
    width: 100%;
    height: 61px;
    border-radius: 32px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    font-family: Satoshi;
    line-height: normal;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.15);
    padding:6px 0 5px 66px ;
    background-image: url("../../images/Group.png");
    background-repeat: no-repeat;
    background-position: 25px center;
    background-size: 30px;
    display: grid;
    grid-template-columns: 1fr;
    align-content: center;
    cursor: pointer;
    
    .calander_txt1{
        color: #4A4A4A;
        font-size: 15px;
        font-weight: 700;
        .error{
            color: #F67D7D;
        }
    }
    .calander_txt2{
        color: $paraHeadColor;
        font-size: 15px;
        font-weight: 300;
        .error {
                color: #F67D7D;
            }
    }
    .date_added{
        color: #0F7173;
        font-size: 14px;
        font-weight: 500;
    }
}
.Calendar_comp__parent:hover{
    background-color: #EDEAE0;
    transition: 0.2s;
    border: 0.5px solid #0f7173;

}



.Calendar_header__parent{
    font-family: Satoshi;
    display: flex;
    justify-content: space-between;
 
    .cal_headboxes:nth-child(1),.cal_headboxes:nth-child(2){
        width: 247px;
        height: 62px;
        border-radius: 20px;
        background: #EDEAE0;
        box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
        color: #0F7173;
        background-image: url("../../images/calendardark.png");
        padding: 8px 0 10px 82px;
        border: 1px solid #0F7173;
        div{
            width:163px;
            text-align: left;
            line-height: normal;
            font-size: 17px;
            font-weight: 300;
        }
        div:nth-child(1){
           
            font-weight: 700;
        }

    }
    .cal_headboxes:nth-child(2){ 
        background-image: url("../../images/calendarGrey.png");
        color: #8C8C8C;
        border: 1px solid #D9D9D9;
        background-color: #FFF;
        div:nth-child(1){
            color: #797979;
        }
        &:hover{
            background: #EDEAE0 url("../../images/calendarHover.png");
        } 
    }
    .fromsel{
        background: #1D8284 !important;
        color: #fff !important; 
        background-image: url("../../images/calendar-white.png") !important;
    }
    .gngtoosel{
        background: #EDEAE0 !important;
        color: #0F7173 !important;
        background-image: url("../../images/calendardark.png") !important;
        div:nth-child(1){
            color: #0F7173 !important;
        }
        border: 1px solid #0F7173 !important;
    }
    .toosel{
        @extend .fromsel;
        div:nth-child(1){
            color: #fff !important;
        }
    }
    .cal_headboxes{
        background-repeat: no-repeat !important;
        background-position: 29px center !important;
    }
 
}

.single-head{
    width: 100%;
    height: 56px;
    border-radius: 31px;
    box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
    color: #0F7173;
    background: url("../../images/calendardark.png") no-repeat 20px center;
    padding: 5px 40px 5px 70px;
}
.single-cal >.Calendar_header__parent{
    justify-self: center
}



//sameInputs_parent
.input_boxes_parent{
    font-family: Satoshi;
    margin-left: auto;
    margin-right: 27px;
    .sameInputs_parent{
        // position: absolute;
        // left: 0px;
        // bottom: 24px;
        label{ 
            cursor: pointer;
            color: #6D6D6D;
            font-size: 16px;
            font-weight: 500;
            display: flex;
            align-items: center;
        }
        input{
            margin:0 20px 0px 0;
            width:15px;
            height:15px;  
        }
        input::before{
            content:"";
            width:15px;
            height:15px;
            background: #E7E7E7;
            // box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;
            position: absolute;
            border: none;
            border-radius: 2px;
        }

        input:hover::before{
            background-color: #ABC1C2;
        }

        input:checked::before{
            background-image: url("../../images/checkboxtick.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 13px;
        }
    }
    .checkBox_parent{
        display: flex;
        gap:0 57px;
        
        .checkBox_item_list{
            display: flex;
            align-items: center;
            
            input{
                margin-right: 9px;
                width: 10px;
                height: 10px;
            }
            label{
                cursor: pointer;
            }
        }
    }
}

//calendar footer
.Calendar_footer{
    display: flex;
    justify-content: flex-end;
    padding: 0px 0px 0px 20px;
    margin-top: 31px;
    align-items: center;
    .validate_btn_multi{
        @extend .calen_validate_btn; 
        opacity: 1 !important;
    }
    .calen_clear{
        text-decoration: underline;
        text-underline-offset: 3px;
        color: #767676;
        font-size: 16px;
        font-weight: 700;
        width:78px;
        text-decoration-thickness: 1.5px; // malikah
        cursor:pointer;// malikah
    }
}


//selected excursion
.SelectedExcursion_parent{
    width: 100%;
    font-family: Satoshi;
    position: relative;
    float: left;
    margin-top: 80px;
    padding: 20px 0 0 124px;
    font-style: normal;
    line-height: normal;
    .SelectedExcursion_left{
        width: 1242px;
        float: left;
        .SelectedExcursion_header{
            float: left;
            position: relative;
        } 
        .SelectedExcursion_body{
            width: 100%;
            float: left;
            .excursion_name{
                color: #0F7173;
                font-size: 38px;
                font-weight: 700;
                margin-bottom: 25px;
                border-left: 5px solid #FFA75D;
                line-height: 28px;
                padding-left: 10px;
            }
            .excursion_type{
                width: 122px;
                border-radius: 18px;
                border: 1px solid #434343;
                background-color: rgba(255, 255, 255, 0.66);
                background-image: url("../../images/excursion_type.png");
                background-repeat: no-repeat;
                background-size: 12px;
                padding-left: 36px;
                background-position: 12px center;
                color: #434343;
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 72px;
                }
            .excursion_title{
                color: #0F7173;
                font-size: 22px;
                font-weight: 700;
                width: fit-content;
                float:left;
                position: relative;
                margin-top: 50px; // malikah
                &::before{
                content:"";
                // width:100%;
                // height: 8px;
                // background-color: red;
                // position:absolute;
                // left: 0;
                // bottom: 0;
                }
            
            }
            .excursion_detail{
                margin: 26px 0 72px 0;
                color: #434343;
                font-size: 16px;
                font-weight: 500;
                float: left;
            }
            .SelectedExcursion_split{
                display: grid;
                grid-template-columns: 598px auto;
                gap: 0 91px;
                width: 100%;
                .amentiesBlock{
                   background: #F7F7F7;
                    border-left: 9px solid #FFAB63;
                    border-top-left-radius: 16px;
                    border-bottom-left-radius: 16px;
                    padding: 20px 30px 30px;
                }
                .labeltxt{
                    margin-bottom: 32px;
                    color: #434343;
                    font-size: 22px;
                    font-weight: 700;

                }
                .split_second{
                    width: 100%;
                    float: left;
                    margin-top: 100px;
                }
                .mapBlock{
                    float: left;
                    position: relative;
                    button{
                        width: 178px;
                        height: 32px;
                        border-radius: 17px;
                        background: #FFF;
                        box-shadow: 0px 5px 5.8px 0px rgba(0, 0, 0, 0.15);
                        position: absolute;
                        right: 10px;
                        bottom: 10px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

//quote excursion
.QuoteExcursion_parent{
    width: 100%;
    float: left;
    position: relative;
    font-family: Satoshi;
    .rmdp-container {
        display: unset !important;
    }
    .paratxt{
            color: #0F7173;
            font-family: Satoshi;
            font-size: 26px;
            font-weight: 900;
        }
        .sub_quoteparent{
            padding: 24px 44px 54px 24px;
            background-color: #FFFBF8;
            border: 1px solid #FFA75D;
            border-radius: 20px;
            float: left;
            margin-bottom: 50px;
        }
        .QuoteExcursion_header{
        width: 100%;    
        float: left; 
        border-bottom-style: dashed;
        border-bottom-width: 3px; 
        border-bottom-color: #859B99; 
        border-spacing: 5px;
        padding-bottom: 38px;
        margin-bottom: 59px;
            .QuoteExcursion_left{
                margin-right: 37px; 
                float: left; 
                img{
                    border-radius: 25px;
                    object-fit: cover;
                }
            }
            .QuoteExcursion_right{
                font-size: 16px;
                padding-top: 84px;
                float: left;
                width: calc(100% - 490px);
                .quote-accesibility{
                    width: 100%;
                    float:left;
                }
                .quote_name{
                    color: #3C3C3C;
                    font-size: 26px;
                    font-weight: 900;
                    margin-bottom: 22px;
                    
                }
                .display_pricepax{
                    color: #4A4A4A;
                    width: auto;
                    float: right;
                    text-align: center;
                    margin-bottom: 26px;
                }
                .location_comp{
                    margin: 23px 0 45px 0;
                    float: left;
                    width: 352px;
                }
                .quote_particular{
                    color: #434343;
                    font-weight: 300;
                    width: 100%;
                    margin-bottom:9px ;
                    background-repeat: no-repeat;
                    background-position: 0 center;
                    background-size: 12px;
                    padding-left: 18px;
                    float: left;
                    
                    span{
                        width: 120px;
                        color: #0F7173;
                        font-weight: 500;
                        margin-right: 10px;
                        float: left;
                        border-right: 0.5px solid #434343;
                    }
                }
                .quote_part1{
                    background-image: url("../../images/gmeals.png");
                }
                .quote_part2{
                    background-image: url("../../images/gclock.png");

                }
                .quote_part3{
                    background-image: url("../../images/gpickup.png");

                }
                .location_comp{
                    .selectbox1 {
                        height: 52px;
                        padding-top: 3px;
                   }
                }
                .priceQuote{
                    color: #4A4A4A;
                    font-size: 22px;
                    font-weight: 900;
                    width: fit-content;
                    float: right;
                    padding-right: 25px;
                    p{
                        font-size: 18px;
                    font-weight: 400;
                    }
                }
                .btnblock{
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 58px ;
                .ex_search{
                    @include ex_search;
                    width: 228px;
                    
                }
                }
            }
        }
    
}

.exprice{      
    font-family: Satoshi;
    font-size: 18px;
    font-weight: 900;
    margin-right: 4px;
}
.pax{
    font-size: 14px; // confirmed by malikah for visual good
    font-weight: 300;
    margin-top: 0px;
}

//feedback 
.feedBack_circle{width:7px;height:7px;background-color: #D9D9D9;border-radius: 50%;}
.circ_selected{background-color: #0F7173;}
.feedback_parent{
    //margin-top: 78px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    gap: 10px;
    font-family: Satoshi;
    width: 100%;
    // flex-grow: 1;
    height: 294px;
    // flex: 1 1 auto;
    
    .feedback-arrows{
        display: flex;
        justify-content: flex-start;
        align-self: flex-start;
        gap: 8px;
        // width: 44px;
        padding-right: 10px;
        margin-left: auto;
        &>div{
            width: 22px;
            height: 22px;
            background-image: url("../../images/accomodation/previous-review.svg");
            background-repeat: no-repeat;
            background-size: 22px;
            cursor: pointer;
            border-radius: 50%;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
            &:hover{
                filter: brightness(0.7);
            }
        }
        &>div:last-child{
            background-image: url("../../images/accomodation/next-review.svg");
            // box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.3);
        }
    }
    .feedback_parent_header{
        display: flex;
        // grid-template-columns: 482px 30px 22px;
        justify-content: space-between;
        //margin-bottom: 35px;
        align-items: center;
        height: 2rem;
        width: 100%;
        .labeltxt{
            color: #0F7173;
            font-size: 22px;
            font-weight: 700;
        }
        
    }
    .feedback_container{
        border-radius: 9px;
        background: #FCFCFC;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        padding: 26px 25px 40px 27px;
        position: relative;
        // max-height: 248px;
        height: 100%;
        // height: calc(100% - 2rem);
        .feedback_header{
            /* background-image: url("../../images/feedbackImg.png");
            background-repeat: no-repeat;
            background-size: 36px;
            background-position: 0 center; */
            // padding-left: 57px;
            margin-bottom: 18px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 21px;
            color: $paraHeadColor;
            img{
                width: 56px;
                height: 56px;
                object-fit: cover;
                border-radius: 50%;
                border: 1px solid #00AA6C;

            }
            .person-details{
                div:nth-child(1){
                    font-weight: 600;
                }
                div:nth-child(2){
                    font-size: 12px;
                }
            }
        }
        .feedback_text{
            width: 100%;
            overflow-y: auto;
            // height: 7em;
            line-height: normal;
            padding-right: 28px;
            color:$paraColor;
            /* display: -webkit-box;
            -webkit-line-clamp: 4; 
            line-clamp: 4;
            -webkit-box-orient: vertical; */
            // max-height: calc(1.35em * 4);
            max-height: calc(100% - 56px - 18px);
            overflow: hidden;
            overflow-y: auto;
            // text-overflow: unset;

            .content{
                margin-right: -28px;
            }

            .content-scroll{
                margin-right: 0;
            }

            &::-webkit-scrollbar {
                width: 7px;
                height: 250px;
                // padding-left: 28px;
              }
              
              &::-webkit-scrollbar-track {
                border-radius: 4px;
                background: #EBEBEB;
              }
              
              &::-webkit-scrollbar-thumb {
                width: 7px;
                height: 79px;
                border-radius: 4px;
                background: #D0D0D0;
                // box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
              }
              

        }
        .image_index{
            display: flex;
            gap:0 4px;
            position: absolute;
            bottom: 10px;
        }
    }
   
}

//imageCarosel_parent
.imageCarosel_parent{
    position: relative;
    float: left;
    width:100%;
    height: $carouselContainerHeight;
    .imageCarosel_images{
        display: grid;
        grid-template-columns: 2.52fr 1fr;
        height: 100%;
        gap: 21px;
        
        div{
                max-height: $carouselContainerHeight;
            img,.carosel-image{
                border-radius: 10px;
                object-fit: cover;
            }
            &.img-hotel-preview{
                height: calc(50% - $gapSecondaryImages / 2);
                max-width:$secondaryImageMaxWidth;
                position: relative;
                cursor: pointer;
                &>.images-left{
                    border-radius: 10px!important;
                    @include absoluteLayout;
                    @include flexCenter;
                    background-color: rgba(0, 0, 0, 0.3);
                    color: white;
                    font-size:50px;
                    font-weight: 500;
                    &:hover{
                        background-color: rgba(0, 0, 0, 0.5);
                    }
                }
            }
        }
        .rytImages{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
    .carosel_popup{
        @include popup;
        z-index: 200;
        &::before{
            content:"";
            background: #000;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            opacity: 80%;
        }

        .carosel_body{
            width: 71.5vw;
            height: 95vh;
            border-radius: 34px;
            border: 0.5px solid #585858;
            background: #28282861;
            backdrop-filter: blur(3.200000047683716px);
            padding: 36px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            // gap: 20px;
        .carosel_mainimage{
            width:100%;
            height:75%;
            float: left;
            text-align: center;
            position: relative;
            .img-carosel{
                border-radius: 18px;
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
                
            }
            .arrow-carosel{
                position: absolute;
                width: 98px;
                height: 98px;
                top:50%;
                cursor: pointer;
                background-repeat: no-repeat;
                background-size: 98px;

            }
            .carosel_left{
                background-image: url("../../images/caroselLft_arrow.png");
                left: 16px;
                transition: transform 0.2s ease;
                &:hover{
                    transform: translateX(-20%);
                }
            }
            .carosel_right{
                background-image: url("../../images/caroselRyt_arrow.png");
                right: 16px;
                transition: transform 0.2s ease;
                &:hover{
                    transform: translateX(20%);
                }
            }
       }
        .carosel_imagelist{
            width: 100%;
            float: left;
            display: flex;
            justify-content: flex-start;
            overflow-x: hidden;
            column-gap: 13px;
            @include hide-scrollbar-x;

            .imagelist_item{
                display: block;
                border-radius: 18px;
                width: 14vh;
                height: 14vh;
                flex: 0 0 auto;
            }
        }
    }
    }
}

//display date 
.display_dates_parent{
    margin-top: 22px;
    font-family: Satoshi;
    font-size: 14px;
    font-weight: 700;
    .date_box_parent{
        display: flex;
        overflow-x: scroll;
        width: 257px;
        
        transition: transform 0.3s ease; 
        .date_box{
            width: 66px;
            height: 66px;
            border-radius: 9px;
            background: #FFF;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
            flex: 0 0 auto;
            margin-right: 8px; /* Adjust margin as needed */
            margin-bottom: 37px;
            cursor: pointer;
            text-align: center;
           @include flexCenter;
               
          }
          .selected{
            background: #0F7173;
            color: #fff;
            width: 82px;
            height: 88px;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);

          }  
    }
    .date_box_parent::-webkit-scrollbar {
        width: 0.5em;
        height: 7px;
      }
      
      .date_box_parent::-webkit-scrollbar-track {
        border-radius: 22px;
      background: #F0F0F0;
      }
      
      .date_box_parent::-webkit-scrollbar-thumb {
       background-color: #696969;
       border-radius: 22px;
      }
    .display_selDtae{
        width: 267px;
      padding: 7px 34px;
      justify-content: center;
      align-items: center;
      display: flex;
      border-radius: 15px;
      border: 0.5px solid #0F7173;
      margin-top: 28px;
      color: #0F7173;
      background: #ECF9F8;
      }
}

.fixedQuotation{
    position: fixed;
    right: 20px;
    top:135px;
    z-index: 120;
    width: $docketWidth;
    @include flexCenter;
    flex-direction: column;
    gap:1.15rem;

    .service-selector-container{
        width: 100%;
        min-height: 50px;

        &.hide-service{
            display: unset;
            *{
                display: none;
            }
        }
    }
    
    &.follow-scroll{
        position: absolute;
        top:unset;
        z-index:119;
    }

    .toast-warning{
        transform: unset;
        position: relative;
        // inset: unset;
        width: 100%;
        padding:0.88rem 2.06rem 0.88rem 1.13rem;
        font-size: 0.875rem;
        font-weight: 700;
        gap:0.75rem;
        &::before{
            width: 1.5rem;
            height: 1.5rem;
        }
    }
    .selectboxDiv{
        width: 100%;
        animation: fadeIn 0.3s ease-in-out;
        transition: all .3s ease-out;
        border-radius: 16px;
        border: 0.5px solid #0F7173;
    }
    .dropdown-option {
        width: 100%;
        height: fit-content;
        .dropdown-optmenu{
            width: 100%;
            height: 42px;
            border-radius: 15px;
            .dropopt_img {
                $imgsize:18px;
                width:$imgsize;
                height: $imgsize;
                background-size: $imgsize;
            }
            .OptionValueBox_sel{
                width: inherit;
                height: inherit;
                background-color: #0F7173;
                color:#FFFFFF;
                border-radius: inherit;
            }
            .dropValue{
                width: inherit;
                height: inherit;
                border-radius: inherit;
                &:hover{
                    background: #F4F4F4;
                    border-radius: inherit;
                    box-shadow: unset;
                }
            }
        }
    }
}
.fixedQuotationDropDown{
    position: fixed;
    right: 20px;
    top:115px;
    z-index: 121;
    width: $docketWidth;
    animation: fadeIn 0.3s ease-in-out;
    transition: all .3s ease-out;

    &.hide{
        display: none;  
        animation :fadeOut 0.5s ease-in-out forwards;
        transition: all .3s ease-out;
    }
    .selectDropdown{

        .selectboxDiv{
            width: 100%;
            border-radius: 16px;
            border: 0.5px solid #0F7173;
        }
        .dropdown-option{
            width: 100%;
        }
    }
    &.follow-scroll{
        position: absolute;
        top:unset;
        z-index:119;
    }
    

}



//notes_parent
.notes_parent{
    width: 100%;
    float: left;
    .lbltxt{
        width: 100%;
        float: left;
        margin-bottom:22px;
        color: #434343;
        font-size:18px;
        font-weight:500;
        background-image: url("../../images/information.png");
        background-repeat: no-repeat;
        background-size: 16px;
        background-position: 0 center;
        padding-left: 21px;
    }
}

//FiltersData_parent
.FiltersData_parent{
    display: grid;
    grid-template-columns: 143px auto;
    gap:28px;
    color: #434343;
    font-family: Satoshi;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    align-items: center;
    margin-bottom: 51px;
    .scroll-container{
        display: flex;
        justify-content: space-between;
        max-width:43.22vw;
    }
    .list_head{
        width: 100%;
        display: flex;
        align-items: center;
        overflow-x: auto;
        &.scrollable{
            width:86%;
        }
        &::-webkit-scrollbar{
            width: auto;
            height: 0px;
        }
        &::-webkit-scrollbar-track {
          visibility: hidden;
        }
        
        &::-webkit-scrollbar-thumb {
          width: 2px;
          height: 0px;
          visibility: hidden;
        }
        // gap:14px;
        .filter_lists{
            width: fit-content;
            height: 34px;
            border-radius: 23px;
            border: 1px solid #0F7173;
            box-sizing: border-box;
            padding: 0 10px 0 15px;
            // margin-right: 10px;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
            display: flex;
            justify-self: flex-start;
            align-items: center;
            gap: 10px;
            white-space: nowrap;
            transition: background-color 0.15s ease;
            &::after{
                content:"";
                width:12px;
                height:12px;
                background: url("../../images/accomodation/remove-filters.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                display: inline-block;
                padding-top:24px;
            }
            &:hover{
                background-color:#FFE8E8;
            }
            &:not(:last-child):not(:nth-last-child(2)){
                margin-right: 14px;
            }
            &:nth-last-child(2){
                margin-right: 23px;
            }
        }
    }
    .scroll-button-container{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width:10%;
        .scroll-button{
            @include flexCenter;
            box-shadow: 0px 2px 4px 0px #0000001F;
            width:27px;
            height: 27px;
            border-radius: 50%;
            cursor: pointer;
            background-color: #FFF;
            &:hover{
                background-color: #F1EFEF;
            }
            &:active{
                background-color: #dfdcdc;
            }
            &::before{
                content: "";
                width:13px;
                height:13px;
                background-image: url("../../images/accomodation/arrowScroll.svg");
                background-repeat: no-repeat;
                background-position: center;
                position: absolute;
            }
            &.left{
                &::before{
                    transform: rotate(180deg);
                }
            }
        }
    }
    .remove-link{
        color: #434343;
        font-size: 14px;
        text-decoration: underline;
        text-underline-offset: 3px;
        font-weight: 500;
        cursor: pointer;
        @include flexCenter;
        transition: color 0.15s ease;
        white-space: nowrap;
        &::after{
            content:"";
            margin: 0 0 0 5px;
            // padding-top: 15px;
            background-image: url("../../images/accomodation/remove-filters.svg");
            background-position: center;
            background-repeat: no-repeat;
            width:15px;
            height:15px;
            display: inline-block;
            margin-top: 3px;
            transition: background-image 0.15s ease;
        }
        &:hover{
            color:#FFAB63;
            &::after{
                background-image: url("../../images/accomodation/remove-filter-orange.svg");
            }
        }
    }
}

//quotation_body_name
.body_excursion{
    width: 100%;
    float: left;
    font-family: Satoshi;
    max-height: 300px;
    margin-top: 10px;
    .quotation_body_name{
        color: #797979;
        font-weight: 700;
        font-size: 18px;
        position: relative;
        padding-left: 30px;
        border-left: 3px solid #FFA75D;
        line-height: 16px;
        
       &:before{
        content: "";
        background: url("../../images/chambre3.png") no-repeat 10px center;
        width: 26px;
        height: 16px;
        background-size: 16px;
        position: absolute;
        left: 0;
       }
    }
    .quotation_tour_name{
        color: #4A4A4A;
        font-size: 17px;
        font-weight: 600;
        margin-bottom: 20px;
    }
    .quotation_tour_date{
        color: #0F7173;
        position: relative;
        padding-left: 20px;
        font-size: 14px;
        font-weight: 500;
        &:before{
            content: "";
            background: url("../../images/quo_calaendarG.png") no-repeat 0 center;
            position: absolute;
            width: 100%;
            height: 20px;
            background-size: 11px;
            left: 0;
        }
    }
    .quotation_tour_support{
        @extend .quotation_tour_date;
        padding-left: 0;
        margin-top: 8px;
        &:before{
            content: "";
            background: unset;
        }
        span{
            font-weight: 700;
        }
    }
   
}

.hotel-list-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin:0 0 20px;
}

.hotel-list-header-no-filter{
    @extend .hotel-list-header;
    margin:calc(104px - $spaceSearchHeaderResult) 0 20px 0;
}

.show-unavailable-hotel{
    margin-bottom: 20px;

    &-no-filter{
        margin-bottom: 51px;
    }
}