$thumbDimension:15px;
$trackWidth:268px;

.slider-container{
    width: 100%;
    @include flexStart;
    margin-left: -13px;
    margin-bottom: 52px;
    flex-direction: column;
    
    .volume-container{
        width: $trackWidth;
        position: relative;
        .disabled-volume{
            position: absolute;
            width: 50%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.65);
            z-index: +1;
//#F8C4A6 #FCDFD0
            &.right{
                right: 0;
            }
        }

        .volume-image{
            width: calc(100% - 2px);
            transform: translateY(1px);
        }
    }
}

.MuiSlider-root{
    width:$trackWidth!important;
    padding:0!important;
    z-index: +1;
}

.MuiSlider-colorPrimary{
    color:rgba(15, 113, 115, 1)!important;
    height:5px!important;

    .MuiSlider-rail{
        color:#D9D9D9!important;
        opacity: unset!important;
        border-radius: 17px!important;
    }
}

.MuiSlider-thumb{
    height: $thumbDimension!important;
    width: $thumbDimension!important;
    box-shadow: none!important;
    &:hover{
        box-shadow: none!important;
    }

    // &.active-thumb{
    //     z-index:+1!important;
    // }
}

.MuiSlider-valueLabelOpen{
    padding: 0.25rem 0rem!important;
    transform: translateY(calc(100% + 3px)) !important;
    background: #fff!important;
    color: #0F7173!important;
    font-size: 14px!important;
    font-weight: 700!important;
    transition: color 0.3s ease;

    &::before{
        display: none;
    }

    &:hover{
        color:#145356!important;
        background-color: red;
    }
}

.Mui-active{
    z-index: +1!important;
    .MuiSlider-valueLabelOpen{
        isolation: isolate!important;
    }
}
