*{
    font-family: "Satoshi";
}

//PriceTag
.priceTag_parent{
    position: absolute;
    width: 86px;
    min-height: 79px;
    border-radius: 14px;
    background: #FFF;
    box-shadow: 2px 1px 6px -1px rgba(0, 0, 0, 0.23) inset;
    bottom: 15px;
        right: 18px;
    @include flexCenter;
    flex-direction: column;
div:nth-child(1){
    color:#0F7173;
    font-weight: 600;
}
}
//near_Region element
.parent_container_near_region{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 28px;
}
.wave-hand{
    margin-left: 8px;
    margin-top: -5px;
}
.container_near_region{

    border: solid 1px rgba(15, 113, 115, 1);
    width: auto;
    border-radius: 20px;
    padding: 7px 12px 7px 12px;
    font-family: Satoshi;
    font-size: 13px;
    font-weight: 400;
    height: 25px;
    @include flexCenter;
    .textRegion{
        &::before {
            flex: 0 0 auto;
            display: inline-block;
            margin-right: 10px;
            @include generatePseudoElement("../../images/global-icon/map.svg", 11px, 11px)
        }
    }
    .infoTemp{
        &::before {
            flex: 0 0 auto;
            display: inline-block;
            margin-right: 5px;
            // margin-top: 4px;
            @include generatePseudoElement("../../images/global-icon/thermometer.svg", 13px, 13px)
        }

    }
}
// optionPopup
.toast-error-form.show {
    opacity: 1;
}
.toast-error-form {
    opacity: 0;
    transition: opacity 1s ease-in-out;
    display: flex;
    align-items: center;
    z-index: 2;
    min-width: 406px;
    color: #F66666;
    font-weight: 400;
    border: 1px solid #C16161;
    border-left: 8px solid;
    position: relative;
    right: -4px;
    top: -5px;
    background-color: #FFF;
    box-shadow: 0px 1px 6.7px rgba(0, 0, 0, 0.19);
    padding: 8px 14px 8px 4px;
    border-radius: 5px;

    &::before {
        flex: 0 0 auto;
        @include generatePseudoElement("../../images/global-icon/alert-sign.svg", 24px, 24px);
        margin-left: 8px;
        margin-right: 10px;
    }
}
.toast-error-samevehc {
    opacity: 0;
    transition: opacity 1s ease-in-out;
    display: flex;
    align-items: center;
    z-index: 2;
    max-width: 255px;
    color: #797979;
    font-weight: 400;
    border: 1px solid #0F7173;
    border-left: 8px solid;
    position: relative;
    right: -370px;
    top: -60px;
    background-color: #FFF;
    box-shadow: 0px 1px 6.7px rgba(0, 0, 0, 0.19);
    padding: 8px 14px 8px 4px;
    border-radius: 5px;

  &.show{
    opacity: 1;
  }
}
.error-form{
    min-width: 406px;
    position: relative;
    right: -427.5px;
    top: -43px;
}
.toast-warning{
    @extend .toast-error;
    border-color:#FFAB63;
    color:#FFAB63;
    &::before{
        background-image: url('../../images//global-icon/alert-sign-warning.svg');
    }
}
.toast-warning-update{
    @extend .toast-error;
    position: relative;
    border-color:#FFAB63;
    color:#FFAB63;
    top:30px;
    left: 170px;
    max-width: fit-content;
    &::before{
        background-image: url('../../images//global-icon/alert-sign-warning.svg');
    }
}
.dropdownContainer{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row-reverse;
    margin-top: 26px;
    position:relative;
    .buttonDrop{
        width: 173px;
        height: 35px;
        border-radius: 25px;
        border: 1px solid #6F8C8D;
        background: #FFF;
        color: #797979;
        padding-left: 41px;
        display: flex;
        // justify-content: space-around;
        align-items: center;
        transition: background-color 0.3s ease;
        cursor: pointer;
        label {
            color: #4A4A4A;
            font-family: Satoshi;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            // margin-right: -25px;
        }
        &::after {
                @include arrow-dropdown-more-options;
                margin-left: 6px;
            }
        
            &.open {
                background: #EDEAE0;
        
                &::after {
                    transform: rotate(180deg);
                }
            }
        
            &:hover {
                @extend .open;
            }
        
            &.selected {
                color: $myColor7;
            }
    }
}
.dropdown-container {
    min-width: 173px;
    color: #877E7E;
    font-size: 18px;
    font-weight: 400;
    position: absolute;
    display: flex;
    padding: 16px 9px;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    border-radius: 7px;
    background: #FFF;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
    margin-top: 45px;
    // right: 0;
    z-index: 1;

    &>div {
        padding-left: 13.5px;
        width: 100%;
        cursor: pointer;

        &:hover {
            border-radius: 7px;
            background: $myColor8;
            box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
        }

        &.disabled {
            opacity: 0.37;
            cursor: default;

            &:hover {
                border-radius: none;
                background: none;
                box-shadow: none;
            }
        }
    }
}
//back_items
.back_items{
    width: fit-content;
    float: left;
    // padding-left: 16px;
    height: 22px;
    margin-bottom: 40px;
    color:$paraColor;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
    .back-txt{
        float: left;
        cursor: pointer;
        padding-right: 16px;
        border-right: 2px solid #797979;
        height: 17px;
        line-height: 16px;
        margin-right: 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &::before{
            content: "";
            width: 11px;
            height: 11px;
            background: url("../../images/backimg.png") no-repeat 0px center;
            position: relative;
            margin-right: 8px;
        }
    }
    .back-label{
        width: fit-content;
        float: left;
        margin: 0 0px 0px 0px;
        text-decoration: underline;
        font-weight: 500;
      
    text-underline-offset: 4px;
    text-decoration-thickness: 2px;
       
    }
    
    b{
        color:#000;
        margin: 0 5px;
    }
   
}

//mappopup_parent
.mappopup_parent{
    width: 100%;
    .imageBlock{
        background-image: url("../../images/dummyImg1.png");
        background-repeat: no-repeat;
        background-size: 359px;
        height: 291px;
        position: relative;
        
        
    }
}

//popups_parent
.popups_parent{
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    margin-top: 85px;
    z-index: 90;
    
    .popupBox{  
        width: 400px;
        height: 130px;
        background-color: #fff;
        padding: 15px 20px 20px ;
        border-radius: 15px;
        box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.26);
        div{
            margin-bottom: 20px;
        }
        button{
            background-color: #FFA75D;
            box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
            border: none;
            border-radius: 10px;
            width: 80px;
        }
    }
}

//car_capacity_parent
.car_capacity_parent{
    width: 786px;
    height: 552px;
    position: relative;
    background-color: #FFFFFF;
    float: left;
    border-radius: 23px;
    border: 1px solid #f4f4f4;
    padding: 0 72px 32px;
    @include flexCenter;
    flex-direction: column;
    text-align: center;
    font-family: "Satoshi";
    color:#434343;
    .imgBlock{
        background: url("../../images/capacitypop-background.png")no-repeat;
        position: absolute;
        height: 164px;
        background-size: 164px;
        width: 164px;
        top: 20px;
    }
    
    .capCarName{
        width: 178px;
        height: 33px;
        border-radius:27px;
        border:1px dashed #0F7173;
        @include flexCenter;
        margin-top: 10px;
    }

    .capacity_btn{
        display: flex;
    gap: 15px;
    justify-content: flex-end;
    width: 100%;
    position: absolute;
    bottom: 20px;
    right: 20px;
      
        button:nth-child(1){
            width:92px;
            height:42px;
            border-radius: 15px;
            background-color: #F9F8F8;
            box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
        }
        button:nth-child(2){
            width:202px;
            height:42px;
            border-radius: 15px;
            background-color: #0F7173;
            color:#fff;
            box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
        }
    }
   
}

//table-layout-parent
.booking-resume{
    .table-layout-parent{
        padding-bottom:80px;
    }    
}
.container-btn{

    .buttonSaveQuote{
        float: inline-end;
        width: 237px;
        height: 42px;
        padding: 9px 18px 9px 18px;
        gap: 10px;
        border-radius: 7px;
        background-color: rgba(15, 113, 115, 1);
        color:rgba(255, 255, 255, 1);
        font-family: Satoshi;
        font-size: 18px;
        font-weight: 500;
        line-height: 24.3px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }
}
.dashed-separator{
    width: 100%;
    &::before{
        content: "";
        height: 1px;
        display: block;
        border-top: 1px dashed hsla(0, 0%, 71%, 0.588);
    }

    &:last-child{
        display: none;
    }
}
.table-layout-parent{
    width: 100%;
    font-family: "Satoshi";
    color: #434343;
    margin: 0 0 80px;
    font-size: 14px;
    // float: left;
    .grid-layoutHead {
        width: 100%;
        display: grid;
        grid-template-columns: 23.571% 14.1% 8.43% 30% 9% 4%;
        // gap: 1.785%;
        row-gap: 17%;
        column-gap: 1.785%;
    }
    .grid-layout {
        width:100%;
        display: grid;
        grid-template-columns: 23.571% 14.1% 8.43% 30% 9% 6%;
        // gap: 1.785%;
        row-gap: 17%;
        column-gap:1.785% ;
        margin: 24px 0px;
        padding: 8px 0;
        // padding-bottom: 32px;

        &:last-child{
            padding-bottom: 0;
        }
        .item1{
            text-align: left;
            padding-left: $marginLeftItemLabel;
        }
        .transferLine_block{
            display: flex;
            gap:50px;
            padding-left:20px;
            div{
                .transferLine{
                    width: 4px;
                    height: 89px;
                    background: #C1CECD;
                    margin-left: 8px;
                    border-radius: 8px;
                    margin-top: 5px;

                }
            }
            .locations {
                font-weight: 400;
                display: flex;
                justify-content: flex-start;
                text-align: left;
                flex-direction: column;
                p{
                    color: #497289;
                    font-weight: 700;
                }
                div{
                   width: 100%;
                   float: left;
                   text-align: left;
                }
            }
            
           
        }
        .vehicle-car{
            background: url("../../images/cotationcar.png") no-repeat 0 center;
            height: 20px;
            padding-left: 20px;
        } 
        .transfer-date{
            background: url("../../images/cotationcalendar.png") no-repeat 0 center;
            height: 20px;
            padding-left: 20px;
        }
        .paxblock {
            display: flex;
            gap:10px;
            span{
                width: fit-content;
                height: 23px;
                border-radius: 16px;
                background-color: #8CB5B0;
                padding: 1px 10px;
                color: #fff;
            }
            .no-value{
                background-color: #fff;
                color:#000;
                border:1px solid #AAAAAA;
            }
        }
        .nb-nights{
            @include details-item("../../images/global-icon/half-moon.svg",12.04px,12px);
            margin-bottom: 6px;
        }
        .hotel-name{
            @include details-item("../../images/global-icon/double-bed-grey.svg",12.06px,14px);
            align-items: flex-start;
            margin-bottom: 6px;
            &-room-text{
                color:#0F7173;
                white-space: nowrap;
                span{
                    color: #434343;
                }
            }
        }
        .offer-text{
            color: #C16161;
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
            text-decoration: underline;
            margin: 10px 0;
        }
        .wrap-text{
            white-space: unset !important;
        }
        .client-type{
            @include details-item("../../images/global-icon/user-grey.svg",11px,12px);
            margin-bottom: 29px;
        }
        .meal-plan{
            @include details-item("../../images/global-icon/cutlery-grey.svg",11px,11px);
            margin-top:8px;
            font-size: 15px;
        }
        .date-container{
            @include flexStart;
            flex-direction: column;
            align-items: flex-start;
            gap:4px;
            .date{
                @include details-item("../../images/global-icon/calendar-grey.svg",11px,12px);
                &-arrow{
                    position: relative;
                    bottom: 1px;
                }
            }
        }
    }
    
    // .dashed-separator:not(:first-of-type){
    
    .head{
        background: #EEEEEE;
        height: 42px;
        align-items: center;
        border-radius: 9px;
        font-size: 16px;
    }
    .body{
        padding: 10px 0;
        &:first-of-type{
            padding: 10px 0 0;

        }
        &:not(:first-child){
            padding: 32px 0 0;
        }
        
    }
    .body-scroll{
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 350px;
    }

    .body-scroll::-webkit-scrollbar {
        width: 7px;
        height: 250px;
      }
      
      .body-scroll::-webkit-scrollbar-track {
        border-radius: 4px;
        background: #e6e6e6;
      }
      
      .body-scroll::-webkit-scrollbar-thumb {
        width: 7px;
        height: 79px;
        border-radius: 4px;
        background: #696969;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
      }
    .dashed-total {
        border-top: 1px dashed #B6B6B696;
        border-bottom: 1px dashed #B6B6B696;
        // float: left;
        width: 100%;
        padding: 5px 0px 5px 82.3%;
        font-size: 16px;
        font-weight: 900;
        color: #4a4a4a;
        margin-top: 56px;
    }

    }
    .dashed-totalPdf{
        border-top: 1px dashed #B6B6B696;
        border-bottom: 1px dashed #B6B6B696;
        float: left;
        width: 100%;
        padding: 5px 0px 5px 86.35%;
        font-size: 16px;
        font-weight: 900;
        color: #4a4a4a;
        margin-top: 160px;
    }

//Selectcar-parent
.Selectcar-parent{
    width: 100%;
    float: left;
    .cars-cotation-list{
        width: 100%;
        float: left;
        .cotation-list-head{
            margin: 20px 0px;
            .location-name{
                color:#0F7173;
                font-weight: 600;
            }
        }
        
    }
    .carblock-container{
        display: grid;
        width: 100%;
        float: left;
        gap: 50px;
        grid-template-columns: 471px 900px;
        margin-bottom: 50px;

        .imgBlock{

        }
        .cntent-blk{
            .carpax-info{
                width:250px;
            
                div{
                    margin-bottom: 8px;
                }
                div:nth-child(1)span{
                    background: url("../../images/cotationprofile.png") no-repeat 6px 6px;
                    position: absolute;
                    width: 150px;
                    padding-left: 25px;
                    height: 30px;
                    background-size: 12px;
                }
                div:nth-child(2)span{
                    background: url("../../images/quotation_calendar.png") no-repeat 6px center;
                    position: absolute;
                    width: 150px;
                    padding-left: 20px;
                    height: 23px;
                    background-size: 12px;
                }
            }
        }
    }
    .carName{
        background: url("../../images/cotationcar.png") no-repeat 0 center;
        width: fit-content;
    padding-left: 20px;
    }
}

//Amenities 
.roomBox{
    width:fit-content;
    height: 25px;
    padding: 8px 20px 8px 25px;
   @include flexCenter;
    font-size: 12px;
    font-weight: 500;
    border-radius: 15px;
    color: #758D74;
    border: 0.5px solid #486A47;
    //background: #F4FEF4 url("../../images/roomAvailable.png") no-repeat 10px center;
}

.roomRequest{
    border: 0.5px solid #B07A4B;
    //background: #FFF3E9 url("../../images/roomRequest.png") no-repeat 10px center;
    color: #B07A4B;
}

.amenities_list{
    display: flex;
    row-gap: 11px;
    column-gap: 11px;
    flex-wrap: wrap;

    &_other{
        border-radius: 34px;
        border: 1px solid #0F7173;
        padding: 6.5px 11px 6.5px 28px;
        @include flexCenter;
        height: 23px;
        color: #0F7173;
        font-size: 14px;
        font-weight: 500;
       
    }

    &_room{
        max-width: 141px;
        border-radius: 34px;
        border: 1px solid #3252A4;
        padding: 6.5px 11px;
        @include flexCenter;
        height: 23px;
        color: #3252A4;
        font-size: 14px;
        font-weight: 500; 
        background:  url("../../images/accomodation/icons/square-meter.svg") no-repeat ; 
        background-position : 9px 5px ;
    }
}


.button-see{
    display: flex;
    padding: 12px 58px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-radius: 10px;
    background: $myColor7;
    box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
    color: #FFF;
    font-size: 15px;
    font-weight: 700;
    height:35px;
    transition:background-color 0.3s ease;

    &:hover{
        background: #0A4E50;
    }
}

.amenities_region_list{
    border-radius: 34px;
    border: 1px solid #0F7173;
    padding: 3px 11px 6.5px 28px;
    //@include flexCenter;
    height: 23px;
    color: #0F7173;
    font-size: 14px;
    font-weight: 500;
    width:max-content;
    color: #434343;
    font-size:13px;
    font-weight:400;
}
.logoAmenities{
    margin-right: 6px;
    width: 11px;
    height: 11px;
}
.paxAge_parent{
    width: 100%;
    float: left;
    margin: 0px 0px 30px 0;
    padding-right: 20px;
    .pax-label{
        font-weight: 600;
        margin-bottom: 20px;
        margin-top: 20px;
    }
   
}

/// car dropdown
.carDropdown-parent{
    width: 100%;
    float: left;
    .carDropdown-input{
        width: 379px;
        height: 46px;
        box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        @include flexCenter;
        justify-content: flex-start;
        padding-left: 30px;
        margin: 10px 0;
        cursor: pointer;
        background: #fff url("../../images/paxdownarr.png") no-repeat 348px center ;
        &:hover{
            
            background: #fff url("../../images/paxuparr.png") no-repeat 348px center ;
        }
    }
    .carDropdown-popup{
        list-style-type: none;
        box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
        background-color: #fff;
        width: 373px;
        padding: 10px;
        border-radius: 25px;
        margin-top: 10px;
        li{
            display: grid;
            grid-template-columns: 157px 120px 13px; 
            padding: 10px 20px;
            border-radius: 20px;
            //border: 1px solid #0F7173;
            box-sizing: border-box;
            margin-top: 10px;
            cursor: pointer;
            
            &:hover{
                background-color: #f4f4f4;
            }
            div{
                font-size: 14px;
            }
            div:nth-child(1){
                background: url("../../images/cardropdown.png") no-repeat 0 center;
                padding-left: 20px;
            }
            div:nth-child(2){
                background: url("../../images/paxImg.png") no-repeat 0 center;
                padding-left: 15px;
                background-size: 12px;
            }
        }
        li:nth-child(1){
            margin-top: 0;
        }
    }
}

//timeline

.module-name{
    background: url("../../images/chambre3.png") no-repeat 0 center;
    padding-left: 39px;
    margin-bottom: 35px;
    font-size: 18px;
    font-weight: 900; 
    color: #797979;
    @include flexCenter;
    justify-content: flex-start;
    gap:14px;
}
.sel-excursion-map{
    width: 100%;
    position: relative;
    .excursion-map{
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 500;
        color:$paraColor;
        b{
            color: $paraHeadColor;
        }

    }
    .paxIcon{
        &::before {
                margin-right: 9px;
                @include generatePseudoElement("../../images/docketIcon/adult.svg", 12px, 11px);
                width: 12px;
                vertical-align: middle;
                position: relative;
                top: 3px;
                top: -1px;
        }
        // @include generatePseudoElement("../../images/calendardark.png", 11px, 11px);
    }
    .suite{
        font-family: Satoshi;
        font-size: 14px;
        font-weight: 400;
        line-height: 18.9px;
        text-align: left;
        margin-top: 22px;
        &::before {
        margin-right: 9px;
        @include generatePseudoElement("../../images/docketIcon/suite.svg", 12px, 11px);
        width: 12px;
        vertical-align: middle;
        position: relative;
        top: 3px;
        top: -1px;
        }
    }
    .meal_plan{
        &::before {
            margin-right: 9px;
            @include generatePseudoElement("../../images/docketIcon/meal_plan.svg", 12px, 11px);
            width: 12px;
            vertical-align: middle;
            position: relative;
            top: 3px;
            top: -2px;
        }
    }
    .filter-teen{
        display: flex;
        gap:15px
    }
    .accom{
        background: url("../../images/chambre1.png") no-repeat 13px center;
        border-left: 3px solid #FFAB63;
        height: 14px;
        line-height: 15px;
    
    }
    .nights-docket{
        background: url("../../images/yellowmoon.png") no-repeat 0px center;
        padding-left: 16px;
        margin: 10px 0 22px 0;
    }
    .nights-docket-v1{
        background: url("../../images/yellowmoon.png") no-repeat 0px center;
        padding-left: 16px;
        margin: 10px 0 10px 0;
    }
    .dates{
        margin: 18px 0 7px;
    }
    .dates-v1{
        margin: 10px 0 7px;
    }
    .roomno{
        background: url("../../images/chambre1.png") no-repeat 0px center;
        padding-left: 25px;
        margin-top: 22px;
    }
    .quotation_tour_name{
        color: $paraHeadColor;
        font-size: 16px;
        font-weight: 700;
        //margin-bottom: 15.88px;
    }
    .quotation_tour_date{
        // background: url("../../images/calendardark.png") no-repeat 0 center;
        background-size: 11px;
        background-position-y: 3px;
        // padding-left: 15px;
        color:#0F7173;
    }
    span.quotation_tour_date_first-date,span.quotation_tour_date_second-date{
        // margin-left: 3px;
        &::before{
            margin-right:9px;
            @include generatePseudoElement("../../images/calendardark.png",11px,11px);
            width: 12px;
            vertical-align: middle;
            position: relative;
            top: -2.9px;
        }
    }
    span.quotation_tour_date_arrow{
        margin:0 9px 0 8px;
        position: relative;
        bottom: 1.2px;
    }
    
    .quotation_tour_support{
        color:#0F7173;
        span{
            font-weight: 700;
        }
    }
    .person-icon{
        background: url("../../images/global-icon/user-grey.svg") no-repeat 0px center;
        padding-left: 16px;
        background-position-y: 2px;
        font-weight: 600;
        color: #4A4A4A;
    }
    .room-type-icon{
        background: url("../../images/global-icon/double-bed-grey.svg") no-repeat 0px center;
        padding-left: 16px;
        background-position-y: 3px;
    }
    .room-number-icon{
        background: url("../../images/global-icon/double-bed-grey.svg") no-repeat 0px center;
        padding-left: 16px;
        background-position-y: 3px;
    }
    .meal-plan-icon{
        background: url("../../images/global-icon/cutlery-grey.svg") no-repeat 0px center;
        padding-left: 16px;
    }
    .person-icon span{
        font-weight: 500;
    }
    &-show-more{
        display: flex;
        flex-wrap: nowrap;
        position: relative;
        left: 15px;
        top: 23px;
        .show-more-text{
            margin-left: 15px;
            font-size: 15px;
            font-style: italic;
            color: #616161;
        }
    }
} 
.sel-transfert-map{
    width: 100%;
    float: left;
    .excursion-map{
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 500;
        color:$paraColor;
        b{
            color: $paraHeadColor;
        }
    }
    .filter-teen{
        display: flex;
        gap:15px
    }
    .module-name{
        background: url("../../images/chambre3.png") no-repeat 0 center;
        padding-left: 39px;
        margin-bottom: 36px;
        font-size: 18px;
        font-weight: 900; 
        color: #797979;
        @include flexCenter;
        justify-content: flex-start;
        gap:14px;
    }
    .accom{
        background: url("../../images/chambre1.png") no-repeat 13px center;
        border-left: 3px solid #FFAB63;
        height: 14px;
        line-height: 15px;
    
    }
    .nights-docket{
        background: url("../../images/yellowmoon.png") no-repeat 0px center;
        padding-left: 16px;
        margin-bottom: 22px;
        
    }
    .dates{
        margin: 18px 0 18px;
    }
    .roomno{
        background: url("../../images/chambre1.png") no-repeat 0px center;
        padding-left: 25px;
        margin-top: 32px;
    }
    .quotation_tour_name{
        color: $paraHeadColor;
        font-size: 16px;
        font-weight: 700;
        //margin-bottom: 15.88px;
    }
    .quotation_tour_date{
        // background: url("../../images/calendardark.png") no-repeat 0 center;
        background-size: 11px;
        background-position-y: 3px;
        // padding-left: 15px;
        color:#0F7173;
    }
    span.quotation_tour_date_first-date,span.quotation_tour_date_second-date{
        // margin-left: 3px;
        &::before{
            margin-right:9px;
            @include generatePseudoElement("../../images/calendardark.png",11px,11px);
            width: 12px;
            vertical-align: middle;
            position: relative;
            top: 3px;
            top: -1px;
        }
    }
    span.quotation_tour_date_arrow{
        margin:0 9px 0 8px;
        position: relative;
        bottom: 1.2px;
    }
    
    .quotation_tour_support{
        color:#0F7173;
        span{
            font-weight: 700;
        }
    }
    .person-icon{
        background: url("../../images/global-icon/user-grey.svg") no-repeat 0px center;
        padding-left: 16px;
        background-position-y: 5px;
        font-weight: 600;
        color: #4A4A4A;
    }
    .room-type-icon{
        background: url("../../images/global-icon/double-bed-grey.svg") no-repeat 0px center;
        padding-left: 16px;
        background-position-y: 3px;
    }
    .room-number-icon{
        background: url("../../images/global-icon/double-bed-grey.svg") no-repeat 0px center;
        padding-left: 16px;
        background-position-y: 3px;
    }
    .meal-plan-icon{
        background: url("../../images/global-icon/cutlery-grey.svg") no-repeat 0px center;
        padding-left: 16px;
    }
    .person-icon span{
        font-weight: 500;
    }
    &-show-more{
        display: flex;
        flex-wrap: nowrap;
        position: relative;
        left: 15px;
        top: 23px;
        .show-more-text{
            margin-left: 15px;
            font-size: 15px;
            font-style: italic;
            color: #616161;
        }
    }
    .trans_points{
        margin-bottom: 5px;
    }
    .quationcar_img{
        background-size: 14px;
        color: #4A4A4A;
        font-weight: 600;
        font-size: 14px;
    }
} 
.sel-excursion-map.gradient{
    background: url("../../images/timeline-gradient.png") no-repeat 0 center;
    background-position: 9px;
    .MuiTimelineConnector-root.css-1ami7lv-MuiTimelineConnector-root{
        background: none !important;
    }
}

    
.supplement-row{
    margin-top: 15px;
    width: 100%;
    float: left;
    border: 1px solid #0F717380;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.28);
    padding: 15px;
    border-radius: 10px ;
    background-color: #fff;
    .sup-text{
        background: url("../../images/baby-seat.png") no-repeat 0 center;
        background-size: 22px;
        padding-left: 34px;
        display: grid;
        column-gap: 25px;
        align-items: center;
        line-height: 21px;
        font-size: 14px;
        color: #0F7173;
        font-weight: 700;
        grid-template-columns: 70px 280px;
        .sup-txtryt{
            color:#4A4A4A;
            span{
                color: #797979;
                font-weight: 400;

            }
        }
    }
    .sup-text-lugg{
        background: url("../../images/luggage-logo.png") no-repeat 0 center;
        background-size: 22px;
        
    }
    .sup-notes-capacity{
        color: #434343;
        font-weight: 700;
        margin: 10px 0;
        font-size: 14px;
    }
    .sup-button{
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        .supplement-count{
            display: flex;
            gap: 8px;
            span{
                background: url("../../images/paxDec.png")no-repeat 0 0;
                background-size: 33.767px;
                height: 33.15px;
                width: 33.767px;
                cursor: pointer;
                &:hover{
                    background: url("../../images/paxDec_sel.png")no-repeat 0 0;
                    background-size: 33.767px;
                    transition: 300ms;
                }
            }
            span:nth-child(3){
                background: url("../../images/paxInc.png")no-repeat 0 0;
                background-size: 33.767px;
                &:hover {
                    background: url("../../images/paxInc_sel.png")no-repeat 0 0;
                    background-size: 33.767px;
                    transition: 300ms;
                }
            }
            input{
                border: none;
                text-align: center;
                outline: none;
            }
            input[type="number"]::-webkit-inner-spin-button,input[type="number"]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                appearance: none;
                margin: 0; /* Optional: removes the margin */
            }
            .paxValueBox{
                width: 70px;
                height: 31px;
                border-radius: 9px;
                background: #FFF;
                box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
                display: grid;
                place-items: center;
            }
        }
    }
    
}
.sup-notes{
    font-size: 14px;
    font-weight: 400;
    color: #3F7B9C;
    // float: right;
    text-align: right;
    margin-top: 42.9px;
    padding-left: 48px;
    p{
        color: #434343;
        font-weight: 700;
    }
}
.order{
    order:3;
    @include disable
   
}


.car_details_block{
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 2px dashed #FFAB63;
    /* background-color: red; */
    padding-bottom: 45px;
}

.single-calendar{
    color:red;
    .react-datepicker {
        width: 343px;
        height: 509px;
        color: #fff;
        top: -120px;
        left: 0;
    }
    .react-datepicker__triangle{display: none;}
    .react-datepicker__current-month {
        text-align: center;
        width: 293px;
    }
    .react-datepicker__month-container {
        width: 333px;
    }
    .inner_calendar_butContainer{
        right: 0;
        justify-content: space-evenly;
    }
}

.accom-Heading{
    width: 100%;
    float: left;
    // background-color: #f7f7f7;
    display: flex;
    justify-content: flex-start;
    font-size: 20px;
    font-weight: 500;
    color:#aeaeae;
    min-width: 1239px;
    column-gap: 86px;    
    height: auto;
    position: relative;
    padding-top: 4px;
    &::before{
        content: "";
        width: 100%;
        height: 4px;
        background: linear-gradient(45deg, #387C7D33 calc(calc(189px + 216px + 277px) / 2), transparent);
        position: absolute;
        top: 0;
    }

    .bar-selected{
        
        // width: 189px;
        position: absolute;
        height: 5px;
        // margin-top: 4px;
        background: #0F7173;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        transition: width 0.3s,margin-left 0.3s;
        box-shadow: 0px 3px 2px -1px rgba(0, 0, 0, 0.35);
    }
       
  .labels{
      width: fit-content;
      width: 189px;
      @include flexCenter;
      flex-direction: column;
    //   padding: 20px 0px 0 0px;
    padding: 18px 0px 0 0;
    position: relative;
    cursor: pointer;
    font-weight: 400;
    color: #6D6C6C;


    &:hover {
        color: #5C7370;
        font-weight: 400;
        &::before {
            content: "";
            width: 100%;
            position: absolute;
            height: 2px;
            left: 0;
            top: 0;
            background: #8CB5B0;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
        }
    }

    $animation-custom :fade-in 0.3s;

    &.selected {
        color: #6D6C6C;
        font-weight: 700;
        -webkit-transition: $animation-custom;
        -moz-transition: $animation-custom;
        -o-transition: $animation-custom;
        transition: $animation-custom;
        
        &::before {
            content: "";
            background: none !important;
        }
        
        &::after{
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background:  #387C7D33;
            top: 57px;
        }

    }

    &:nth-child(3) {
        width: 216px;
    }

    &:nth-child(4) {
        width: 277px;
    }
}   
}
.booking-resume {
    .accom-Heading .labels.selected::after {
        background: transparent;
    }
}


.room-popup-parent{
    width: 1123px;
    float: left;
    border-radius: 26px;
    padding: 54px 23px 40px 42px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 600;
    position: relative;
    max-height: 95%;

    >*:not(:last-child){
        z-index: 10;
    }
    &::after{
        content:"";
        width: 100%;
        height: 100%;
        background-image: url("../../images/background/coconut.svg");
        background-repeat: no-repeat;
        background-position:105% -75%;
        background-size: 512px;
        position:absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.3;
        z-index:1;
    }
    // margin-top: 100px;
    .room-popup-head{
        width: 100%;
        display: flex;
        gap:32px;
        position:relative;
        .img-overview{
            width: 143px;
            height:96px;
            border-radius: 12px;
            border-left: 5px solid #FFAB63!important;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        }
        .popup-head-cnt{
            width: 100%;
            color: #797979;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
           div:nth-child(1){
                color: #0F7173;
                font-size: 18px;
                font-weight: 700;
            }
            .popup-hotel-name{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap:11px;
                &::before{
                    content: url("../../images/accomodation/chambre.svg");
                }
            }
            .popup-room-name{
                font-size: 16px;
                font-weight: 500;
                @include justify-content-top;
                // margin-top: 14px;

            }
            .popup-head-tarrif{
                display: flex;
                justify-content: space-between;
                align-items: center;
                // margin-top: 15px;
                div:nth-child(1){
                    color: $myColor7;
                    font-size: 14px;
                    font-weight: 700;
                    }
                    div:nth-child(2){
                        margin-top: 0;
                    }    
           
                }
        }
    }
    //room-popup-parent
    .room-popup-containerBody {
        max-height: 59vh;
        overflow-y: auto;
        /* isolation: isolate; */
        z-index: 12;
        position: relative;
    }

    .room-popup-containerBody::-webkit-scrollbar {
        width: 7px;
        height: 250px;
    }

    .room-popup-containerBody::-webkit-scrollbar-track {
        border-radius: 4px;
        background: #e6e6e6;
    }

    .room-popup-containerBody::-webkit-scrollbar-thumb {
        width: 7px;
        height: 79px;
        border-radius: 4px;
        background: #696969;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    }
    .room-popup-body{
        // width: 1039px;
        min-height: 353px;
        margin-top: 33px;
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.69);
        box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.12);
        backdrop-filter: blur(2px);
        padding: 31px 35px 0 35px;

        .tariffDates-parent{
            color: #0F7173;
            margin-bottom: 15px;
            display: flex;
            gap: 15px;
            div {
                margin-bottom: 5px;
                span{color: #797979;font-size: 15px;}
            }
            
           
        }
        // position:relative;
        table {
            width: 100%;
            border-collapse: collapse;
            position: relative;
            // table-layout: auto;
            //border-bottom: 1px dashed #FFAB63;
            .rate-txt{
                &.pax-category{
                    font-weight: 700;
                    color: #0F7173;
                    white-space: unset;
                }

                &.price-detail{
                    text-align: center;
                    color: #797979;
                    font-weight: 400;
                }
            }
            th,td{
                white-space:nowrap;
            }
            thead{
                // display: table-row;
                .head-txt:nth-child(1){width: 233px;}
                .head-txt{
                    color: #797979;
                    font-size: 16px;
                    font-weight: 400;
                    text-align: center;

                    &:last-child{
                        font-weight: 700;
                    }
                }
            }
            tbody{
                tr:not(:last-child)>td{
                    padding-bottom: 23px;
                }
            }
            tbody tr:last-child td {
                padding-bottom: 20px; /* Adjust the padding as needed */
            }
            tfoot {
                td{
                    padding-bottom: 23px;
                }
                .line-separator {
                    padding-bottom: 21px;
                    &::after{
                        content: '';
                        position: absolute;
                        display:block;
                        border-top: 1px dashed #FFAB63;
                        width:100%;
                    }
                }
                .discount{
                    color:#F66666!important;
                }
            }
          }
          
          table tbody {
            border-top: 33px solid #fff;
          } 

    }
    .room-popup-footer{
        display: flex;
        gap: 29px;
        margin-top: 36px;
        width: 1039px;
        justify-content: flex-end;
        align-items: center;
        z-index:10;
        position: relative;

        .total{
            padding: 7px 21px;
            width: auto;
            border-radius: 19px;
            border: 1px solid #0F7173;
            display: flex;
            justify-content: flex-start;
            gap: 17px;
            align-items: center;
            color: #0F7173;
            font-size: 18px;
            font-weight: 700;
            height:fit-content;
            // padding:8px;
        }
    
    }
    .close-icon{
        position: absolute;
        right: 28px;
        top: 30px;
        cursor: pointer;
        width: 37px;
        height: 37px;
        background: url("../../images/accomodation/cross.svg") no-repeat;
        transition: background-image 0.3s ease;

        &:hover{
            background: url("../../images/accomodation/cross-hover.svg") no-repeat;
        }
    }
    
}

//amenities-popup-parent

.amenities-popup-parent{
    width: 37.44vw;
    float: left;
    height: 96vh;
    position: relative;
    .arrow-container{
        display: flex;
        // background-color: #0F7173;
        width: 100%;
        height: inherit;
        position: absolute;
        z-index: 1000;
        top: 0;
        justify-content: space-between;
        align-items: center;
        .containerRightArrow,.containerLeftArrow{
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 12%;
            height: 30%;
            cursor: pointer;
            // background-color: rgba(0, 0, 0, 0.5);
            filter: brightness(0.6);
            &:hover{
                filter:none;
            }
        }
    }
    .close-icon{
        position: absolute;
        right: 24px;
        top:24px;
        z-index: 2000;
        &:hover{
            cursor: pointer;
        }
    }
    .amenities-popup-content{
        height: calc(100% - $imageRoomPopupHeight);
        background-color: #fff;
        padding: 49.5px $paddingContentPopupLeft $paddingContentPopupBottom 44px;
        // border-top: 15px solid #FFA75D;
        border-bottom-left-radius: 26px;
        border-bottom-right-radius: 26px;
        box-shadow: inset 0px 7px 12px 0px rgba(0, 0, 0, 0.1);
        position: relative; //pour le positionnement du background
        background-image: url("../../images/background/palmtree.png");
        background-repeat: no-repeat;
        background-position: right calc(0% - 4vh);
        background-size: 12vh;
        /* &::before {
            content: ''; 
            position: absolute;
            top: -5px; 
            right: 0; 
            width: 14%;
            height: 32%;
            background-size: cover;
            border-top-right-radius: 26px;
        } */
        .amenities-list{
            max-height: 31.83vh;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 7px;
                height: 250px;
                // padding-left: 28px;
              }
              
              &::-webkit-scrollbar-track {
                border-radius: 4px;
                background: #EBEBEB;
              }
              
              &::-webkit-scrollbar-thumb {
                width: 7px;
                height: 79px;
                border-radius: 4px;
                background: #D0D0D0;
                // box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
              }
        }
        ul{
            display: flex;
            gap:18px 28px;
            flex-wrap: wrap;
            margin-bottom: 36px;
            list-style: none;
            padding-left: 0;
            li{
                position: relative; // Nécessaire pour le pseudo-élément
                color: #0F7173;
                font-size: 14px;
                font-weight: 400;
                padding-left: 13.5px; // Ajustez l'espace entre le point et le texte
                white-space: nowrap;
                height: 1em;
                &::before {
                    content: '•'; // Définit le symbole de la puce
                    position: absolute;
                    left: 0;
                    font-size: 14px; 
                    color: #0F7173; 
                }
            }
        }
        // &:last-child ul {
        //     margin-bottom: 75.58px; // Appliquer une margin-bottom uniquement au dernier ul
        // }
        .hotel-name{
            @include line-name;
            border-left: 3px solid #ffffff;
            padding-left: 0;
            font-size: 22px;
            line-height: 29px;
            margin-bottom: 46px;
            font-weight: 700;
            display: flex;
            align-items: center;
            .borderLeftStyle{
                width: 3px;
                height: 14px;
                background-color: #FFA75D;
                margin-right: 5px;
            }
        }
        .category-name{
            display:flex;
            color:#434343;
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 18px;
            font-weight: 700;
            white-space: nowrap;
            height: 1em;
        }
        .btn-block{
            display: flex;
            justify-content: space-between;
            position: absolute;
            bottom:$paddingContentPopupBottom;
            width: calc(100% - $paddingContentPopupLeft - 44px);
            button{
                display: none!important;
                width: 322px;
                height: 37px;
                background-color: #0F7173;
                color:#fff;
                border-radius: 25px;
                font-size: 18px;
                font-weight: 700;
                padding: 0 20px;
                height: 37px;
                margin-top: 5px;
                font-weight: 700;
                @include flexCenter;
                transition: background-color 0.3s ease;
                opacity:0.5;
                pointer-events: none;
                img{
                    position: relative;
                    right: 10px;
                    margin-left: 8px;
                    bottom: 2px;
                    height: 12px;
                    top: 0px;
                }

                span{
                    @include remove-white-space;
                    @include flexCenter;
                    margin-top: -3px;
                }
             &:hover{
                background-color: #159092;
             }
            }
            .exprice{
                color:#0F7173;
                position: relative;
                bottom: 4px;
                @include remove-white-space;
                margin-bottom: 8px
            }
        }

        .paxNumber{
            @include pax-number;
            @include remove-white-space;
        }
    }
    .room-images-carosel{
        height:$imageRoomPopupHeight;
        position:relative;
        background-color: #878787;
        border-radius: 26px 26px 0 0;
        
        .image-room-popup {
            width: 100%;
            height: inherit;
            border-top-left-radius: 26px;
            border-top-right-radius: 26px;
        }
        &:hover {
            .image-room-popup{
                filter: brightness(0.55);
                // transition: 200ms;
            }
        }
        &::before{
            content: "";
            cursor: pointer;
            position: absolute;
            width: 100%;
            height: inherit;
        }
        
        &::after{
            content: "";
            width:100%;
            position: absolute;
            border-bottom: 15px solid #FFA75D!important;
            bottom: 0;
            left:0;
            z-index: 10000;
        }

    }
   
}

//dialogue-link
.dialogue-link{
   display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    flex-direction: column;
    text-align: center;
    background: #F2F2F2;
    color:#C16161;
    width: 445px;
    height: 199px;
    font-size: 16px;
    border-radius: 13px;
    padding: 32px 27px 20px 27px;

    button:nth-child(1){color: #4A4A4A;}
    button:nth-child(2){
        width: 165px;
        height:36px;
        @include flexCenter;
        border-radius: 17px;
        background-color: #0F7173;
        font-weight: 500;
       
        color: #fff;
    }
    
}
.dialogue-asking-service{
   display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 10px;
    flex-direction: column;
    background: #FFFFFF;
    color:#C16161;
    width: 684px;
    height: 234px;
    font-size: 16px;
    border-radius: 13px;
    padding: 21px 27px 20px 38px;

    &-text{
        p:nth-child(1){
            color:#0F7173;
            font-size: 20px;
            font-weight: 700;
            line-height: 27px;
            margin-bottom: 5px;
        }
        p:nth-child(2){
            color:#434343;
        }
    }
    &-container-button{
        display:flex;
        justify-content:space-between;
        gap:14px;
        margin:15px 6px 0 0;        
        button:nth-child(1){
            width: 95px;
            height:42px;
            @include flexCenter;
            border-radius: 7px;
            border : .5px solid #898989; 
            background-color: #F9F8F8;
            font-weight: 500;
            box-shadow: 0px 1px 6.7px 0px #00000030;
            font-size: 18px;
            line-height: 24.3px;
    
        }
        button:nth-child(2){
            width: 115px;
            height:42px;
            @include flexCenter;
            border-radius: 7px;
            background-color: #0F7173;
            font-weight: 500;
            box-shadow: 0px 1px 6.7px 0px #00000030;
            color: #fff;
            &::after{
                position: relative;
                top:1px;
                left: 7px;
                transition: transform 300ms ease-in-out;
                @include generatePseudoElement("../../images/global-icon/next-arrow-white-bold.svg",13px,13px);
                
            }
        }
    }

    
}
.dialogue-caution-service{
   display: flex;
    justify-content: center;
    flex-direction: column;
    background: #F2F2F2;
    color:#C16161;
    width: 684px;
    // height: 270px;
    min-height: 270px;
    font-size: 16px;
    border-radius: 13px;
    padding: 0px 27px 0px 32px;

    &-text{
        p:nth-child(1){
            color:#FFAB63;
            font-size: 16px;
            font-weight: 700;
            line-height: 27px;
            margin-bottom: 5px;
        }
        p:nth-child(2){
            color:#A96E3C;
            font-size: 16px;
            font-weight: 400;
            line-height: 21.6px;
            text-align: left;

        }
    }
    &-date-container{
        .Calendar_comp__parent {
            border: 3px solid #0f7173;
            height: 55px;
        }
        .Calendar_comp__parent:nth-child(1){
            background: url("../../images/arrival-plane.png") no-repeat ;
            background-position: 25px center;
            background-size: 28px;
        }
        .Calendar_comp__parent:nth-child(2){
            background: url("../../images/departure-plane.png") no-repeat ;
            background-position: 25px center;
            background-size: 28px;
        }
    }
    &-container-button{
        display:flex;
        justify-content: flex-end;
        gap:14px;
        margin:15px 6px 0px 0;        
        button:nth-child(1){
            width: 95px;
            height:42px;
            @include flexCenter;
            border-radius: 7px;
            border : .5px solid #898989; 
            background-color: #F9F8F8;
            font-weight: 500;
            box-shadow: 0px 1px 6.7px 0px #00000030;
            font-size: 18px;
            line-height: 24.3px;
    
        }
        button:nth-child(2){
            width: 115px;
            height:42px;
            @include flexCenter;
            border-radius: 7px;
            background-color: #0F7173;
            font-weight: 500;
            box-shadow: 0px 1px 6.7px 0px #00000030;
            color: #fff;
            &::after{
                position: relative;
                top:1px;
                left: 7px;
                transition: transform 300ms ease-in-out;
                @include generatePseudoElement("../../images/global-icon/next-arrow-white-bold.svg",13px,13px);
                
            }
        }
    }

    
}
.dialogue-choose-service{
    display: flex;
     align-items: center;
     flex-direction: column;
     background: #F2F2F2;
     width: 608px;
     height: 382px;
     font-size: 16px;
     border-radius: 13px;
    padding: 31px 27px 20px 31px;
 
     &-text{
         margin-bottom: 25px;
         p:nth-child(1){
             color:#0F7173;
             font-size: 20px;
             font-weight: 700;
             line-height: 27px;
         }
     }
     &-container{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        column-gap: 20px;

        &-child{
            max-width: 248px;
            height: 233px;
            width: 100%;
            display: flex;
            flex-direction: column;
            text-align: center;
            background: url("../../../../public/Assets/images/global-icon/pax-booking-choosing.svg") no-repeat  center 52px;
            background-color: #A7BABA;
            border: 3px solid #0F7173;
            border-radius: 23px;
            padding: 114px 0 0;
            cursor: pointer;

            p{
                position: relative;
                margin: 0 auto;
                font-size: 16px;
                line-height: 21.6px;
                color:#ffff;
                cursor: pointer;
            }
        }
        &-child.service{
            background: url("../../../../public/Assets/images/global-icon/service-selecting-booking.svg") no-repeat  center 48px;
            background-color: #D5BAA4;
            border: 3px solid #FFAB63;
            padding: 130px 0 0;

        }
     }

     
 }
 .check-mark-simple{
        @include generateIconClass("../../images/global-icon/check-mark-simple.svg",18px,18px);
 }
 .dialogue-pax-selections{
    display: flex;
     margin-top: 10px;
     flex-direction: column;
     background: #ffffff;
     color:#C16161;
     width: 751px;
     min-height: 372px;
     font-size: 16px;
     border-radius: 13px;
     padding: 31px 27px 20px 38px;
     position: relative;
     bottom: 100px;
 
    .transfer-filter{
        .Calendar_comp__parent{
            width: 190px;
        }
    }

     &-text{
        margin-bottom: 18px;
        
         p:nth-child(1){
             color:#0F7173;
             font-size: 20px;
             font-weight: 700;
             line-height: 27px;
             margin-bottom: 3px;
         }
         p:nth-child(2){
             color:#434343;
         }
     }
     &-selector{

        &-child{
            display: grid ;
            grid-template-columns: 267px 170px 170px;
            column-gap: 5px;
            .Calendar_comp__parent{
                height: 50px;

            }
            .rmdp-container div:nth-child(3) {
                left: unset !important;
                top: unset !important;
            }
            .selectboxDiv {
                // border:1px solid #0F7173;
            }
            .flex-center{
                justify-content: inherit;
                margin-bottom: 30px;
            }
        }
        &-child-excursion{
            display: grid ;
            grid-template-columns: 267px 228px;
            column-gap: 6px;
            .Calendar_comp__parent{
                height: 50px;

            }
            .rmdp-container div:nth-child(3) {
                left: unset !important;
                top: unset !important;
            }
            .selectboxDiv {
                border:1px solid #0F7173;
            }
            .flex-center{
                justify-content: inherit;
                margin-bottom: 30px;
            }
        }
        .selectDropdown-pax{
            display: grid;
            grid-template-columns: 240px;
            .numOfPax{
             height: 50px;   
            }
            .optionBox-pax {
                transform: translateX(-113%);
            }
        }
        .service-pax-container{
            @include flexStart;
            flex-wrap: wrap;
            gap:9px;
            width:100%;
            &.match-pax{
                opacity: 0.24;
                .service-box-client{
                    pointer-events: none;
                }
            }
            .service-pax-error-container{
                display: flex;
                gap: 5px;
                
                .toast-error-form {
                    position: inherit;
                }
            }
        }
        .service-box-client{
            min-width: 121px;
            min-height: 45px;
            border-radius: 7px;
            border: 0.5px solid #AEAEAE;
            // background: red;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
            padding: 5px;
            cursor: pointer;
            transition: background-color 0.3s ease;
            @include flexSpace;
            gap:20px;
           
            &:hover{
                background: #EBEBEB;
            }
    
            &.checked{
                border: 1px solid $myColor7;
                color: $myColor7;
                .service-pax-info{
                    .service-box-client-name{
                        color: $myColor7;
                
                    }
                    
                  
                    
                   
                }
               
               
            }
    
            &.not-filled{
                border: 0.5px solid #C16161;
                background: #F6E6E6;
            }
    
            .add-baby-seat{
                background-color: red;
            }
            .service-pax-info{
                color: #434343;
                font-size: 14px;
                font-weight: 700;
                .service-box-client-name{
                    color: #666;
                    font-size: 12px;
                    font-weight: 400;
                }
               
            }
           
            
        
            .service-pax-rank{
                min-width: 20px;
                height: 20px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                align-self: center;
                gap: 20px;
                border-radius: 50%;
                border: 1px solid rgba(174, 174, 174, 0.15);
                background: #D9D9D9;
                box-shadow: 0px 1px 0.85px rgba(0, 0, 0, 0.08) inset;
                color: #FFF;
                font-size: 14px;
                font-weight: 700;
                @include flexCenter;
    
                &.not-filled{
                    background: #DDBEBE;
                }
                
                &:not(:empty){
                    box-shadow: 0px 1px 1.7px 0px rgba(0, 0, 0, 0.08) inset;
                    background: #8CB5B0;
                    stroke-width: 0.15px;
                    stroke: #D9D9D9;
                }
            }

           
        }
        
       
        
      
     }
     &-container-button{
         display:flex;
        justify-content: flex-end;
         gap:14px;
         margin:60px 6px 0 0;        
         button:nth-child(1){
             width: 95px;
             height:42px;
             @include flexCenter;
             border-radius: 7px;
             border : .5px solid #898989; 
             background-color: #F9F8F8;
             font-weight: 500;
             box-shadow: 0px 1px 6.7px 0px #00000030;
             font-size: 18px;
             line-height: 24.3px;
     
         }
         button:nth-child(2){
             width: 115px;
             height:42px;
             @include flexCenter;
             border-radius: 7px;
             background-color: #0F7173;
             font-weight: 500;
             box-shadow: 0px 1px 6.7px 0px #00000030;
             color: #fff;
             &::after{
                 position: relative;
                 top:1px;
                 left: 7px;
                 transition: transform 300ms ease-in-out;
                 @include generatePseudoElement("../../images/global-icon/next-arrow-white-bold.svg",13px,13px);
                 
             }
         }
     }
 
     
 }

 .dialogue-pax-selections.reservation{
    display: flex;
    width: 751px;
    height: 279px;
    flex-direction: column;
    background: #ffffff;
    padding: 31px 27px 20px 38px;

    .dialogue-pax-selections-selector{
        display: flex;
        width: 80%;
    }

    .dialogue-pax-selections-container-button{
        display:flex;
       justify-content: flex-end;
        gap:14px;
        margin:23px 6px 0 0;        
        button:nth-child(1){
            width: 95px;
            height:42px;
            @include flexCenter;
            border-radius: 7px;
            border : .5px solid #898989; 
            background-color: #F9F8F8;
            font-weight: 500;
            box-shadow: 0px 1px 6.7px 0px #00000030;
            font-size: 18px;
            line-height: 24.3px;
    
        }
        button:nth-child(2){
            width: 115px;
            height:42px;
            @include flexCenter;
            border-radius: 7px;
            background-color: #0F7173;
            font-weight: 500;
            box-shadow: 0px 1px 6.7px 0px #00000030;
            color: #fff;
            &::after{
                position: relative;
                top:1px;
                left: 7px;
                transition: transform 300ms ease-in-out;
                @include generatePseudoElement("../../images/global-icon/next-arrow-white-bold.svg",13px,13px);
                
            }
        }
    }
 }
.dialogue-cancel-booking{
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    flex-direction: column;
    background: #FFFFFF;
    color:#C16161;
    width: 684px;
    height: 886px;
    font-size: 16px;
    border-radius: 13px;
    padding: 37px 27px 20px 35px;
 
     &-text{
        margin-bottom: 23px;
         p:nth-child(1){
             color:#0F7173;
             font-size: 20px;
             font-weight: 700;
             line-height: 27px;
             margin-bottom: 21px;

             &::after{
                margin-left:12px;
                @include generatePseudoElement("../../images/global-icon/cancellation-green.svg",19px,19px);
                transform: translateY(4px);
             }
         }
         p:nth-child(2){
             color:#434343;
         }
     }
     &-details{
        display: flex;  
        justify-content: center;
        width: 100%;
        &-box1{
            flex-grow: 1;
            .pax-details-container{
                max-width: 200px;
            }
            .client-name{
                font-size: 18px;
                font-weight: 700;
                color:#434343;
                margin-bottom: 3px;
            }
            .booking-cancellation{
                font-size: 16px;
                font-weight: 300;
                line-height: 21.6px;
                color: #434343;

            }
        }
        &-box2{
            .cotDocdetails{
                width: 254px;
                height: auto;
                border-radius: 12px;
                border: 0.5px solid #FFFFFF;
                background: #FFFFFF;
                box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
                padding: 12px 1px 15px 24px;
                span{
                    color: #434343;
                    font-family: Satoshi;
                    font-style: normal;
                    line-height: normal;
                    font-size: 13px;                   
                    margin-top: 8px;
                    &.label {
                            font-weight: 700;
                    }
                
                    &.value {
                        font-weight: 400;
                    }
                }
                p{
                    color: #0F7173;
                    font-size: 16px;
                    font-weight: 700;
                    margin-bottom: 26px;
                }
                // span,p:first-of-type {
                //     margin-top: 0;
                // }
                div{
                    display: grid;
                    grid-template-columns: 37% 60%;
                    word-wrap: break-word;
                }
            }
        }
     }
     &-service{
        width: 616px;
        height: 416px;
        box-shadow: 0px 1px 4px 0px #00000040;
        border-radius: 16px;
        border: 1px solid #8CB5B0;
        margin-top: 35px;
        overflow-y: auto;
        @include hide-scrollbar-y;
        p{
        margin:0px;
        color: #000;
       } 
        .item-deleted{
            width:100%;
            padding: 20px 59px 20px 26px;
            gap: 10px;
            border-radius: 16px;
            background-color: #FFFFFF;
            padding-top: 37px;
            padding-left: 26px;
        }
        .img-delete-service{
            width: 20px !important;
            height: 20px;
            position: relative;
            top: -1px;
        }
        .name-service-delete{
            font-family: Satoshi;
            font-size: 18px;
            font-weight: 700;
            line-height: 18.9px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #434343;

        }
        .accom-header{
            padding: 0 0 21px 0px;
        }
        .hotel-name{
            color: #0F7173;
            font-size: 16px;
            font-weight: 600;
            margin-top: 20px;
            border-left: 3px solid #D9D9D9;
            width: 100%;
            padding-left: 9px;
            margin-bottom: 12px;
        }
        .dates-container{
            @include flexStart;
            align-items: center;
            gap:8px;
        }
        .night-stay{
            box-shadow: unset!important;
            margin-left: 9px;
        }
        .accom-date{
            @include date-label-green();
        }
        .date-arrow{
            color: #4A4A4A;
            font-size: 12px;
            font-weight: 500;
        }
        .room-name{
            color: #4A4A4A;
            font-size: 14px;
            font-weight: 500;
            @include flexStart;
            gap: 6px;
            &::before{
                @include generatePseudoElement("../../images/global-icon/double-bed-grey.svg",13.082px,14px);
            }
        }
        .period-application {
            border-radius: 18px;
            border: 1px solid #FFAB63;
            background: #FFF;
            display: flex;
            padding: 8px 23px;
            align-items: center;
            gap: 9px;
            width: fit-content;
            color: #434343;
            font-size: 13px;
            font-weight: 400;
            margin-top: 22px;
            .accom-date{
                color: #585858;
                font-size: inherit;
                font-weight: 500;
                &::before{
                    background-image: url("../../images/global-icon/calendar-grey.svg");
                }
            }
        }
        .policy-details{
            color: #585858;
            font-weight: 700;
            margin-top: 19px;
            .policy-header{
                font-size: 16px;
                font-weight: 700;
            }
            .policy-details{
                font-size: 12px;
                font-weight: 700;

            }
        }
        .title-popup.ex{
            font-size: 16px;
            color:  #0F7173;
            font-weight: 600;
            
        }
        .quotation-map-child {
            background-image: url("../../images/calendardark.png");
            background-repeat: no-repeat;
            background-size: 10px;
            background-position: 15px 5px;
            padding-left: 20px;
            color: #0f7173;
            font-size: 14px;
            @include lineHeight;
            font-weight: 500;
            margin-bottom: 8px;
            font-family: Satoshi-Medium;
           
        }
        .dashed-total{
            margin: 0 29px;
            border-top: 2px dashed rgba(182, 182, 182, 0.5882352941);
        }
        .quationcar_img{
            color:  #434343;
            margin-top: 25px;
        }
    }
     &-container-button{
         display:flex;
         justify-content:flex-end;
         gap:14px;
         margin-top:15px;        
         width: 100%;
         button:nth-child(1){
            //  width: 115px;
             height:42px;
             @include flexCenter;
             border-radius: 7px;
             background-color: #0F7173;
             font-weight: 500;
             box-shadow: 0px 1px 6.7px 0px #00000030;
             color: #fff;
             padding: 9px 19px;
             &::after{
                 position: relative;
                 top:1px;
                 left: 7px;
                 transition: transform 300ms ease-in-out;
                 @include generatePseudoElement("../../images/global-icon/next-arrow-white-bold.svg",13px,13px);
                 
             }

             &.loading{
                &::after{
                    display: none;
                    background: unset;
                }
             }
         }
     }
 
     
 }
.arrival-timeline{
    background: url("../../images/arrival-plane.png") no-repeat 0 0;
    width: 20px;
    height: 20px;
    position: relative;
    margin-bottom: 29.5px;
}
.departure-timeline{
    @extend .arrival-timeline;
    background: url("../../images/global-icon/departure-plane-green.svg") no-repeat 0 0;
    margin: 10px 0 0 0;
    background-size: cover;
}
.transferIcon{
    @extend .arrival-timeline;
    background: url("../../images/chambre2.png") no-repeat 0 0;
    margin: 9.5px 0 4px 1.9px;
}
.accomIcon{
    @extend .arrival-timeline;
    background: url("../../images/chambre1.png") no-repeat 0 0;
    margin: 9.5px 0 4px 1.9px;
}
.excuricon{
    @extend .arrival-timeline;
    background: url("../../images/chambre3.png") no-repeat 0 0;
    margin: 9.5px 0 4px 1.9px;
}
.arrival-timeline.arrival-timeline-v1{
 top: 14px
}

.accomIcon-table{
    @extend .accomIcon;
    padding-left: 25px;
    color: #0F7173;
    font-size: 14px;
    font-weight: 700;
}

.pax-details-container{
    @include pax-tags;
}

.showfilter-parent{
    width: 100%;
    position: relative;
    .filter-box{
        width: $filterBoxWidth;
        border-radius: 14px;
        border: 0.5px solid #B7B7B7;
        background-color: #FFF;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        padding-left: 20px;
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
        user-select: none;
        float:right;
        &::before{
            content:"";
            background: url("../../images/accomodation/filterSvg.svg") no-repeat center;
            background-size:13px;
            // background-size: 13px;
            width: 14px;
            height:14px;
        }
        //transition: red 4s ease-out;{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap:6px;
        height: 41px;
    }
    .filter-box:hover{
        border-radius: 14px;
        border: 0.5px solid #B7B7B7;
        background-color: #EDEAE0;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    }
    .active{
        border-radius: 14px;
        border: 0.5px solid #B7B7B7;
        background-color: #EDEAE0;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    }
    .labelCheckBox{
        padding-left: 13px;
        color: #434343;
        font-family: Satoshi;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        input::before {
            content: "";
            width: 15px;
            height: 15px;
            background-color: #EFEFEF;
            box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;
            position: absolute;
            border: none;
            border-radius: 2px;
        }
    }
    .labelCheckBox:hover{
        color: #0F7173;
        input::before {
            background-color: #D9D9D9;;
        }
    }
         input:checked::before {
             background-image: url("../../images/checkboxtick.png");
             background-repeat: no-repeat;
             background-position: center;
             background-size: 13px;
    
         }
    .checkunvailable{
        margin-right: 10px;
        cursor: pointer;
    }

    .filter-dropdown{
        width: $filterDropdownWidth;
        user-select: none;
        height: 188px;
        border-radius: 11px;
        background: #FFF;
        box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
        padding: 16px 11px;
        position: absolute;
        z-index: 1;
        top: 50px;
        cursor:pointer;
        left: calc($filterBoxWidth - $filterDropdownWidth);
        
    }

}
.filter-list{
    margin-bottom: 12px;
    padding-left: 13px;
    color: #4A4A4A;
    line-height: 1.5;
    transition: background-color 0.2s ease;
    border-radius: 7px;
    &:hover{
        background: #D9D9D9;
    }
}
.filDisable{
    pointer-events: none;
    opacity: 0.5;
}
//no_hoteldropdown

.no_hoteldropdown{
    background: url("../../images/no-hotel-result.png")no-repeat top center;
    width: 100%;
    height: 160px;
    background-size: 94px;
    position: relative;

    p{
        position: absolute;
        bottom: 15px;
        width: 100%;
        text-align: center;
        color: #B3B3B3;
        font-size: 14px;
        font-weight: 700;
    }
}

.nights-parent{
    border-radius: 6px;
    box-shadow: 0px 2px 5.5px 0px rgba(0, 0, 0, 0.18);
    padding: 11px 7px;
    background-size: 11px;
    color: #666;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    gap:3px;

    &.popup-dimension{
        padding: 11px 10px
    }

    &.flex-column{
        flex-direction: column;
        gap:0;
        padding: 9px 7px;
        span{
            @include flexCenter;
            height: 1em;
        }
    }
    span:first-child{
        @include flexCenter;
        &::before{
            background: #fff url("../../images/yellowmoon.png") no-repeat;
            background-size:contain;
            content:"";
            height: 12px;
            width:12px;
            transform: translateY(1px);
        }
    }
}

.star-icon{
    .css-1lauo1g-MuiRating-root {
       gap:3px
      }
}

.regionList-parent{
    @include flexStart;
    gap: 9px;
    color: #434343;
    font-size: 14px;
    font-weight: 700;
    .region-names{
        padding: 10px 16px;
        border-radius: 7px;
        border: 0.5px solid #AEAEAE;
        background-color: #FFF;
        transition: background-color ease-in-out 100ms;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        cursor: pointer;
        color:$paraHeadColor;
        &:hover{
            background-color: #EBEBEB;
        }
    }
    .selected{
        border: 1px solid #0F7173;
        color: #0F7173;
    }
}

//occupency-parent starts
.occupancy-container{
    width: 210px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    
        .occupency-parent {
            width: inherit;
            height: 27px;
            padding: 8px 0px 8px 39px;
            border-radius: 21px;
            font-size: 15px!important;
            font-weight: 400!important;
            @include alignCenter;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        }
    
        .occupency-stop {
            border: 1px solid #975454;
            color: #975454 !important;
            background: #FCC url("../../images/accomodation/icons/occupency-red.png") no-repeat 16px center;
        }
    
        .occupency-enable {
            color: #558F50 !important;
            border: 1px solid #558F50;
            background: #E8F1E7 url("../../images/accomodation/icons/occupency-green.png") no-repeat 16px center;
        }
    
        .occupancy-message-success {
            color: #486A47;
            font-family: Satoshi;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            &::after{
                content: url('../../images/accomodation/check-mark-green.svg');
                display: inline-block;
                margin-left: 6px;
                vertical-align: middle;

            }
        }
    
        .occupancy-message-danger {
            @extend .occupancy-message-success;
            color:#F66666;

            &::after{
                content: url('../../images/accomodation/check-mark-red.svg');
            }
        }
}
//occupency-parent ends

.dashed-hr{
    border: none;
    border-top: 2px dashed #000;
    width:calc(100% - 14px);
    margin: 17px auto; /* Center the hr and add margin */
    // padding: 0 7px;
}

.description-label{
    height: 1em;
    margin-bottom: 12px;
}

.description-title{
    // margin-bottom:4px;
    // float:left;
    font-weight:700;
    height: 1em;
    color:$paraHeadColor;
}


//booking-done-popup parent

.booking-done-popup{
    width: 841px;
    height: max-content;
    border-radius: 35px;
    background: #FFF;
    position: absolute;
    top: 50%;
    left: 50%;
    color: #0F7173;
    transform: translate(-50%, -50%);
    .popup-content-container{
        padding:59px 0px 15px 60px;
    }
   
   
    .popup-heading{
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 73px;
        &::before {
                margin-right: 9px;
                @include generatePseudoElement("../../images/cancellation.png", 19px, 19px);
                vertical-align: middle;
                position: relative;
                top: 3px;
                top: -4px;
            }
    }

    .popup-content{
        // height: 220px;
       // overflow-y: scroll;
        width: 98%;
        max-height: calc(75.20814061vh - 59px);
        overflow-y: auto;
        @include default-scrollbar;
        
        .popup-sub-heading{
            padding-left: 12px;
            //font-size: 24px;
            //font-weight: 700;
            margin-bottom: 10px;
            border-bottom: 1px dashed;
            padding-bottom: 10px;
            width: 98%;
            div{
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 3px;
            }
            .roomnum{
                font-size: 18px;
                font-weight: 600;
            }
            .accom-label{
                font-family: Satoshi;
                font-size: 18px;
                font-weight: 700;
                line-height: 24.3px;
                text-align: left;
                color: #434343;
                margin-bottom: 20px;
                &::before {
                    margin-right: 9px;
                    @include generatePseudoElement("../../images/svg/chambre1.svg", 20px, 20px);
                    vertical-align: middle;
                    position: relative;
                    top: 3px;
                    top: -1px;
                }
            }
            .hotel-name{
                border-left: solid 5px #D9D9D9;
                padding-left: 6px;
                margin-bottom: 16px;
                b{color:#0F7173;}
            }
            .date{
                font-family: Satoshi;
                font-size: 14px;
                font-weight: 500;
                line-height: 18.9px;
                text-align: left;
                &::before {
                    margin-right: 9px;
                    @include generatePseudoElement("../../images/calendardark.png", 12px, 11px);
                    vertical-align: middle;
                    position: relative;
                    top: 3px;
                    top: -1px;
                }
            }
            .nights{
                margin-left: 17px;
                color:  #434343;
                font-family: Satoshi;
                font-size: 14px;
                font-weight: 400;
                line-height: 18.9px;
                text-align: left;
                &::before {
                    margin-right: 3px;
                    @include generatePseudoElement("../../images/yellowmoon.png", 12px, 12px);
                    vertical-align: middle;
                    position: relative;
                    top: -1px;
                }
            }
            .container-policy-room{
                margin-top: 75px;
                .policy-room{
                    margin-bottom: 23.4px;
                    &::before {
                        margin-right: 6px;
                        @include generatePseudoElement("../../images/svg/chambre1.svg", 13px, 12.8px);
                        vertical-align: middle;
                        position: relative;
                        top: -1px;
                    }
                    font-family: Satoshi;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 18.9px;
                    text-align: left;
                }
            }
            .policy-period {
                border-radius: 18px;
                border: 1px solid #FFAB63;
                background: #FFF;
                padding: 5px 23px;
                width: max-content;
                margin-bottom: 19px;
                span{
                    color: #0F7173;
                    font-weight: 600;
                }
                
            }
            .policy-price {
                font-family: Satoshi;
                font-size: 16px;
                font-weight: 500;
                line-height: 21.6px;
                text-align: left;
            }
        }
    }
    .scrollpop{
        overflow-y: scroll;}
    .scrollpop::-webkit-scrollbar {
        width: 7px;
        height: 250px;
      }
      
      .scrollpop::-webkit-scrollbar-track {
        border-radius: 4px;
        background: #e6e6e6;
      }
      
      .scrollpop::-webkit-scrollbar-thumb {
        width: 7px;
        height: 79px;
        border-radius: 4px;
        background: #696969;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
      }
    
    .popup-btn-block{
        border-radius: 0px 0px 35px 35px;
        border-top: 9px solid #FFA75D;
        background: #FFF;
        box-shadow: 0px -3px 7.6px 0px rgba(0, 0, 0, 0.25);
       
        width: 100%;
       
        height: 134px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        color: #4A4A4A;
        font-size: 18px;
        font-weight: 500;
        .sameInputs_parent{
            user-select: none;
        }
        .confirm-book-btn{
            width: 247px;
            height: 64px;
            font-size: 22px;
            font-weight: 700;
            @include greenButton;
        }
    }
}

.addbaby-seat-parent{
    width: 684px;
    height: 234px;
    border-radius: 11px;
    background: #fff;
    padding:30px;
    color: #434343;
    font-size: 16px;
    font-weight: 600;
    p{
        color: #0F7173;
        font-size: 20px;
        font-weight: 700;
        
    }
    .button-block{
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
        gap:15px;
        .add-baby-seat-cancel{
            width: 95px;
            height: 42px;
            padding: 9px 20px 9px 19px;
            border-radius: 7px;
            border: 0.5px solid #898989;
            background-color: #F9F8F8;
            color: #434343;
          
            
        }
        .add-baby-seat{
            @include greenButton;
            border-radius: 7px;
           
        }
    }

}

.inputref-parent-conteiner{
    width: 500px;
    height: max-content;
    border-radius: 35px;
    background: #FFF;
    position: absolute;
    top: 50%;
    left: 50%;
    color: #0F7173;
    transform: translate(-50%, -50%);
    .input-ref-parent{
        padding: 20px;
        .toref_textbox{
            border-radius: 5px;
            border: 1px solid #DDD;
            background: #F5F5F5;
            outline: none;
            width: 425px;
            padding: 10px 14px;
            margin-top: 10px;
            box-sizing: border-box;
        }
        .ref_button{
            margin: 25px 15px 15px;
            @include greenButton;
            height: 35px;
            float:right;
        }
    }
}

.popup-confirmed-booking{
    transition: height 0.3s ease,width 0.3s ease,background-image 0.3s ease;
}

.confirmed{
    height: 303px;
    border-radius: 22px;
    background: #EDF3EC url("../../images/accomodation/doneSVG.png")no-repeat center 55px;
    background-size: 174px;
    padding-top: 180px;
    p{
        position: relative;
        color: #444;
        font-size: 22px;
        font-weight: 700;
        // top: 180px;
        width: 100%;
        text-align: center;
    }
    p:nth-child(3){
        top: 210px;
        span{
            color: #0F7173;
        }
    }

    .next-button{
        position:absolute;
        right:38px;
        bottom: 29px;
        height: 37px;
        padding: 12px 35px 12px 38px;
        justify-content: center;
        align-items: center;
        gap: 7px;
        flex-shrink: 0;
        border-radius: 33px;
        border: 0.5px solid #C8C8C8;
        background: #FFF;
        box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
        color: #0F7173;
        font-size: 16px;
        @include flexCenter;
        transition: all 0.3s ease;

        &::after{
            @include generatePseudoElement("../../images/global-icon/next-arrow-green.svg",12px,12px)
        }

        &:hover{
            border: 0.5px solid #0F7173;
            background: #EDEAE0;
        }
    }
    .bookind-id-text{
        color:#444;
        font-weight: 400;
        span{
            color:#0F7173
        }
    }
}

.confirmed-mail{
    width: 731px;
    height: 340px;
    flex-shrink: 0;
    border-radius: 22px;
    background: #EDF3EC url("../../images/global-icon/mail-sending.svg")no-repeat center 55px;
    padding-top: 200px;
    @include flexCenter;
    flex-direction: column;
    p{
        position: relative;
        color: #444;
        font-size: 22px;
        font-weight: 700;
        // top: 180px;
        width: 100%;
        text-align: center;
    }
    p:nth-child(2){
        display: none;
    }
    .next-button{
        display: inline-flex;
        padding: 12.5px 14px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 33px;
        border: 0.5px solid #C8C8C8;
        background: #FFF;
        box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
        color: #0F7173;
        font-size: 16px;
        transition: all 0.3s ease;
        // position: absolute;
        &:hover{
            border: 0.5px solid #0F7173;
            background: #EDEAE0;
        }
    }
}


// Docket options parent
.docket_Options_parent{
    width: 100%;
    position: relative;
    .docket_head{
        width: 130px;
        height: 32px;
        border-radius: 28px;
        background: #fff;
        box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
        background: url("../../images/paxdownarr2.png") no-repeat 90% center;
        padding-left: 12px;
        color: #4a4a4a;
        cursor: pointer;
        @include alignCenter;
        font-size: 15px;
        font-weight: 500;
        &:hover {
          // background: #edeae0;
          // background-image: url("../../images/edit_quo.png");
          // background-repeat: no-repeat;
          // background-position: 12px center;
          // color: #0f7173;
          // transition: 0.3s;
          // background-size: 11px;
        }
      }
      .docket_body{
        padding: 16px 11px;
        border-radius: 7px;
        background: #FFF;
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
      }
}

.quotation-details-container {
    margin: 45px 0 0 0;

    .first-item-category {
        margin-left: calc($marginLeftItemLabel - 18px - 12px);
    }
    .second-item-category-transfer{
        background: url("../../images/global-icon/voiture-musclee.svg") no-repeat 0px center;
        padding-left: 25px;
        background-position-y: 3px;
        font-weight: 600;
        color: #4A4A4A;
        font-weight: 700;
    }
    
    .total-line-price{
        // display: flex;
        // justify-content: flex-end;
        color: $grey-calendar-color;
        font-weight: 700;

    }
    .container-trash{
        display: flex;
        align-items: center;
        justify-content: center;
        .separator {
            height: 100%;
            border-left: 1px solid #434343;
            // margin-left: 26px;
        }

        .image-trash {
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // margin-left: 20px;
            @include generatePseudoElement("../../images/delete.svg", 25px, 25px);
            margin: auto;

            &:hover {
                @include generatePseudoElement("../../images/trash-hover.svg", 25px, 25px);
            }
        }
    }
    .category-container {
        display: grid;
        grid-template-columns: 18px auto;
        gap: 13px;
         margin-top: 2px;
        .img-icon {
            float: left;
            background-size: cover;
        }
        .Accommodation-icon{
            @include generatePseudoElement("../../images/global-icon/accomodation-icon.svg",15px,15px)
        }

        .Transfer-icon{
            @include generatePseudoElement("../../images/chambre2.png",15px,15px)
            
        }

        .category-details {
            display: flex;
            flex-direction: column;
            gap: 12px;

            .category-title {
                color: $myColor7;
                font-weight: 700;
                height: 18px;
                @include flexCenter;
                justify-content: flex-start;
            }

            .category-hotel-name {
                color: $grey-calendar-color;
                font-weight: 700;
                .transfer-location{
                    font-weight: 500;
                }
            }
        }
    }
}

@keyframes shimmer {  
    0% {  
      background-position: 100% 0;  
    }  
    100% {  
      background-position: 0 0;  
    }  
  }  
  
.default-skeleton-load{
    // position: absolute;
  background: linear-gradient(  
    -90deg,  
    #f0f0f0 0%,  
    #e0e0e0 50%,  
    #f0f0f0 100%  
  );  
  background-size: 400% 400%;  

  animation: shimmer 1.5s ease-in-out infinite;  
}
.skeleton-container{
    width: 100%;
    height: auto;

    img{
        object-fit: cover;
        width: 100%;
        height: auto;
    }
}

.token-expired-container{
    border-radius: 8px;
    background: #FFF;
    padding: 24px 33px 30px 29px;
    @include flexStart;
    flex-direction: column;
    .token-expired-title{
        @include title;
        align-self: flex-start;
        margin:0;
    }

    .token-message-container{
        @include flexStart;
        flex-direction: column;
        padding: 0 27px;
    }

    .token-image{
        object-fit: cover;
        width: 117px;
        height: 99px;
        margin-top: 42px;
    }

    .token-message{
        @include flexCenter;
        flex-direction: column;
        color: $paraColor;
        margin-bottom: 0;
        margin-top: 32px;
        font-size: 14px;
        font-weight: 400;
    }


    .token-button{
        @include button-success;
        padding: 5px 28px;
        margin-top: 37px;
    }
}

.cancel-update-container{
    width: 426px;
    height: 195px;
    border-radius: 11px;
    background: #FFF5F5;
    box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
    @include flexCenter;
    flex-direction: column;
    color: #444;
    font-size: 16px;
    font-weight: 700;
    position: relative;

    .cancel-update-title{
        font-size: inherit;
        font-weight: inherit;
        margin-bottom: 12px;
        @include remove-white-space;
        @include flexCenter;
    }

    .refCode-subtitle{
        
        margin-bottom: 27px;
        @include remove-white-space;
        @include flexCenter;
        font-weight: 400;
        .refCode-label{
            color: $color-red-light;
        }
    }
    .cancel-update-button{
        display: inline-flex;
        height: 36px;
        width: 145px;
        padding: 12px 47px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 27px;
        background: $color-red-light;
        box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
        color: #FFF;
        font-weight: 500;
        transition: background-color 0.3s ease;
        margin-bottom: 18px;
        &:hover{
            background: #A75555;
        }
    }
    .continue-update-button{
        cursor: pointer;
        border-radius: 33px;
        border: 0.5px solid #C8C8C8;
        background: #FFF;
        box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
        transition: color 0.3s ease;
        width: 79px;
        height: 36px;
        //padding: 12px 14px;
        &:hover{
            color:$myColor7;
        }
    }
}

.delete_item_popup{
    background-color: #fff;
    padding: 60px 69px 44px 69px;
    border-radius: 29px;
    max-height: 854px;
    // overflow-y: auto;
    width: 90%;
    .table-layout-parent{
        margin: 41px 0 80px;
    }
    .grid-layout:hover {
        border-radius: 20px;
        background-color: rgba(88, 5, 5, 0.31);
        transition: background-color 300ms, box-shadow 300ms;
        opacity: 0.59;
        .image-trash {
            @include generatePseudoElement("../../images/trash-hover.svg", 25px, 25px);
        }
        .separator {
            border-left: 1px solid #83484F;
        }
    }
}

.exportpdf-parent{
    width: 391px;
    height: 171px;
    border-radius: 10px;
    background: #FFF;
    padding-top: 46px;
    position: relative;
    p{text-align: center;margin-bottom: 0;}
    .title{
        &::before {
            margin-right: 9px;
            @include generatePseudoElement("../../images/expo_export.png", 14px, 14px);
            vertical-align: middle;
            position: relative;
            top: 3px;
            top: -1px;
        }
        text-align: center;
        margin-bottom: 0;
        font-family: Satoshi;
        font-weight: 700;
        font-size: 16px;
        line-height: 21.6px;
        letter-spacing: 0%;
    }
    p:nth-child(2){
        color: rgba(132, 132, 132, 1);
        margin-top: 10px;
        font-family: Satoshi;
        font-weight: 400;
        font-size: 14px;
        line-height: 18.9px;
        letter-spacing: 0%;
    }
    label{
        font-family: Satoshi;
        font-weight: 400;
        font-size: 14px;
        line-height: 18.9px;
        letter-spacing: 0%;
        color: rgba(67, 67, 67, 1);
    }
    .containerCheckBox{
        position: absolute;
        bottom: 19px;
        display: flex;
        left: 19px;
        justify-content: center;
        align-items: center;
        @include flexCenter;
        input[type="checkbox"] {
                appearance: none;
                border: solid 0.5px #E8E8E8;
                margin-right: 8px;
                -webkit-appearance: none;
                -moz-appearance: none;
                @include flexCenter;
                height: 19px;
                width: 15px;
                height: 15px;
                padding: 3px 1px;
                flex-direction: column;
                gap: 10px;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
                flex-shrink: 0;
                border-radius: 2px;
                // background: #E7E7E7;
                transition: background-color 0.3s, border-color 0.3s;
                cursor: pointer;
        
                &:checked {
        
                    &::before {
                        content: "";
                        display: block;
                        width: 80%;
                        height: 90%;
                        background: url("../../images/global-icon/check-green.svg") no-repeat;
                        background-size: cover;
                    }
                }
        
                &:hover {
                    background: #E2F2F2;
                }
            }
    }
    button{
        margin-top: 40px;
        position: absolute;
        bottom: 14px;
        right:14px;
        border-radius: 8px;
        background: rgba(15, 113, 115, 1);
        @include flexCenter;
        color:#fff;
        box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);

        width: 130px;
        height: 31px;
        border-radius: 8px;
        padding-top: 11px;
        padding-bottom: 11px;
        font-family: Satoshi;
        font-weight: 700;
        font-size: 12px;
        line-height: 16.2px;
        letter-spacing: 0%;
    }
}

.quotation-mail-popup{
    width: 495px;
    height: 490px;
    border-radius: 10px;
    background: #FFF;
    padding: 27px 16px 16px 24px;
    input[type="text"]{
        width: 319px;
        height: 30px;
        border-radius: 5px;
        border: 0.5px solid #E7E5E5;
        background: #F8F8F8;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        border: none;
        outline: none;
        padding: 10px 0 10px 17px;
        margin-bottom: 13px;
    }
    textarea{
        border: none;
        outline: none;
        width: 447px;
        padding: 10px 45px 25px 18px;
        border-radius: 5px;
        border: 1px solid #E7E5E5;
        background: #F8F8F8;
        max-height: 174px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        font-size: 14px;
    }
    .button-block{
        position: relative;
        width: 100%;
        margin-top: 65px;
        display: flex;
        justify-content: flex-end;
        button {
            border-radius: 8px;
            background: #0F7173;
            box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
            color: #fff;
            @include flexCenter;
            width: 101px;
            height: 31px;
        }
    }
    .error{
        display: block;
        position: relative;
        bottom: 10px;
    }

}

.quote-detail-parent{
    position: relative;
    .quote-items-blocks{
        // background-color: #FAFAFA;
        padding:46px 198px 46px 40px;
        // float: left;
        margin-bottom: 100px;
        border-radius: 32px;
        width: 100%;
        box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
        .quote-info{
            display: flex;
            justify-content: space-between;
            margin-bottom: 54px;
        }
    }
    
}

.quote-items-display{
    display: grid;
    width: 100%;
    grid-template-columns: 520px auto;
    column-gap: 50px;
    margin-bottom: 50px;

    .quote-items-display-hotels{

        &-names{
            font-weight: 600;
            font-size: 14px;
        }
        .amenities_list{
            overflow-y: hidden;
            margin-bottom: 44px;

            .amenities_list_hotel{
            border-radius: 34px;
            border: 1px solid #FFAB63;
            padding: 6.5px 18px 6.5px 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 28px;
            color: #434343;
            font-size: 14px;
            font-weight: 600;

            &.person-icon{
                background: url("../../images/global-icon/user-grey.svg") no-repeat 0px center;
                background-position :7px 7px;
                font-weight: 600;
                color: #4A4A4A;
            }
            &.meal-plan-icon{
                background: url("../../images/global-icon/cutlery-grey.svg") no-repeat 0px center;
             padding: 6.5px 18px 6.5px 24px;
             background-position-x: 8px;
            }
        }
    }
    .quote-items-display-hotels-img{
        width: 518px;
        height: 452px;
        gap: 0px;
        border-radius: 25px;
        box-shadow: 0px 1px 6.7px 0px #00000030;
    }
    }

    &-amenities{
        .amenities-list{
            position: relative;
            top:120px;
            .category-name{
                display: flex;
                color: #434343;
                font-size: 14px;
                line-height: 22px;
                margin-bottom: 18px;
                font-weight: 500;
                white-space: nowrap;
                height: 1em;
    
                img{
                    position: relative;
                    top: 3px;
                }
            }
            ul{
                display: flex;
                margin-bottom: 24px;
                gap: 18px 38px;
                padding-left: 25px;
            }
        }
    } 
    // img{
    //     width: 518px;
    //     height: 452px;
    //     gap: 0px;
    //     border-radius: 25px;
    //     border: 0px 0px 0px 7px;
    //     opacity: 0px;
    // }
}

.quote-det-policy-parent{
    color: #797979;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
    @include lineHeight;
    .quote-det-period{
        border: 1px solid #FFAB63;
        border-radius: 18px;
        width: fit-content;
        padding: 8px 23px;
        margin-bottom: 19px;
    }
    .quote-det-price{
        color: #434343;


    }
   
}

.cancellationInfo-parent{
    border-radius: 23px;
    border: 1px solid rgba(255, 171, 99, 0.30);
    background: #FFF;
    box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
    padding: 16px 28px 21px 24px;
    width: fit-content;

    .cancellationInfo-service{
        color: #434343;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 22px;
        padding-left: 24px;
        
    }
    .cancellationInfo-location{
        color: $myColor7;
        font-size: 16px;
        font-weight: 600;
        padding-left: 6px;
        position: relative;
        margin-bottom: 15px;
        &::before{
            content: "";
            width: 3px;
            height: 14px;
            background: #D9D9D9;
            position: absolute;
            left: 0;
            top:4px
        }
    }
}

.removeNightscss{
    box-shadow: unset; 
    padding: unset; 
    background-size: 11px;
}
.Accomodationicon{background: url("../../images/whitechambre1.png")no-repeat 0 center;}
.Transfericon{background: url("../../images/whitechambre2.png")no-repeat 0 center;}


// image layout
.image-grid-container {
    display: grid;
  grid-template-columns: 1fr; // One column for mobile view
  grid-template-rows: auto;

  img {
    width: 100%;
    height: 238px;
    box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
    border-radius: 25px;
    object-fit: cover;
  }

  // First image spans the entire width
  img:nth-child(1) {
    grid-column: 1 / -1; // Spans across all columns
  }

  // At least 768px, apply two-column layout for second and third images
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr; // Two equal-width columns
    gap:18px
  }

  }

.datesAtom{
    color:$myColor7;
    font-size: 14px;
    font-weight: 500;
    span.quotation_tour_date_first-date,span.quotation_tour_date_second-date{
        // margin-left: 3px;
        &::before{
            margin-right:9px;
            @include generatePseudoElement("../../images/calendardark.png",11px,11px);
            width: 12px;
            vertical-align: middle;
            position: relative;
            top: 3px;
            top: -1px;
        }
    }
    span.quotation_tour_date_arrow{
        margin:0 9px 0 8px;
        position: relative;
        bottom: 1.2px;
    }
}

.accom-quote-detail-parent{
    width:100%;
    // border-radius:32px;
    // background: #FAFAFA;
    // box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
    padding: 46px 43px 46px 40px;
    margin-bottom: 40px;

    &:not(:first-of-type){
        margin-top: 40px;
    }
    .accom-detail-block1{
        display:flex;
        justify-content:space-between;
        margin-bottom:54px;
        width: 100%;
        .price{
            border-radius: 16px;
            background: $myColor7;
            width: fit-content;
           @include flexCenter;
           padding: 8px 9px;
            height: 28px;
            color: #fff;
            font-size: 16px;
        }
    }
    .accom-detail-block2{
        color: #434343;
        font-size: 16px;
        font-weight: 500;
        @include lineHeight;
        margin-bottom: 82px;

        p{
            color: #434343;
            font-size: 14px;
            font-weight: 600;
        }
        
    }

    .accom-detail-block3{
        display:flex;
        gap: 42px;
        margin-bottom:54px;
        .amenities_block{
            width: 784px;
            height: 294px;
            background-color: #fcfcfc;
            box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
            padding: 28px 29px 48px 29px;
            border-radius: 16px;
            overflow: hidden;
            &>p:first-child{
                margin-bottom:27px;
            }

            &>div{
                height: 100%;
                overflow-y: hidden;
                
                .amenities_list {
                    overflow-y: hidden;
                    height: 100%;
                    row-gap: 21px;
                }
            }
        }
    }
    .transfer-carBlock{
        display: flex;
        gap: 40px;

        .transfer-details{
            display: flex;
            gap:7px;
            div{
                border-radius: 16px;
                border: 1px solid #FFAB63;
                background: #FFF;
                height: 28px;
                padding: 8px 18px 8px 15px;
                font-size: 14px;
                font-weight: 400;
                @include flexCenter;
            }
        }
    }

   ul{
    width: 100%;
    display: flex;
    gap: 30px;
    li{
        color: $myColor7;
    }
   }
}

.icon-question-mark-yellow{
    @include generateIconClass("../../images/global-icon/question-mark-yellow.svg",27px,27px)
}
.icon-true-green{
    @include generateIconClass("../../images/global-icon/true-green.svg",27px,27px)
}
.important-asterisk{
    color:  $orange-color;
    font-size: 18px;
    font-weight: 700;
}

.service-box{
    border-radius: 35px;
    border: 1px solid #C2D8D5;
    background: #FFF;
    padding: 44px 50px 44px 32px;
    width: 757px;
    box-shadow: 0px 2px 5.5px 0px rgba(0, 0, 0, 0.18);

    &.not-filled{
        border: 1px solid #C16161;
        box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
    }
    .service-name{
        border-left: 4px solid #FFAB63;
        margin-bottom: 15px;
        line-height: 14px;
        color: #797979;
        font-size: 18px;
        font-weight: 900;
        padding-left: 14px;
        gap: 10px;
        @include flexStart;
        &::before{
            @include generatePseudoElement('../../images/global-icon/bed-green.svg',16px,17px);
        }
    }
    .service-name-transfer{
        border-left: 4px solid #FFAB63;
        margin-bottom: 15px;
        line-height: 14px;
        color: #797979;
        font-size: 18px;
        font-weight: 900;
        padding-left: 14px;
        gap: 10px;
        @include flexStart;
        &::before{
            @include generatePseudoElement('../../images/global-icon/transfer-icon.svg',16px,17px);
        }
    }
    .service-item-name{
        color: #4A4A4A;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 15px;

        span.date-arrow{
            color: #4A4A4A;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 15px;
        }
    }
    .service-room-name{
        color: #4A4A4A;
        font-size: 14px;
        font-weight: 500;
        @include flexStart;
        gap: 7px;
        margin-bottom: 41px;

        &::before{
            @include generatePseudoElement("../../images/global-icon/double-bed-grey.svg",14px,15px);
        }
    }
    .service-transfer-name{
        color: #4A4A4A;
        font-size: 14px;
        font-weight: 600;
        @include flexStart;
        gap: 7px;
        margin-bottom: 41px;

        &::before{
            @include generatePseudoElement("../../images/global-icon/transfer-booking-pax.svg",14px,15px);
        }
        &-car{
           font-weight: 300; 
        }
    }
    .service-instruction{
        color: $myColor7;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 20px;
    }
    .service-box-remarks{
        border-radius: 25px;
        border: 0.5px solid #898989;
        background: #FAFAFA;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        // height: 121px;
        padding: 21px 10px 90px 20px;
        color: #666;
        font-size: 14px;
        font-weight: 400;
        margin-top: 20px;
        min-width: 585px;
        resize: none;
        &::placeholder{
            line-height: normal;
        }
        &:focus-visible{
            outline: none;
        }
        @include hide-scrollbar-y;
    }
    .service-pax-info-container{
        @include flexStart;
        gap:23px;
        flex-wrap: wrap;
        position: relative;
        width: fit-content;

        .toast-error-form{
            position: absolute;
            inset: auto 0 auto auto;
            transform: translate(calc(100% + 24.5px),0%);
            transition: none;
            height: 57px;
            color: #C16161;
            font-size: 14px;
            font-weight: 700;
            z-index: 0;
        }
    }
    .service-pax-container{
        @include flexStart;
        align-items: stretch;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap:9px;
        width:570px;
        &.match-pax{
            opacity: 0.24;
            .service-box-client{
                pointer-events: none;
            }
        }
    }
    .service-box-client{
        // min-width: 121px;
        max-width: 185px;
       
        min-height: 110px;
        border-radius: 7px;
        border: 0.5px solid #AEAEAE;
        background: #FFF;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        padding: 10px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        @include flexSpace;
        gap:20px;
        position: relative;
       
        &:hover{
            background: #EBEBEB;
        }

        &.checked{
            border: 1px solid $myColor7;
            color: $myColor7;
            
           
            .service-pax-info{
                position: relative;
                .service-box-client-name{
                    color: $myColor7;
                    margin-top: 5px;
                    margin-bottom: 15px;
                }
            }
        
        }
        &.seat-included{
            &::before{
                content: "";
                width: 122px;
                height: 56px;
                position: absolute;
                opacity: 0.5;
                background: #fff;
                top: 0;
                left: 0;
                border-radius: 0.5px;
                pointer-events: none;
                z-index: 100;
            }
         }  

        &.not-filled{
            border: 0.5px solid #C16161;
            background: #F6E6E6;
        }
        // .seats-block{
            .service-pax-info{
                color: #434343;
                font-size: 14px;
                font-weight: 700;
                position: relative;
                .service-box-client-name{
                    color: #666;
                    font-size: 12px;
                    font-weight: 400;
                    margin-top: 5px;
                    margin-bottom: 15px;

                    height: 20px
                }
                .add-baby-seat{
                    @include greenButton;
                    margin:10px 0 0 0;
                    height: 30px;
                    position: absolute;
                    bottom:0;
                    width: 140px;
                    padding: 0 15px 0 15px;
                    &.selected{
                        background-color: #8CB5B0;
                        border:none
                    }
                }
            }
            .service-pax-rank{
                min-width: 20px;
                height: 20px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                align-self: center;
                gap: 20px;
                border-radius: 50%;
                border: 1px solid rgba(174, 174, 174, 0.15);
                background: #D9D9D9;
                box-shadow: 0px 1px 0.85px rgba(0, 0, 0, 0.08) inset;
                color: #FFF;
                font-size: 14px;
                font-weight: 700;
                @include flexCenter;

                &.not-filled{
                    background: #DDBEBE;
                }
                
                &:not(:empty){
                    box-shadow: 0px 1px 1.7px 0px rgba(0, 0, 0, 0.08) inset;
                    background: #8CB5B0;
                    stroke-width: 0.15px;
                    stroke: #D9D9D9;
                }
            }
       // }
         
        .seatAdded{
            color: #0F7173 ;
            font-size: 12px;
            position: absolute;
            bottom: -20px;
            &:hover{
              color:  #0C5657;
            }
            
        }
    }
}

// Search agency Parent
.search-agency-parent{
    position: relative;
    .search-input{
        width: 383px;
        height: 38px;
        border-radius: 32px;
        background: #EDEAE0 url("../../images/search-agency-icon.png") no-repeat 20px center;
        border: none;
        outline: none;
        padding-left: 50px;
    }
   
}

.list-container {
  
   height: 230px !important;
   
ul{
    padding-left: 0;
}
    
    /* Custom scrollbar styles */
    &::-webkit-scrollbar {
      width: 7px;
    }
  
    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background: #e6e6e6;
    }
  
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: #696969;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    }
.agency-lists{
    border-bottom: 1px solid #AEAEAE;
    width: 95% !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
}
.container-popup-booking{
    width: 608px;
    transition: height 0.3s ease, padding-top 0.3s ease, margin-bottom 0.3s ease;
    .success {
        height: 367px;
        padding-top: 33px;
        transition: inherit;
        /* Hérite des transitions définies sur le parent */
    }

    .rejected {
        height: auto;
        /* Exemple de hauteur différente */
        padding-top: 20px;
        margin-bottom: 30px;
        transition: inherit;
    }
    
    border-radius: 26px;
    background-color: #EDF3EC;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .img-success{
        width: 186px;
        height: 161px;
        margin-bottom: 36px;
        transition: opacity 0.3s ease, transform 0.3s ease;
    }
    .message{
        line-height: 0 !important;
        font-family: Satoshi;
        font-size: 22px;
        font-weight: 700;
        line-height: 29.7px;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color:#444444;
    }
    .messageDeclined{
        font-family: Satoshi;
        font-size: 22px;
        font-weight: 700;
        line-height: 29.7px;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #444444;
    }
    .userActif{
        font-family: Satoshi;
        font-size: 16px;
        font-weight: 400;
        line-height: 21.6px;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #444444;
        span{
            color: #0F7173;
        }
    }
    .userActif.declined{
        span{
            color: #F66666;
        }
    }
    .button-b{
        margin-top:  34px !important;
        width: 165px;
        height: 37px;
        padding: 12.5px 14px 12.5px 14px;
        gap: 10px;
        border-radius: 33px;  
        background: #FFFFFF;
        border: 0.5px solid #C8C8C8;
        font-family: Satoshi;
        font-size: 16px;
        font-weight: 500;
        line-height: 21.6px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        display: flex;
        margin: auto;
        align-items: center;
        box-shadow: 0px 1px 6.7px 0px #00000030;
        color: #0F7173;
        justify-content: center;
        &:hover{
            background: #EDEAE0;
        }
    }
    .button-b.rejected {
        color: #C16161;
        margin-bottom: 34px;
    }
    .button-b.next {
        width: 129px;
    }
    .img-arrow {
        width: 12px;
        height: 12px;
    }

}

.container-button-carousel {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    position: relative;
    .carousel-button {
        display: flex;
        gap: 3px;
        position: absolute;
        right: 10px;
        bottom: 0px;

        .item-button {
            width: 8px;
            height: 8px;
            background-color: #ccc;
            border-radius: 50%;
            transition: background-color 0.3s;

            &:hover {
                background-color: #888;
            }
        }
    }
}


.notif-wrapper {
    position: fixed;
    top: 90px;
    right: 11px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 100;
width: 586px;
}
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
        /* Légèrement en haut */
    }

    to {
        opacity: 1;
        transform: translateY(0);
        /* Position normale */
    }
}

.container-notif {
    animation: fadeIn 1s ease-in-out;
    animation-fill-mode: forwards;
    /* Maintien la dernière étape de l'animation */
    position: relative;
    display: flex;
    align-items: center;
    padding-block: 44px;
    padding-inline: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: rgb(26, 25, 25);
    background: #FFFFFF;
    width: 586px;
    height: 172px;
    gap: 20px;
}
.container-notif.success{
    border: 5px solid #0F7173;
}
.container-notif.rejected{
    border: 5px solid #C16161;
}

.statusMessage {
    font-family: Satoshi;
    font-size: 22px;
    font-weight: 700;
    line-height: 29.7px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

/* Partie gauche (icône ou image) */
.container-notif .notif-icon {
    // flex: 0.4 1;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: red;
}

/* Partie droite (texte) */
.container-notif .notif-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .user-booking{
        font-family: Satoshi;
        font-size: 16px;
        font-weight: 400;
        line-height: 21.6px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }

}
.popup-delete-item{
    width: 541px;
    height: 329px;
    background-color: #FFF5F5;;
    border-radius: 11px;
    padding-top: 26px;
    padding-left: 25px;
    p{
        margin: 0px
    }
    .title-popup{
        font-family: Satoshi;
        font-size: 16px;
        font-weight: 700;
        line-height: 21.6px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }
    .title-popup.ex{
        font-size: 14px;
    }
    .item-deleted{
        width:490px;
        height:137px;
        padding: 20px 157px 20px 26px;
        gap: 10px;
        border-radius: 16px;
        background-color: #FFFFFF;
        margin-top: 38px;
        padding-top: 20px;
        padding-left: 26px;
        box-shadow: 0px 1px 4px 0px #00000040 inset;
    }
    .img-delete-service{
        width: 18px !important;
        height: 18px;
        position: relative;
        top: -1px;
    }
    .name-service-delete{
        font-family: Satoshi;
        font-size: 14px;
        font-weight: 700;
        line-height: 18.9px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #C16161;
    }
    .button-action{
        margin-top: 32px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-right: 26px;
        .cancel{
            width: 79px;
            height: 36px;
            gap: 10px;
            border-radius: 33px;
            border: 0.5px ;
            background-color: #FFFFFF;
            font-family: Satoshi;
            font-size: 16px;
            font-weight: 500;
            line-height: 21.6px;
            color: #434343;
            box-shadow: 0px 1px 6.7px 0px #00000030;
            &:hover {
                background: #EDEAE0;
            }
        }
        .delete{
            width: 141px;
            height: 36px;
            gap: 10px;
            border-radius: 27px;
            background-color: #C16161;
            font-family: Satoshi;
            font-size: 16px;
            font-weight: 500;
            line-height: 21.6px;
            color: #ffffff;
            margin-left: 10px;
            box-shadow: 0px 1px 6.7px 0px #00000030;
            &:hover {
                    background: #A75555;
            }
        }
    }
}
.container-notif-delete-quotation {
    display: flex;
    width: 706px;
    height: 70px;
    margin: auto;
    gap: 46px;
    border-radius: 17px;
    opacity: 1;
    background-color: rgb(140, 181, 176);
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    .font-notif{
        color: rgba(255, 255, 255, 1);
        font-family: Satoshi;
        font-size: 22px;
        font-weight: 900;
        line-height: 29.7px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }
}
.container-notif-sendMail-quotation {
    display: flex;
    width: 706px;
    height: 70px;
    margin: auto;
    gap: 46px;
    border-radius: 17px;
    opacity: 1;
    background-color: rgb(140, 181, 176);
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top:100px;
    margin-bottom: 0;
    .font-notif{
        color: rgba(255, 255, 255, 1);
        font-family: Satoshi;
        font-size: 22px;
        font-weight: 900;
        line-height: 29.7px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }
}
.container-notif-delete {
    position: fixed;
    top: 100px;
    width: 100%;
    opacity: 1;
    z-index: 100;
    transform: translateY(0);
    transition: opacity 3s ease, transform 3s ease;
}
.container-notif-delete.hide {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 3s ease, transform 3s ease;
}

.flight-Number-box{
    width: 620px;
    height: 213px;
    padding: 16px 28px 21px 24px;
    border-radius: 23px;
    border: 1px solid rgba(255, 171, 99, 0.3);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    margin-bottom: 56px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .quote_input_flight_title{
        @include text-before-icon($txtColor:#434343,$txtSize:18px,$txtWeight:700,$iconPath:"../../images/whitechambre2-big.png",$iconHeight:21px,$iconWidth:20px)
    }
    .quote_input_flight_subtitle{
        @include title-before-line($txtColor:#0F7173,$txtSize:16px,$txtWeight:600,$lineColor:#D9D9D9,$borderWidth:3px)
    }
    .quote_input_flight_date{
        @include text-before-icon($txtColor:#0F7173,$txtSize:14px,$txtWeight:600,$iconPath:"../../images/calendardark.png",$iconHeight:16px,$iconWidth:16px); 
    }
    .flight-input {
        width: 304px;
        height: 47px;
        border-radius: 35px;
        border-width: 1px;
        padding: 16px 30px 16px 20px;
        border: 1px solid rgba(217, 217, 217, 1);
        box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
        outline: none;
        padding-left:55px;
        &:focus {
            border: 1px solid rgba(15, 113, 115, 1);
            background-color: rgba(237, 234, 224, 1);
        }
        background: url("../../images/departure-plane.png") no-repeat;
        background-position: 20px center;
        background-size: 18px 15px;
        // &::before{
        //     @include generatePseudoElement("../../images/global-icon/map.svg", 11px, 11px)
        // }
        // @include input-default($width:304px,$height:47px,$pleft:60px,$bg-color:#FAFAFA,$bg-img:url("../../images/gray-plane-big.png"));
    }
}


.offers-parent-popup{
    padding: 40px 25px 40px 39px;
    border-radius: 26px;
    background: #FFF;
    width: 1127px;
}

.docket-price-parent{
    display: flex;
    justify-content: space-between;
    .price-list{
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
}