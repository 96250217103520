$myColor1:#000;
$myColor2:#666565;
$myColor3:#F4F4F4;
$myColor4:#9F9F9F;
$myColor5:#7E7D7D;
$myColor6:#fff;
$myColor7:#0F7173;
$myColor8:#F3F3F3;
$greenDark:#033132;
$para-grey:#4a4a4a;
$orange-color:#FFA75D;
$grey-calendar-color:#434343;
$warning-red-color:#FF9C9C;
$color-blue:#3F7B9C;
$color-light-beige:#EDEAE0;
$color-red-error:#D24D4D;
$color-red-light:#C16161;

//common css prop...............
$boxSizing:border-box;



// select width...............
$selectWidth:422px;
$selectBorderRadius:31px;

// select width22...............
$selectWidth1:253px;
$selectBorderRadius:18px;

//input box...................
$borderNone:none;


// paragraph font
// para heading #oaoaoa confirmed by patrick
// paragraph #161616 confirmrd by patrick$

$paraHeadColor:#0A0A0A;
$paraColor:#161616;
$paraFontSize:14px;
$paraFontSize2:16px;
$paraFontWeight:400;

 //lineHeight Normal

//calendar
$dimensionDay:32px;
$shiftSd:1px;
$borderRadiusCalendar:16px;

//paxDropdown
$signPaxSize:47px;
$incrementPaxWidth:37px;
$incrementPaxHeight:36px;

//Map
$mapBorderRadius:27px;

//Search Input
$critereaGlobalSearchHeight:62px;
$globalSearFontSize:15px;
$lineHeightFirstLabelSearch:0.9;

//filter
$filterBoxWidth:156px;
$filterDropdownWidth:289px;
$spaceSearchHeaderResult:55px;

//hotel-details
$hotelDetailsWidth:68vw;

//carousel
$carouselContainerHeight:42vh;
$gapSecondaryImages:23px;
$secondaryImageMaxWidth:348px;

//hotel amenities
$containerHotelAmenitiesWidth:67vw;

//room-popup
$imageRoomPopupHeight:37.1vh;
$paddingContentPopupBottom:33.84px;
$paddingContentPopupLeft:30.84px;

//also-like
$ratioImgAlsoLike:calc(397 / 424);
$ratioImgWidth:0.3328;
$imgImgAlsoLikeWidth:22.08vw;

//quotation details
$marginLeftItemLabel:81px;
$paddingItemQuotation:51px;

//docket
$docketWidth:384px;

$rightBookingDocket:6.197916667vw;

$navbarHeight:78px;

$gridRowSpacing:12px;