


@mixin inputGroupStyles{
    width: 100%;
    float: left;
    flex-direction: row;
    display: flex;
    justify-content: space-around;
}

@mixin positionRelative{
    width:auto;
    position: relative;
}


@mixin selectDropdown($value){
    width:$value;
    position: absolute;
    font-size: 16px;
    font-family: Satoshi-Regular-Regular;
    height: 306px;
}

@mixin optionSpace{
   width: 490px ;
    padding: 7px 33.356px 7px 10px;
    box-sizing: border-box;
    margin: 18px 0 0;
    cursor: pointer;
    
}

@mixin ex_search{
    width: 228px;
    height: 48px;
    border-radius: 29px;
    background: #ffa75d;
    box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
    border: none;
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: Satoshi-Bold;

}

///  flex box accessories....................
@mixin flexStart{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

@mixin flexEnd{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@mixin alignCenter{
    display: flex;
    align-items: center;
}

@mixin flexCenter{
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flexSpace{
    display: flex; 
    justify-content: space-between;
}

////////////////////////////////////***********  PROJECT 1  ***********************//////////////////////////////////////////////////////////////////////////////////////



@mixin parentDiv{
    width:100%;
    float: left;
    position: relative;
    margin: 0;
    padding: 0;
    height: 100vh;
}



@mixin imgWidth{
    max-width: 100%;
    width:100%
}

@mixin lineHeight{
    font-style: normal;
    line-height: normal;
}

@mixin float_width{
    width:100%;
    float:left;
}

@mixin popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background: #080808; 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    //opacity: 86%;
}

@mixin disable{
    opacity: 0.5;
    pointer-events: none;
  }

  @mixin inputbox_background{
    width: 1128px;
    height: 248px;
    border-radius: 31px;
    background: #FFF;
    box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.26);
    backdrop-filter: blur(4.5px);
}   

@mixin inputs_midline{
    background-image: url("../../images/Group 26.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 17px 
   }  


@mixin close-icon{
    position: absolute;
    right: 10px;
    top:10px;
    cursor:pointer
} 

@mixin line-name{
    color: $myColor7;
    font-size: 38px;
    font-weight: 700;
    border-left: 6px solid #FFAB63;
    line-height: 32px;
    padding-left: 10px;
   }

@mixin no-margin-x{
    margin-right: 0!important;
    margin-left: 0!important;
}

@mixin arrow-calendar{
    background-image: url("../../images/calendar/arrowGrey.png")!important;
    background-size: 11px 11px;
    background-repeat: no-repeat;
    background-position: center;
}

@mixin arrow-pax{
    content:"";
    background-image: url("../../images/calendar/arrowLightGrey.png");
    transition: 0.2s;
    transform: rotate(180deg);
}
@mixin remove-white-space{
    white-space: nowrap;
    height: 1em;
}
@mixin pax-number{
    color: #434343;
    font-size: 16px;
    font-weight: 400;
    text-align: right;
}

@mixin absoluteLayout{
    
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

@mixin hide-scrollbar-x{
    overflow-x: auto;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  
    &::-webkit-scrollbar {
      display: none;
    }
  }
@mixin hide-scrollbar-y{
    overflow-y: auto;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  
    &::-webkit-scrollbar {
      visibility:hidden;
    }
  }

@mixin justify-content-top{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

@mixin greenButton{
    border-radius: 18px;
    border: 1px solid #16A391;
    background: #0F7173;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
    padding: 0px 25px 0px 20px;
    color: #FFF;
    
}

@mixin justify-content-bottom{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}
@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};      
}
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
      @content;
    }
    @-moz-keyframes #{$animation-name} {
      @content;
    }  
    @-ms-keyframes #{$animation-name} {
      @content;
    }
    @-o-keyframes #{$animation-name} {
      @content;
    }  
    @keyframes #{$animation-name} {
      @content;
    }
  }

@mixin fade-in($duration){
    animation: fade-in #{$duration}s ease-in-out;
}

@mixin fade-out($duration){
    animation: fade-out #{$duration}s ease-in-out;
}

@mixin close-button{
    border-radius: 50%;
    border:1px solid $myColor7;
    background: url("../../images/global-icon/x-close-button.svg"); 
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease;
    background-color: #D9D9D9;
    cursor: pointer;
    &:hover{
        background: url("../../images/global-icon/x-close-button-hover.svg"); 
        background-repeat: no-repeat;
        background-position: center;
        background-color: #A3A3A3;
    }
}

@mixin arrow-dropdown{
    content: "";
    width:11px;
    height:11px;
    background: url("../../images/global-icon/arrow-down-grey.svg");
    position: relative;
    @include flexCenter();
    transition: transform 0.3s ease;
}
@mixin arrow-dropdown-more-options{
    content: "";
    width:8px;
    height:5px;
    background: url("../../images/global-icon/arrow-down-sign-to-navigate.svg");
    position: relative;
    @include flexCenter();
    transition: transform 0.3s ease;
    top:.7px
}


@mixin generatePseudoElement($url,$height,$width,$position:center){
    content: "";
    width: $width;
    height: $height;
    background: url($url);
    background-repeat: no-repeat;
    background-position: $position;
    background-size: contain;
    display: inline-block;
}

@mixin details-item($urlIcon,$heightIcon,$widthIcon,$distTextLeft:22px){
    color: $grey-calendar-color;
    font-size: 14px;
    font-weight: 500;
    @include flexStart;
    @include remove-white-space;
    // align-items: flex-start;
    gap:calc($distTextLeft - $widthIcon);
    text-wrap: wrap;
    height: auto;
    &::before{
        flex:0 0 auto;
        @include generatePseudoElement($urlIcon,$heightIcon,$widthIcon);
    }

}

@mixin select-options {
    border-radius: 14px;
    background: #FFF;
    box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
    padding: 12px 15px;
    margin-top: 9px;
    position: absolute;
    width: 100%;
    z-index: 200;

    div {
        display: flex;
        padding: 7px 12px 7px 12px;
        justify-content: flex-start;
        align-items: center;
        color: #797979;
        font-size: 16px;
        font-weight: 400;
        height: 23px;
        margin-bottom: 10px;
        border-radius: 9px;
        cursor: pointer;
        transition: background-color 0.2s ease;
        isolation: isolate;
        overflow: hidden;
        
        span{
            z-index: -1;
            display: inline-block;
            // position: absolute;
            // left: 0;
            transition: transform 0s;
        }
        
        &:hover {
            background: #E7E5E5;
            span{
                // animation: autoScrollHorizontal 3s linear infinite;
            }
        }
    }

    .opt-selected {
        background: #0F7173;
        color: #fff;
        border-radius: 9px;

        &:hover {
            border-radius: 9px;
            background: #0F7173;
        }
    }

    .client-opt {
        &:hover {
            @extend .opt-selected
        }
    }
}

@mixin pax-tags{
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
        &>div{
            width: fit-content;
            padding: 1px 11px;
            border-radius: 13px;
        }
        .paxselected{
            border: 1px solid #0F7173;
            background: #0F7173;
            color: #fff;
        }
        .paxunselected{
            border: 1px solid #0F7173;
            background: #FFF;
            color: #0F7173;
        }
}

@mixin id-link{
    // padding-left: 29px;
    color: $myColor7;
    font-weight: 500;
    text-decoration-line: underline;
    text-underline-offset: 3px;
    cursor: pointer;
}

@mixin generateIconClass($path,$height,$width){
    @include flexCenter;
    &::after{
        @include generatePseudoElement($path,$height,$width);
    }
}

@mixin button-success{
    border-radius: 10px;
    background: $myColor7;
    box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    transition: background-color 0.3s ease;

    &:hover{
     background: $greenDark;   
    }

}

@mixin title($fontSize:20px,$color:#0F7173){
    color: $color;
    font-size: $fontSize;
    font-weight: 700;
    line-height: normal;
}

@mixin saw-tooth-vertical($width: 12px, $bg: #fff) {
    background-image:linear-gradient(45deg, $bg 50%, transparent 50%),linear-gradient(-45deg, $bg 50%, transparent 50%);
    background-position:bottom left, bottom left;
    background-size: $width $width;
    background-repeat: repeat-x;
  }

@mixin jagged-bottom($height:24px){
    content:"";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height:24px;
    background: linear-gradient(-45deg,#f8f8f8 12px,transparent 0%),
                linear-gradient(45deg,#f8f8f8 12px,transparent 0%);
    background-size: 24px;
}

@mixin default-scrollbar{
   
    &::-webkit-scrollbar {
        width: 7px;
        height: 250px;
        // visibility: hidden;
        // padding-left: 28px;
    }
    
    &::-webkit-scrollbar-track {
        border-radius: 4px;
        background: #EBEBEB;
    }
    
    &::-webkit-scrollbar-thumb {
        width: 7px;
        height: 79px;
        border-radius: 4px;
        background: #D0D0D0;
        // box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    }
}

@mixin date-label-green($height:12.5px,$width:13.2px, $fontSize:16px,$fontWeight:normal){
    color: $myColor7;
    font-size: $fontSize;
    font-weight: $fontWeight;
    @include flexStart;
    display: inline-flex;
    width: fit-content;
    gap:9px;
    &::before{
        @include generatePseudoElement("../../images/global-icon/calendar-green.svg", $height, $width);
    }
}

@mixin input-default($width:auto,$height:32px,$pleft:0px,$bg-color:#fff,$bg-img:none){
    width:$width;
    height: $height;
    border-radius: 32px;
    border: 0.5px solid ;
    outline: none;
    border: none;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    padding-left: $pleft;
    background: $bg-color $bg-img no-repeat;
    background-position: 20px center;
}


@mixin title-before-line($txtColor:#0F7173,$txtSize:16px,$txtWeight:16px,$lineColor:#d9d9d9,$borderWidth:3px){
    color: $txtColor;
    font-size: $txtSize;
    font-weight: $txtWeight;
    line-height: #{$txtSize + 2};
    position: relative;
    border-left:$borderWidth solid $lineColor;
    padding-left: 5px;
}


@mixin text-before-icon($txtColor,$txtSize,$txtWeight,$iconPath,$iconHeight,$iconWidth){
    color: $txtColor;
    font-size: $txtSize;
    font-weight: $txtWeight;
    line-height: #{$txtSize + 2};
    position: relative;
    padding-left: 25px;
    &::before{
        @include generatePseudoElement($iconPath,$iconHeight,$iconWidth,0 center);
        position: absolute;
        left: 0;
    }
}


@mixin default-text1{
    color:$grey-calendar-color;
    font-size: $paraFontSize2;
    font-weight: $paraFontWeight;
    //margin:unset;
}


@mixin cancellationItemStyles {
    max-height: calc(66.142vh - 104px);
    overflow-y: auto;
    @include flexStart;
    flex-direction: column;
    gap: 20px;
    padding-right: 29.5px;
    padding-left: 15px;
  
    &::-webkit-scrollbar {
      width: 7px;
      height: 250px;
    }
    
    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background: #EBEBEB;
    }
  
    &::-webkit-scrollbar-thumb {
      width: 7px;
      height: 79px;
      border-radius: 4px;
      background: #D0D0D0;
    }
    .cancellation-item-container{
        margin-top: 20px;
    }
  }

 