.transfer_box{
    width: 100%;
    border-radius: 31px;
    background: #FFF;
    box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.26);
    // -webkit-backdrop-filter: blur(4.5px); commented for transfer screen one having same class name pls chk and change the name
    // backdrop-filter: blur(4.5px);
    margin-top: 25px;
    padding: 32px 31px 46px 54px;
    float: left;
    position: relative;

    .inputBlocks .listsParent .rmdp-container {
        .Calendar_comp__parent{
            max-width: 247px;
        }
        .rmdp-week .rmdp-day.rmdp-selected span:not(.highlight){
            background-color: #ffa75d;
            color: #fff;
            box-shadow: none;
        }
    }
    .inputBlocks {
        .transfer-new-design{
            min-height: 144px;
            padding: 19px 27px;
            margin-bottom: 15px;
            border: 1px solid transparent;

            .transfer-new-design-box{
                display: flex;
                justify-content: space-between;

                .tranfser-icon{
                    background: url('../../images/global-icon/transfer-icon-grey.svg') no-repeat 0 0;
                    background-size: 19px;
                    width: fit-content;
                    margin-left: 7px;

                    &.delhover{
                        color: #C16161;
                        background: url('../../images/global-icon/transfer-icon-red.svg') no-repeat 0 0;
                    }
                }
                .tranfser-ask-delete{
                    background: #F67D7D;
                    color: #fff;
                    border-radius: 16px;
                }
            }
            &.delhover{
                border-radius: 40px;
                background: #EDEAE026;
                padding: 19px 27px;
                border: 1px solid #A755554D;
                &:hover{
                    background: #F67D7D26;
                    .tranfser-ask-delete.delhover{
                        background: #C16161;
            
                    }
                }

            }
            &:hover{
                border: 1px solid #D9D9D9;
                border-radius: 40px;
                background: #EDEAE026;
                // padding: 18px 26px;
            }
        }
        .transfer-new-design:last-of-type{
            // min-height: 105px;
            margin-bottom: 0px;
        }
        .also-block{
            width: 96%;
            background-image: url("../../images/accomodation/icons/dash.svg");
            background-repeat: no-repeat;
            height: 5px;
            margin: 20px 19px;
        }
    }

    .inputBlocks {
        &.scrollable{
            max-height: 522px;
            overflow-y: auto;
            @include default-scrollbar;

            .selectbox-dropdown{
                width:339px;
            }

            .transfer-new-design{
                margin-right: 10px;
                padding: 18px 22px;
            }
        }
    }

    &.danger{
        background: #FFF5F5;
    }

    .buttonBlock{
        @include flexEnd;
    }
}
.pointerunset{
    pointer-events: unset;
}

//mainTransfer-parent
.mainTransfer-parent{
    height: 100%; 
  width: 100%;
    position: relative;
    //height: calc(100vh - 79px);
    background-image: url("../../images/background/coconut.svg"), url("../../images/background/cocktail.svg");
    background-size: 20.5vmax, 11vmax;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: calc(0% - 4.3vmax) calc(100vh - 18.4vmax), calc(100% + 0.4vmax) calc(0% + 4.5vmax);
    float: left;
    //margin-top: 50px;
   
    .transferLeft{
        padding-left: 120px;
        display: grid;
        grid-template-columns: 1275px 384px;
        margin-top: 78px;
        float: left;
        margin-bottom: 141px;
    }
}

.transfer-display{
    display: flex;
    align-items: center;
    padding: 9px 19px 9px 18px;
    gap: 17px;
    color: #797979;
    font-size: 20px;
    font-weight: 400;
    margin-right: auto;
    cursor: pointer;
    height: 48.5px;
    border-radius: 18px;
    border: 1px solid transparent;
    transition: color 0.2s ease;
    transition: background-color 0.2s ease;

    &:hover {
        border: 1px solid #D9D9D9;
        background: #EDEAE0;
        color: #0D6365;
    }

    .display-icon-container{
        border-radius: 6px;
        border: 1px solid #D9D9D9;
        width:30.5px;
        height:30.5px;
        border-radius: 6px;
        background: #FFF;
        box-shadow: 0px 2px 3.4px rgba(0, 0, 0, 0.25);
        // padding: 7px;
        @include flexCenter;
        // background: var(--button-hovers-Button_default, #EDEAE0);
        
        .display-icon{
            &::after{
                transition: background-image 0.2s ease;
            }
        }
    }

    
    &.less{
        border: 1px solid #D9D9D9;

        .display-icon{
            @include generateIconClass("../../images/global-icon/display-less.svg", 16.5px, 16.5px);
        }
        
        &:hover{
            .display-icon{
                @include generateIconClass("../../images/global-icon/display-less-green.svg", 16.5px, 16.5px)
            }
        }
    }

    &.more{
        .display-icon{
            @include generateIconClass("../../images/global-icon/display-more.svg", 16.5px, 16.5px);
        }
        
        &:hover{
            .display-icon{
                @include generateIconClass("../../images/global-icon/display-more-green.svg", 16.5px, 16.5px)
            }
        }
    }
}

.booking-cancellation-success{
    // height: 70px;
    padding: 27px 51px;
    width: auto;
    background-color: #D9D9D9;
    box-shadow: 0px 1px 6.7px 0px #00000030;
    position: fixed;
    font-weight: 900;
    font-size: 22px;
    line-height: 29.7px;
    color: #434343;
    border-radius: 17px;
    top: 118px;
    left: 50%;
    transform: translateX(-50%);
    // @include remove-white-space;
}