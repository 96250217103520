.rmdp-wrapper{
    height:100%!important;
    padding: 34px 34px 32px 34px!important;
    margin-top: -1px!important;
    margin-left: -1px!important;
}

.rmdp-header {
    width:575px;
    padding-bottom: 14px!important;
    .rmdp-arrow{
        display: none;
        transform: rotate(0deg);
    }

    .rmdp-arrow-container{
        border-radius: 0;
    }

    .rmdp-right{
        @include arrow-calendar;
        padding:9px 0 7px 7px ;
        transform: rotate(-90deg);
        &:hover{
            background-image: url("../../images/calendar/arrowGreen.png")!important;
        }
    }

    .rmdp-left{
        @include arrow-calendar;
        padding:9px 7px 7px 0 ;
        transform: rotate(90deg);
        &:hover{
            background-image: url("../../images/calendar/arrowGreen.png")!important;
        }
    }
    
    div {
        width: 100%;

        .disabled{
            opacity:0.38;
            pointer-events: none;
        }

        .rmdp-arrow:not(:disabled) {
            border: solid #434343;
            border-width: 0 2px 2px 0;
            height: 11px;
            width: 11px;
        }

        .rmdp-arrow-container{
            &:not(.disabled) {
                &:hover {
                    background-color: initial;
                    border-radius: initial;
                    box-shadow: initial;
        
                    .rmdp-arrow {
                        border: solid $myColor7;
                        border-width: 0 2px 2px 0;
                    }
                }
            }

        }

        .rmdp-left{
            // border: solid #00AA6C;
            &:hover {
                &+ .rmdp-header-values{
                    color:$myColor7!important;
                }
                
                &+ .rmdp-header-values.disabled{
                    color:$grey-calendar-color!important;
                    cursor: default!important;
                }

            }

            &:has(+ div:is(:hover,:focus-visible)){
                &{
                    background-image: url("../../images/calendar/arrowGreen.png")!important;
                }
            }

            &:has(+ div.disabled:is(:hover,:focus-visible)){
                &{
                    background-image: url("../../images/calendar/arrowGrey.png")!important;
                }
            }

            &.disabled:hover{
                background-image: url("../../images/calendar/arrowGrey.png")!important;
                cursor: default;
            }
        }

        .rmdp-header-values {
            user-select: none;
            &:nth-child(2) {
                @include no-margin-x;
                text-align: left;

                span:nth-child(1) {
                    padding-left: 0px;
                }
            }

            &:nth-child(3) {
                @include no-margin-x;
                text-align: right;

                span:nth-child(2) {
                    padding-right: 0px;
                }
                

                &:hover ~ .rmdp-arrow-container {
                    background-color: initial;
                    border-radius: initial;
                    box-shadow: initial;
                }

                &:hover ~ .rmdp-right{
                    background-image: url("../../images/calendar/arrowGreen.png")!important;
                }
            }
            
            &:has(+ button:is(:hover,:focus-visible)){
                color:$myColor7!important;
            }

            &.disabled:hover{
                color: $grey-calendar-color!important;
                cursor: default!important;
            }
        }
    }
}

.rmdp-week{
    .rmdp-week-day:nth-child(6){
        color: $orange-color!important;
    }
    .rmdp-week-day:nth-child(7){
        color: $orange-color!important;
    }

    .rmdp-range.end:first-child{
        // border-radius: 50% 0 0 50%;
        background-image: none!important;
    }
    
    .rmdp-range.start:last-child{
        background-image: none!important;
        // border-radius: 0 50% 50% 0;
    }
    
    .rmdp-range.start.end{
        background-image: none!important;
        // border-radius: 0 50% 50% 0;
    }

    .rmdp-range.start:has(.range-day-sunday){
        background-image: none!important;
    }

    .rmdp-range.end:has(.range-day-monday){
        background-image: none!important;
    }
}

.rmdp-day.rmdp-disabled{
    span{
        color: #6a5f5f42!important;
    }
}


.rmdp-deactive{
    .sd{
        color: #A59E9E!important;
    }
}

.rmdp-shadow{
    box-shadow: 0px 8px 13px 4px rgba(0, 0, 0, 0.26);
}

.rmdp-range{
    // transition: none !important;
    .sd {
        transition: none !important;
    }
    &.start{   
        background-image: linear-gradient(#EFD5BE,#EFD5BE)!important;
        background-position:10px 5px!important; // Sets the Y-position of the background color to 5px
        background-size:auto calc(100% - 12px);
        position:relative;
        border-radius: 0;
        background-repeat: no-repeat;

        .sd {
            transition: none !important;
    
            &.in-range-day {
                background-color: #ffa75d !important;
                background-image: unset !important;
                background-repeat: unset !important;
                color: #FFF !important;
                // left: $shiftSd !important;
                border-radius: 50% !important;
                width: $dimensionDay !important;
                height: unset !important;
                margin-top: unset !important;
                transform: translateX(1px);
            }
        }
    }
    &.end{   
        background-image: linear-gradient(#EFD5BE,#EFD5BE)!important;
        background-position:0 5px!important; // Sets the Y-position of the background color to 5px
        background-size:50% calc(100% - 12px);
        position:relative;
        border-radius: 0;
        background-repeat: no-repeat;
        .sd{
            color: unset!important;
            transition: none!important;
        }
    }

    .sd{
        transition: none!important;
    }
}
.sd{
    transition: background-color 0.3s linear;
    width:$dimensionDay;
    height: $dimensionDay;
    &:hover{
        transition: none;
        color: inherit!important;
    }
}

.in-range-day{
    background-color: transparent!important;
    background-image: linear-gradient(#EFD5BE,#EFD5BE)!important;
    background-repeat: no-repeat;
    color:initial!important;
    left:0!important;
    border-radius:0!important;
    width:100%;
    height: calc(100% - 12px);
    margin-top: calc(5px - $shiftSd);
    // margin-left: -1px;
    // text-align: calc(center - 1px);
}

.range-day-sunday{
    border-top-left-radius: 0;
    border-top-right-radius: $borderRadiusCalendar!important;
    border-bottom-right-radius: $borderRadiusCalendar!important;
    border-bottom-left-radius: 0;
}

.range-day-monday{
    border-top-left-radius: $borderRadiusCalendar!important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: $borderRadiusCalendar!important;
}

.rmdp-day span{
    top:$shiftSd;
    left:1.5px;
    right:1.5px;
}