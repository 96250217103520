.booking-recap-container{
    padding: 6.25vw 6.25vw 05.625vw ;
}

.title-recap-booking{
    color: $color-blue;
    font-size: 18px;
    font-weight: 700;
    margin-bottom:45px;
}

.clients-information{
    margin-bottom: 161px;
    &_headtxt0{
        font-size: 16px;
        color:#585858;
        font-weight: 400;
        text-decoration: underline;
        text-underline-offset: 4px;
        margin-bottom:11px;
        
    }
    &_headtxt1{
        color: #FFA75D;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 8px;
    }
    &_headtxt2{
        color: #0F7173;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 57px;
        
    }
}

.clients-info-container{
    width: 1262px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 58px 6.656%;
    position: relative;
    flex-wrap: wrap;
}
.absolute-added{
    width: 1292px;
    position: relative;
    // position: absolute;
    // top:121px
}

.save_quotaion_popup{
    background-image: url("../../images/background/coconut-full-opaque.svg"),url("../../images/background/cocktail-booking.svg"); 
    background-size: 32.5vmax,35.88541667vmax;
    background-repeat: no-repeat;
    background-position: -16.66666667vmax 559px,119% 131vh;
}

.absolute-added-booking{
    width: 1292px;
    position: relative;
    // margin-top: 144px;
    margin-bottom: 108px;

    &-client-info{
        display: inline-flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 58px 6.656%;
        position: relative;
        flex-wrap: wrap; 
        width: 100%;

        &-container{
            width: 46.671%;
           .pax-recap-details-container .pax-recap-row{
                flex-wrap: nowrap;
            }
        }
    }
}

.pax-recap-details-container{
    .pax-recap-label{
        // color: #434343;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 12px;

        .pax-recap-age{
            color: #0F7173;
        }
    }
    .pax-recap-row {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        .input-name {
            padding:0 0 0 22px;
            height: 42px;
            border-radius: 33px;
            border: 1px solid #DDD;
            background: #F5F5F5;
            flex: 1;
            font-weight: 700;
            color: #666666;
            &:focus {
                outline: none;
            }
        }

        .half{
            flex: 0 0 calc(50% - 6px);
        }
    }
}

.stepper-container{
    position: fixed;
    min-width: 723px;
    height:132px;
    right: 4.270833333vw;
    top: 82.2386vh;
    @include flexCenter;
    gap:15px;

    .next-step-button{
        display: flex;
        padding: 24px 51px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        height: 64px;
        border-radius: 18px;
        border: 1px solid #16A391;
        background: $myColor7;
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
        color: #FFF;
        font-size: 22px;
        font-weight: 700;
        &::after{
            position: relative;
            top:1px;
            transition: transform 300ms ease-in-out;
            @include generatePseudoElement("../../images/global-icon/next-arrow-white-bold.svg",13px,13px);
            
        }
        &:hover{
            background-color: #0C5657;
            transition: 300ms;
            &::after {
                    transform: translateX(10px);
                }
        }

        &.loading{
            .MuiCircularProgress-root{
                position: relative;
                left: 6px;
            }
            &::after{
                background: none;
            }
        }
    }

    &.blur{
        border-radius: 17px;
            border: 1px solid #0F7173;
            background: rgba(255, 255, 255, 0.57);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
            backdrop-filter: blur(5.200001px);
            /* Correction ici */
    }

    .label-step{
        font-size: 16px;
        font-weight: 700;
        &.current{
            color: $grey-calendar-color;
            text-decoration: underline;
            text-decoration-color:$orange-color;
            text-decoration-thickness:2px;
            text-underline-offset:8px;
        }
        &.done{
            color: $myColor7;
        }
        &.disable{
            color: #DDD;
        }
    }
}

.table-booking-parent{
    $gridRowSpacing:12px;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 $gridRowSpacing;
    margin-top:-$gridRowSpacing;
    font-size: 14px;
    .date-cell{
        width:110px;
        &::before{
            margin-right: 9px;
            @include generatePseudoElement("../../images/global-icon/calendar-grey.svg",11px,12px);
        }
    }
    .from-date{
        text-align: right;
    }
    .table-booking-header{
        width: 100%;
        height: 52px;
        border-radius: 9px;
        border: 1px solid #A4ACD7;
        background: #E6EAFC;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        // margin: -80px;
        border-spacing: 0;
        th:first-child{
            border-radius: 9px 0 0 9px;
            padding-left: 29px;
        }

        th:last-child{
            border-radius: 0 9px 9px 0;
        }
        .table-booking-row{
            th:nth-child(1){
                padding-left: 31px;
            }
            th:nth-child(2){
                padding-left: 26px;
            }
            th:nth-child(3){
                padding-left: 19px;
            }
            th:nth-child(4){
                padding-left: 8px;
            }
            th:nth-child(5){
                padding-left: 11px;
                width: 50px;
            }
            // th:nth-child(8){
            //     width: 448px;
            //     padding-left: 26px;

            // }
        }
        
    }

    .table-booking-body{
        transform:  translateY(40px);
        hr {
            border: none;
            border-top: 1px solid black;
            margin: 0;
        }
        .row-body{
            // border-bottom: 1px solid #E1DFDF;
        }
        .ref-cell{
            width: auto;
            padding-right: 63px;
        }
        .arrow-cell{
            *{
                transform: translateY(-2px);
            }
        }
        .table-booking-row{
            cursor: pointer;             
            height: 73px;
            &.yellow{
                td:first-child{
                    position: relative;
                    &::before{
                        content: "";
                        width: calc(100vw - 291px);
                        background-color: #FFFAE8;
                        border:#DBAB00 1px dashed;
                        border-radius: 15px;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        position: absolute;
                        z-index: -1;
                    }
                }
            }
            td{
                // transition: border 0.3s ease,background-image 0.3s ease;   
                &:first-child{
                    padding-left: 29px;
                    @include id-link;
                }

                &.booking-status{
                    height: 100%;                  
                    display: table-cell;  
                    // height: 200px; /* Set a fixed height for the parent if needed */  
                    vertical-align: middle;  
                    text-align: center; /* Center horizontally */
                     .quote-btn-container{
                        display: flex;
                        justify-content: flex-end;
                        &>div:not(:last-of-type){
                            width: 115px;
                            margin:0 10px;
                        }
                     }
                     .booking-new-indicator{
                        width: 28px;
                        height: 28px;
                        position: absolute;
                        background: url('../../images/new-booking.png') center no-repeat;
                        left: 101%;
                        transform: translateY(-97%);
                     } 
                }
            } 

           &:not(:has(hr)):hover{
               td{
                // box-shadow: inset 0 2px 0 0 #000, inset 0 -2px 0 0 #000;
                background: #E7E5E5;
                &:first-child{
                    border-radius: 15px 0 0 15px;
                }
                &:last-child{
                    border-radius: 0 15px 15px 0;
                }
            }
           }
           td:nth-child(2){
            padding-left: 27px;
            }
            td:nth-child(3){
                padding-left: 20px;
            }
            td:nth-child(4){
                padding-left: 8px;
            }
            td:nth-child(5){
                padding-left: 11px;
            }
            .statuscell{
                cursor: pointer;
                width: 99px;
                height: 24px;
                @include flexCenter;
                border-radius: 8px;
                text-align: center;
                box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
                color:#fff;
            }
            .status_booked{background: #3F7B9C;}
            .status_pending{background: #FFD84C;color: #624E06;}

            &:hover{
                td{
                    transition: background-color 0.2s ease-in-out;
                    background: #E7E5E5;
                }
            }
        }
        .table-booking-row.last{
            background-color: #FFEEEE;
            border-radius: 8px;
            &:not(:has(hr)){
                td{
                 // box-shadow: inset 0 2px 0 0 #000, inset 0 -2px 0 0 #000;
                 background-color: #FFEEEE;
                 &:first-child{
                     border-radius: 15px 0 0 15px;
                 }
                 &:last-child{
                     border-radius: 0 15px 15px 0;
                 }
             }
            }
        }
        .status_req1{background: #379193;color:#624E06; opacity: 0.5;
        
            &:hover {
                background: #28686A;
                transition: 300ms;
                }
        }
        .status_req2{background: #3F7B9C;color:#624E06; }
        .status_req3{background: #345E74;color:#624E06; }
        .quote-btn{
            width: 159px;
            height: 37px;
            @include flexCenter;
            border-radius: 24px;
           color:#fff;
           //cursor: pointer;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        }
        .pax-details{
            
            &>div{
                display: inline-block;
                margin: 12px 10px; /* Gap between elements */
                width: fit-content;
                padding: 1px 11px;
                border-radius: 13px;
            }
            .paxselected{
                border: 1px solid #0F7173;
                background: #0F7173;
                color: #fff;
            }
            .paxunselected{
                border: 1px solid #0F7173;
                background: #FFF;
                color: #0F7173;
            }
        }
    }
}