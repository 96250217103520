.AccomIndex{
    width: 100%;
    display: grid;
    grid-template-columns: 70% 30%;

// / compariosn csss starts
.comparision_parent{
    width: 100%;
    display: flex;
    justify-content: center;
    color:#000;
    .comparision_body{
        width: 265px;
        height: 145px;
        border-radius: 8px;
        background: var(--efefef, #EFEFEF);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        flex-direction: column;
        @include flexCenter;
        .compareNot{
            border-radius: 8px;
             background: #77B0E3;
        }
        .compareYes{
            border-radius: 6px;
background: #97EB89;
        }
    }
    
}
// / compariosn csss ends



//Filter Rating css started
.filterRating_parent{
    margin-top: 50px;
}

.filter_head{
    margin-top: 50px;
}


.rating_head{
    display: grid;
    grid-template-columns: 47% 48% 5%;
    border-radius: 2px;
    background: #EAEAEA;
    margin-top: 15px;
    padding: 5px 10px;
    align-items: center;
}    

.rating-star{
    display: flex;
}


.Accom_middle{
    width: 100%;
    padding: 20px;
    background-color: #fff;
    box-sizing: border-box;

// show hotels csss started




// show hotels csss Ended





}

.accom_filter_parent{
    background-color: #FFF;
    padding: 10px 37px;
}





}


//location_points_parent

.location_points_parent{
    float: left;
    font-family: Satoshi;
    color: #4A4A4A;
    font-size: 14px;
    font-weight: 700;
    
    .location_inputs{
        outline: none;
    }
    .input_selected{
        background-color: #EDEAE0 !important;
        outline: none;
        font-size: 14px;
        font-weight: 700;
    }
    .location_div{
        flex-direction: column;
        cursor: pointer;
        &:hover{
            background-image: url("../../images/location-pin.png"),url("../../images/paxuparr.png") !important;
            border: 1px solid #EAEAEA !important;
            background-color: #EDEAE0 !important;
        }
        .location_div_txt{
            width: 100%;
            text-align: left;
        }
    }
}

//////////////////////////////////////new accomodation///////////////
/// 
.accomodation_box{
    @include inputbox_background;
    width: 1398px;
    padding: 45px 39px 33px 39px;  
}
.accomInput_parent{
    //width: calc(1300px - 67px); line no 128 width was taken 
    display: flex;
    position: relative;
    //grid-gap:10px 0;
    
    
    // .excursion_inputs_line{
    //     @include inputs_midline;
    //     background-size: 7px;
    //     height: 55px;
    // }
    .selectbox1{
        height: 61px;
    }
    .numOfPax{
        height: 61px;
    }
}
.remove-flex{
    display: grid;
    grid-gap:22px;
}

  

.roomBox{
    width:fit-content;
    height: 25px;
    padding: 8px 20px 8px 25px;
   @include flexCenter;
    font-size: 12px;
    font-weight: 500;
    border-radius: 15px;
    color: #758D74;
    border: 0.5px solid #486A47;
    background: #F4FEF4 url("../../images/roomAvailable.png") no-repeat 10px center;
}

.roomRequest{
    border: 0.5px solid #B07A4B;
    background: #FFF3E9 url("../../images/roomRequest.png") no-repeat 10px center;
    color: #B07A4B;
}

//selected accom
.selectAccom_parent{
   @include float_width;
    font-family: Satoshi;
    padding: 20px 0 0 123px;
    background-image: url("../../images/background/cocktail.svg"),url("../../images/background/coconut-opaque.svg"); 
    background-repeat: no-repeat;
    background-position: right calc(0% + 1vmax), left calc(100% + 4.5vmax);
    background-size: 11vmax, 20vmax;
    background-attachment:fixed;
    .SelectedAccom_left{
        margin-top: 84px;
        width: 65vw;
        float: left;
     

        .mainHotel_block{
           @include float_width;
           margin-top: 94px; 
           .hotel_nameblock{
            margin-bottom: 15px;
            display: flex;
            gap: 15px;
            align-items: flex-start;
            flex-direction: column;
                .hotel_name{
                    flex: 0 0 auto;
                    @include line-name;
                    padding-left: 16px;
                }
                .hotel_types{
                    display: flex;
                    gap:14px;
                    font-size: 18px;
                    font-weight: 700;
                    align-self: flex-start;

                    .hotel_category{
                        display: flex;
                        justify-content: flex-start;
                        gap:14px;
                        flex:0 0 auto;

                        .separator_bar{
                            font-weight: 300;
                            font-size: 16px;
                            color:#4A4A4A;
                            align-self: center;
                        }
                    }
                }
           }

           
           .rating_location{
            display: flex;
            align-items: center;
            margin-top: 20px;
            color:$paraColor;
            // background: url("../../images/accomodation/location-pin.svg") no-repeat 0 center;
            background-size: 12px;
            // padding-left: 19px;
            gap: 6px;
            font-size: 20px;
            color: #878080;
            font-weight: 500;


            &::before{
                @include generatePseudoElement("../../images/accomodation/location-pin.svg",18px,16px);
            }
            .separator_bar {
                    margin-left: 4px;
                    margin-right: 4px;
                }
            }

            .hotel_image{
                cursor: pointer;
                // transition: filter 0.05s ease;
                &.disabled{
                    cursor: default;
                    &:hover{
                        filter: none;
                    }
                }
                &.main{
                    @at-root div.main{
                        width:100%;
                    }
                    @at-root img.main{
                        width:120%;
                    }
                    height: 100%;
                }

                &.secondary{
                    height: calc(50% - $gapSecondaryImages / 2);
                    max-width:$secondaryImageMaxWidth;
                }

                &.tiers{
                    height:100%;
                    width:100%;
                }
                &:hover{
                    filter:brightness(0.7);
                }
            }
            
            .title_txt{
                @include float_width;
                color: #0F7173;
                font-size: 22px;
                font-weight: 700;
                margin: 54px 0 30px;
                background: linear-gradient(#FFCEA4,#FFCEA4) no-repeat;
                background-size: 100% 5px;
                background-position: center 87%;
                width: auto;
            }
            .description-container{
                display: inline-flex;
                flex-direction: column;
                gap: 15px;
                color: #161616;
                width: 100%;
            }
            .splitBox1{
                display: flex;
                // grid-template-columns: repeat(auto-fit,minmax(200px, 1fr));
                justify-content: flex-start;
                align-items: stretch;
                gap:74px;
                margin-top: 5.5rem;
                flex-basis: auto;
                height: 100%;
                .amenities_block{
                    width: 826px;
                    border-radius:16px;
                    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
                    p{
                        color: $paraHeadColor;
                        font-size: 18px;
                        margin-bottom: 38px;
                        @include remove-white-space;
                        @include flexStart;
                    }
                    padding: 36px 29px 36px 38px;
                    background-color: #FCFCFC;
                    position: relative;
                    overflow: hidden;
                    height: 260px;
                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 9px;
                        height: 100%;
                        background-color: rgb(255, 165, 92);
                        // border: 2px solid rgb(255, 165, 92); /* Adjust border width and color as needed */
                        border-radius: 0; /* Ensures no border radius on pseudo-element */
                        box-sizing: border-box; /* Ensures border width is included in width and height */
                      }
                    .amenities_list{
                        gap: 15px;
                        overflow-y: hidden;
                        justify-content: flex-start;
                        align-items: flex-start;
                        align-content: flex-start;
                        height: 137px;
                        &.resume{
                            height:99px;
                        }

                    }
                    &:has(.amenities_list.resume){
                        padding-bottom: 24px;
                        &>p{
                            margin-bottom: 29px;
                        }
                        &>div:last-child{
                            @include flexSpace;
                            flex-direction: column;
                            gap:24px;
                        }
                    }
                }
                    
                .container-map-img-filter{
                    // margin: auto;
                    // height:100%;
                }
                .map-img-filter{
                    // margin-top: 88px;
                    background:url("../../images/mapFilter.png") left center no-repeat;
                    background-size: 309px;
                    min-height: 100%;
                    // display: grid;
                    place-items: center;
                    width: 309px;
                    @include flexCenter;

                    &.hotel-details{
                        width:355px;
                        background-size: cover;
                        border-radius: 16px;

                    }
                
                    span{
                        width: 178px;
                        float: left;
                        background-color: #fff;
                        box-shadow: 0px 5px 5.8px 0px rgba(0, 0, 0, 0.15);
                        color: #0F7173;
                        text-align: center;
                        border-radius: 17px;
                        font-size: 16px;
                        font-weight: 700;
                        padding: 5px;
                        cursor: pointer;
                        transition:background-color 0.2s ease;
                    
                        &:hover{
                            background-color: #D6D6D6;   
                            cursor: pointer;
                        }
                    }
                }
            }
            .splitBox2{
                @extend .splitBox1;
                gap:54px;
                // height: 294px;
                // grid-template-columns: 477px 492px;
               
            .review_block{
                width: auto!important;
            }
            }
        }

        .also-block{
            float: left;
            // border-top: 2px dashed #000;
            margin-top: 173px;
            width: 100%;
            background-image: url("../../images/accomodation/icons/dash.svg");
            background-repeat: no-repeat;
            width: $containerHotelAmenitiesWidth;
            .suggestions-title{
                color: $paraHeadColor;
                font-size: 28px;
                font-weight: 700;
                margin-bottom: 63px;
                margin-top: 64px;
            }
            
            .after-also-block {
                padding-left: 26px;
                width: 100%;

                .hotels_parent {
                    max-width: 100%;
                    display: flex;
                    justify-content: space-between;

                    .hotel_img {
                        // $hotelImgWidth:100% * $ratioImgWidth;
                        width: 424px;
                        flex: 0 0 auto;
                        border-radius: 25px;
                        box-shadow: 0px 2px 5.5px 0px rgba(0, 0, 0, 0.18);

                        .img-hotel-card {
                            object-fit: cover;
                            // width: $imgImgAlsoLikeWidth;
                            // height: calc($imgImgAlsoLikeWidth * $ratioImgAlsoLike);
                            width: 424px;
                            height:397px;
                        }
                    }

                    .hotel_details {
                        width: 100%;
                        .rating_location {
                            margin-top: 7px;
                            .rating_loc_txt{
                                margin-right:4px;
                            }
                        }

                        &>div:not([class]){
                            margin-bottom:27px!important;
                        }

                        .hotel_details_body {
                            .chk_avaliability {
                                &>div {
                                    padding-left: 5px;
                                }

                                .limited-nbr-lines {
                                    line-clamp: 4;
                                    -webkit-line-clamp: 4;
                                    width:29.8vw;

                                    div {
                                        margin-bottom: 10px;

                                        &:last-child {
                                            margin-bottom: 0;
                                        }
                                    }
                                }
                            }
                        }
                        .hotel_btnblk {
                            margin-bottom: unset;
                            bottom: 0;
                            right: 0;
    
                            &>div {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-end;
                                .exprice {
                                    position: relative;
                                    bottom: .5px;
                                    color: #434343;
                                    font-size: 18px;
                                    font-weight: 900;
                                }
                                .paxNumber{
                                    color: #434343;
                                    font-size: 13px;
                                    font-weight: 400;
                                }
                            }
    
                            &>.seemore_btn {
                                font-size: 16px;
                                margin: 3px 0 0 20px;
                                text-align: center;
                                vertical-align: middle;
                            }
                        }
                    }
                }

                .maphotel {
                    display: flex;
                    justify-content: space-between;


                }
            }
        }
    }
}

//hotel rating 
.hotel_rating_parent{
    max-width: 477px;
    padding: 21px 8vw 21px 24px ;
    background-color: #FCFCFC;
    height: auto;
    font-family: Satoshi;
    border-radius: 16px;
    color: $paraHeadColor;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    .circleRating{
        color:#00AA6C!important;
        &>span:not(:last-child){
            margin-right: 7px;
        }
      }
    .rating_header{
        display: flex;
        gap: 26px;
        align-items: center;
        min-width: 300px;
        .overall_value{
            font-size: 48px;
            font-weight: 700;
            color:#505050;
            height: 1em;
            text-align: center;
            @include flexCenter;
        }
        .overall-container{
            display: flex;
            flex-direction: column;
            // gap: 12px;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            // height:48px;
            align-self: stretch;
            
            .overall_name{
                font-size: 16px;
                font-weight: 500;
                height: 1rem;
            }

            .overall_rating{
                display: flex;
                width: 100%;
                justify-content: flex-start;
                gap: 20px;
                align-items: center;
            }
        }
        .overall_review{
            font-size: 12px;
            font-weight: 400;
            position: relative;
            // top: -3px;
            // margin-left: 20px;
        
           
        }
    }
    .overall_details_container{
        margin-top:19px;
        padding-bottom: 51px;
        // height: calc(100% + 51px);
        background: url("./../../images/accomodation/tripadvisor.svg") no-repeat left bottom;
    }
    .overall_title{
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 30px;
        height: 1em;
    }
    .rating_loop_parent{
        margin-left: 14px;
        .rating_display{
            display: flex;
            gap: 88px;
            margin-top: 14px; 
            font-size: 12px;
            height: 1em;
        }
    }

}

.ex_search{
    @include ex_search;
    width: 100%;
    height:100%;
    font-weight: 900;
    span{
        position: absolute;
        /* bottom: 30px; */
        transform: translate(-50%, -56%);
    }
    &:hover{
        background: #FFBA80;
    }    
}

.btnblock{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 60px ; //  as per adreian conformation go to issue 7
    .ex_search{
        @include ex_search;
        transition: #ffa75d 208ms ease-out;
        width: 228px;  
        &:hover{
            background: #FFBA80;
        }    
    }
}


// hotel options
.options_Data{
margin-top: 60px;
 @include float_width;
 @include keyframes(slide-down) {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
    }
  &>div{
      @include animation('slide-down .9s 1');
  }  
 .region-parent{
    //display: flex;
    //flex-direction: row-reverse;
    //gap: 65px; // cpmfirmed by malikah
    margin-top: 25px;
    .description-region-container{
        width:32.1875vw;
        float:left;
        color:#161616;
        .title-description{
            margin-bottom:16px;
            font-size:16px;
            font-weight:700;
            color:#0a0a0a;
        }
        .description{
            font-size:14px;
            font-weight:400;
            line-height:18px;
        }
        .title-activities{
            margin:29px 0 16px 0;
            font-size:16px;
            font-weight:700;
        }
        .description-activities{
            font-size:14px;
            font-weight:400;
            line-height:18px;
        }
    }
    .img-region-container{
        width:544px;
        float:left;
        margin-left:65px;
        img{
            border-radius: 14px;
            border-left: 10px solid #F5A477!important;
            box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
            box-sizing: border-box;
            width: 544px;
            height: 418px;
            object-fit: cover;
        }
    }
 }
 .hotel_name{
    // @include line-name;
    color: #0F7173;
    font-size: 22px;
    font-weight: 700;
    background: url("../../images/accomodation/accomRegion.png") no-repeat 0 center; 
    background-size: 19px;
    padding-left:32px // 14px
 }
}

//HotelAmenities-parent

.HotelAmenities-parent{
    @include float_width;
    width:$containerHotelAmenitiesWidth;
    padding-top: 30px;
    background: #FFFCFC;
    border-radius: 34px;
    box-shadow: 0px 7px 8px 0px rgba(0, 0, 0, 0.12);
   
    .amenities-element{
        border-bottom: 1px solid rgba(116, 116, 116, 0.60);
        @include float_width;
        padding: 34px 0 14px 0;
        position:relative;
        cursor: pointer;
        height: auto;
        &:first-child{
            padding-top: 15px;
        }
        &:last-child{
            border-bottom: none;
            padding-bottom: 29px;

            .accordion-section{
                margin-bottom: 0!important;
            }
            // display: flex;
            .other-element{
                font-size: 18px;
                float: left;
                margin-left: 20px;
                position: relative;
                padding-left: 15px;
                color: #797979;
                margin-bottom: 21px;
                
            }
            .other-element::before{
                content: "";
                width: 5px;
                height: 5px;
                background-color: #797979;
                position: absolute;
                border-radius: 50%;
                top: 8px;
                left: 0px;
            }
        }

        &:has(.pattern-parent:empty){
            display: none;
        }

        .element-head{
            width: 100%;
            display: flex;
            font-size: 22px;
            justify-content: space-between;
            height:1em;
            padding: 0 48px 0 41px;
            .element-head-name{
                @include flexCenter;
                height: 1em;
                font-weight: 700;
                color:#0F7173 ;
                width: fit-content;
                // padding-right: 30px;
                width: auto;
                background-size: 24px;
                &::after{
                    content:"";
                    background: url("../../images/accomodation/down-arrow-amenities.png") no-repeat 100% center;
                    position: relative;
                    width: 24px;
                    height: 24px;
                    transition: transform 0.3s ease;
                    margin-left:14px;
                }
                &.enable{
                    &::after{
                        transition: none;
                        transform: rotate(180deg);
                    }    
                }
                img{
                    width:18px;
                    height:18px;
                    margin-right:12px;
                    object-fit: cover;
                }
                span{
                    @include remove-white-space;
                    @include flexCenter;
                }
                &:hover{
                    color:#8CB5B0;
                    &::after{
                        background: url("../../images/accomodation/down-arr-amenities-hover.png") no-repeat 100% center;
                        transform: rotate(180deg);
                    }
                    img{
                        opacity: 0.5;
                    }
                }
                  
            }
            div:nth-child(2){
                height:1.2em;
                font-weight: 400;
                min-width: 40px;
                color:#666666 ;
                font-style: italic;
                text-align: center;
                background:linear-gradient(#FFDFC3,#FFDFC3) no-repeat;
                background-size: 40px 7px;
                background-position: center 90%;
                &:empty{
                    display: none;
                }
            }
        }
        
        .pattern-parent{
            @include float_width;
            $animationDuration:0.3s;
            height: 0;
            // margin-left: 22px;
            padding-right: 25px;
            position:relative;
            animation:fade-out $animationDuration ease-in;
            overflow: hidden;
            transition: height $animationDuration ease-in;
            -webkit-transition: height $animationDuration ease-in;
            -moz-transition: height $animationDuration ease-in;
            -o-transition: height $animationDuration ease-in;
            -ms-transition: height $animationDuration ease-in;
            // animation:padding-out 0.3s ease-out;
            padding-top:20px;
            
            &.active{
                // height: 6000px;
                transition: height $animationDuration ease-out;
                -webkit-transition: height $animationDuration ease-out;
                -moz-transition: height $animationDuration ease-out;
                -o-transition: height $animationDuration ease-out;
                -ms-transition: height $animationDuration ease-out;
                animation:fade-in $animationDuration ease-out;
            }
            .accordion-section{
                margin: 20px 0px 50px;
                display: flex;
                gap:30px;
                overflow: hidden;
                gap: 52px;
                justify-content: flex-start;
                overflow: visible;
                img{
                    max-width: unset;
                }
                &.l{
                    padding-right: 100px;
                    padding-left: 41px;
                }
                &.r{
                    flex-direction: row-reverse;

                    padding-left: 100px;
                    padding-right: 54px;
                    text-align: right;
                }   
                ul{
                    padding-left: 15px;
                    display: flex;
                    justify-content: flex-start;
                    gap:15px;
                    row-gap: 31px;
                    align-items: center;
                    flex-wrap: wrap;
                    list-style: none;
                    margin-bottom: 0;
                    li{
                        color: $paraHeadColor;
                        font-size: 20px;
                        font-weight: 700;
                        &::before{
                            content:"•";
                            margin-right: 14px;
                        }
                    }
                }

                div:nth-child(2){
                    padding-top: 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    gap:28px;
                    @include remove-white-space;
                    b{
                        font-size: 16px;
                        color:$paraHeadColor;
                    }
                    span{
                        text-wrap: wrap;
                        color: $paraColor;
                    }
                    &:not(b){
                       font-size: 14px; 
                    }
                }
             }

             &:last-of-type{
                height: 245px;
                padding-left: 24px;
                padding-top: 30px;
            }
        }
    }
}
.HotelAmenities-gp{
    margin-top: 35px;
  .element-head-name {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: 700;
    color: #0F7173;
    width: -moz-fit-content;
    width: fit-content;
    padding-right: 30px;
    width: auto;
    
    img{
        margin-right: 14px;
    }
    }  
    .accordion-section{
        display: flex;
        gap: 47px;
        margin: 40px 0 62px 0;
        width: $containerHotelAmenitiesWidth;
        .main-image{
            max-width: 608px;
            height: 405px;
            border-left: 10px solid $orange-color;
            border-radius: 17px;
            -webkit-box-shadow: 2px 3px 15px -9px rgba(0,0,0,1);
            -moz-box-shadow: 2px 3px 15px -9px rgba(0,0,0,1);
            box-shadow: 2px 3px 15px -9px rgba(0,0,0,1);
            &:hover{
                cursor: pointer;
            }
        }
        .accordion-image-container{
            border-radius: 14px;
            box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
            height: 292px;
            .accordion-image {
                border-radius: 14px;
                object-fit: cover;
                border-left: 7px solid $orange-color!important;
                width: 516px;
                height: 292px;
            }
        }
        &-hoteldescription{
            padding: 16px 0 0 0;
            display: flex;
            flex-direction: column;
            gap: 15px;
            
            b{
                color:#0F7173;
                font-size: 16px;
            }
            &:not(b){
                font-size: 14px;
                
            }
            &>div{
                font-weight: 400;
                line-height: 18px;
            }
        }
    }
}

//rooms-main-parent
.rooms-main-parent{
@include float_width;

    .room-btnBlock{
        display: flex;
        margin-bottom: 10px;
        gap:10px;
        flex-wrap: wrap;
        &:empty{
            display: none;
        }
        .room-btn{
            width: 151px;
            height: 33px;
            text-align: center;
            color: #fff;
            border-radius: 10px;
            font-size: 18px;
            font-weight: 700;
            cursor: pointer;
            @include flexCenter;
            gap:9px;

            &::before{
                content:"";
                width:16px;
                height:16px;
                background: url("../../images/Amenities-icon/double-bed.svg");
                background-repeat: no-repeat;
                position: relative;
            }
        }
        .needtosel{
            background-color:#FFAB63;
        }
        .alreadysel{
            border: 1px solid #8DB58C;
            background-color: #FFF;
            color:#8DB58C;
            &::before{
                background: url("../../images/Amenities-icon/double-bed-green.svg");
            }
        }
        .disable3{
            background: #fff ; 
            opacity: 0.31;
            border: 1px solid #DD7C29;
            color: #DD7C29 ;
            pointer-events: none;
            &::before{
                background: url("../../images/Amenities-icon/double-bed-orange.svg");
            }
        }
        .disable4{
            background: #fff ; 
            //opacity: 0.31;
            border: 1px solid #DD7C29;
            color: #DD7C29 ;
            //pointer-events: none;
            &::before{
                background: url("../../images/Amenities-icon/double-bed-orange.svg");
            }
        }
        .selectedOne{
            border: 1px solid #8DB58C;
            background: #8DB58C;
        }
    }

    .hotel-rooms-parent{
        @include float_width;
        display: grid;
        gap: 45px;
        grid-template-columns: repeat(3, 1fr);
        &.unavailable-rooms{
            .room-info-parent{
                background-color: #FFF8F8;
            }
        }

        &:not(:empty) + .hotel-rooms-parent-unavailable{
            margin-top: 53px;
        }
        .room-info-parent{
            // width: 383px;
            background-color: rgba(255, 255, 255, 0.70);
            border: 0.5px solid #FFEAD9;
            border-radius: 12px;
            padding: 14px 14px 24px 14px;
            position: relative;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
            backdrop-filter: blur(2px);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-content: flex-start;
            flex-wrap: wrap;
            .room-name{
                &:hover{
                    color: #228C8E;
                    cursor: pointer;
                }
                @include line-name;
                padding-left: 5px;
                border-left: 3px solid #FFAB63;
                line-height: 16px;
                font-size: 18px;
                margin: 32.5px 0 38.5px;
                height:18px;
                @include flexStart;
                gap:14px;
            }
            b.room-amenities{
                font-weight: bold;
                font-size: 14px;
                color:$paraHeadColor;
            }
            b.room-amenities + div {
                margin-top: 20px;
            }
            .see-more{
                border: 1px solid #4565B7;
                border-radius: 34px;
                background-color: #3252A4;
                // padding: 0px 15px 0px 14px;
                padding-left: 9px;
                height: 23px;
                color: #ffffff;
                font-size: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap:6px;
                width:97px;
                transition: background-color 0.3s ease;

                &::after{
                    content: url("../../images/accomodation/arrow-right-white.svg");
                    margin-top: 0.5px;
                }
                span{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 1em;
                    margin-top: -1.5px;
                }
                &:hover{
                    background-color:#5A75BB;
                }
            }
            .maxroom-avail{
                p{
                    padding: 5px 15px;
                    border-radius: 21px;
                    width: fit-content;
                }
                div{
                    font-size: 14px;
                    font-weight: 500;
                    width: 80%;
                }
            }
            .maxroom-avail.room-enable{
                p{
                    color:#558F50;
                    border:1px solid #558f50;
                    background-color: #E8F1E7;
                }
                div{
                    color:#558F50;
                }
            }
            .maxroom-avail.room-disable{
                p{
                    color:#975454;
                    border:1px solid #975454;
                    background-color: #FFCCCC;
                }
                div{
                    color:#F66666;
                }
            }
           
            .meal-title{
                font-weight: 700;
                font-size: 14px;
                margin-bottom:20px;
                color:$paraHeadColor;
            }

            .room-details-container{
                width: 100%;
                padding: 0 12px 0 11px ;
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
            }
            
            .meal-box{
                height:48px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: #fff;
                border-radius: 11px;
                width: 100%;
                padding: 10px 15px;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
                transition: background-color 0.3s ease;
                border: 1px solid #C3C2C2;
                &.non-available{
                    background: #FDF4F4;
                    pointer-events: none;
                    border: 1px solid #ECB2B2;
                    .tariff-price{
                        color: #C16161!important;
                    }
                }
                &.selected{
                    border: 1px solid #0F7173;
                }
                &:not(:last-child){
                    margin-bottom: 10px;
                }
                &:not(.selected):hover{
                    cursor: pointer;
                    background: #F9FFFE;
                    .checkbox{
                        // border-radius: 17px;
                        background: #B8D0D0;
                    }
                }
                .tariff-container{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap:2px;
                    .tariff-label{
                        font-size: 13px;
                        height:1em;
                        @include justify-content-top;
                        color:$paraHeadColor;
                    }
                    .tariff-price{
                        font-size:11px;
                        height:1em;
                        color: #666;
                        font-weight: 300;
                        @include justify-content-bottom;
                        color:$paraColor;
                    }
                }
                .checkbox{
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    background: #E7E5E5;
                    @include flexCenter;
                    transition: background-color 0.3s ease;
                    
                    span{
                        width: 11px;
                        height: 11px;
                        border-radius: 11px;
                        background: #3F7B9C;
                    }
                }
            }
            .meal-box:last-child {
                margin-bottom: 0;
            }
            .view-block{
                display: flex;
                justify-content: space-between;
                padding: 20px 2px 0;
                width:100%;
                float: left;
                align-items: flex-start;
                // margin-bottom: 124.92px;
                
                .view-details{
                    background: url("../../images/accomodation/view-green.svg") no-repeat 2px 4px;
                    padding-left: 22px;
                    transition: background-image 0.3s ease,color 0.3s ease;
                    color: #0F7173;
                    margin-right: 7px;
                    font-size: 16px;
                    font-weight: 700;
                    text-decoration: underline;
                    text-underline-offset: 3px;
                    cursor: pointer;
                    &:hover{
                        color:#0D3233;
                        background: url("../../images/accomodation/view-green-dark.svg") no-repeat 2px 4px;
                    }
                }
            }
            .view-block.error{
                color:#F66666
            }
            
            .offers-container{
                position:relative;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-end;
                min-height: 83.84px;
                width:100%;
                color:#B23C3C;
                font-size: 13px;
                font-weight: 700;
                .offer-applied{
                    text-decoration: line-through;
                }

                .offer-message{
                    @include flexStart;
                    cursor: pointer;
                    gap:8px;
                    transition: color 0.3s ease;
                    &::before{
                        @include generatePseudoElement("../../images/global-icon/info-not-filled-red.svg",11px,11px);
                    }

                    &:hover{
                        color: #973232;
                    }
                }
            }
            .room-info-imgBlock{
                &:hover{
                        filter: brightness(0.7);
                        cursor: pointer;
                    }
                position: relative;
                width:100%;
                min-height: 256px;
                .room-stock-status{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    right: 14px;
                    bottom: 14.84px;
                    width: 126px;
                    height: 26px;
                    background: green;
                    color: #fff;
                    text-align: center;
                    border-radius: 12px;
                    border: 0.5px solid white;
                    
                    box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.14);
                    backdrop-filter: blur(2px);
                    .status_txt{
                        font-size: 14px;
                    }
                }
                .img-room{
                    width: 100%;
                    height: 256px;
                    border-radius: 12px;
                    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
                }
            }
        }
    }
    .hotel-rooms-parent-unavailable{
        float: left;
        margin: 0 0 4px 0;
        &_text{
            font-size: 18px;
            color: #666666;
            font-weight: 800;
        }

        &:has(+.hotel-rooms-parent:is(:empty)){
            display: none;
        }
    }
    .hotel-rooms-available{
        @extend .hotel-rooms-parent-unavailable;
        margin-top: 0;
    }
}

//starRating-parent
.starRating-parent{
    @include float_width;
    position: relative;
    height:$critereaGlobalSearchHeight;
}

.room-offer-popup{
    padding: 46px 25px 50px 39px;
    border-radius: 26px;
    background: #FFF;
    width: 1127px;
    .offer-heading{
        font-size: 20px;
        font-weight: 700;
        color:#0F7173;
        margin-bottom: 30px;
    }
    .room-offer-popup-block{
        display: flex;
        overflow-y: auto;
        max-height: 658px;
        min-height: auto;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        &::-webkit-scrollbar {
        
            width: 7px;
            height: 250px;
            // visibility: hidden;
             padding-left: 28px;
        }
        
        &::-webkit-scrollbar-track {
            border-radius: 4px;
            background: #EBEBEB;
        }
        
        &::-webkit-scrollbar-thumb {
            width: 7px;
            height: 79px;
            border-radius: 4px;
            background: #D0D0D0;
            // box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
        }
       
        .room-details-container{
            display: flex;
            align-items: flex-start;
            gap: 23px;
            align-self: stretch;
            height: 299px;

            .room-image{
                width: 310px;
                height: inherit;
                border-radius: 25px;
                box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
            }
            .room-info-container {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                height: inherit;

                .room-name{
                    color: #434343;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    // @include remove-white-space;
                    margin-bottom: 20px;
                    height: 14px;
                    position: relative;
                    background-color: transparent;
                    background: linear-gradient(#FFAB63, #FFAB63) no-repeat;
                    background-size: 100% 4px;
                    background-position: center 90%;
                    @include flexStart;
                }

                .clients-info-container{
                    display: flex;
                    align-items: flex-start;
                    gap: 10px;
                    width:auto;
                    margin-bottom: 24px;

                    .clients-info{
                        border-radius: 16px;
                        border: 1px solid #FFAB63;
                        background: #FFF;
                        color: #434343;
                        font-size: 14px;
                        font-weight: 500;
                        display: flex;
                        height: 28px;
                        padding: 7px 18px;
                        justify-content: center;
                        align-items: center;
                        gap: 9px;

                        .light-weight{
                            font-weight: 400;
                        }
                    }

                    .pax-info{
                        gap:7px;
                        padding: 8px 18px 8px 15px;
                        &::before{
                            @include generatePseudoElement("../../images/global-icon/user-grey.svg",12px,12px);
                        }
                    }

                    .mealplan-info{
                        padding: 8px 18px;
                        gap: 7px;
                        &::before{
                            @include generatePseudoElement("../../images/global-icon/cutlery-grey.svg",11px,12px);
                        }
                    }
                }

                .amenities-container{
                    display: flex;
                    padding: 34px 29px;
                    // flex-direction: column;
                    align-items: flex-start;
                    width: 680px;
                    border-radius: 16px;
                    border: 1px solid #C8D4D4;
                    background: #FCFCFC;
                    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
                    align-items: flex-start;
                    align-content: flex-start;
                    gap: 38px;
                    flex-wrap: wrap;
                    height: 215px;
                    overflow-y: hidden;

                    .amenities-section-title{
                        color: #797979;
                        font-size: 14px;
                        font-weight: 700;
                        background: linear-gradient(#FFAB63, #FFAB63) no-repeat;
                        background-size: 100% 4px;
                        background-position: center 90%;
                        margin:unset;
                    }

                    .amenities-items-container{
                        display: flex;
                        width: 589px;
                        align-items: flex-start;
                        align-content: flex-start;
                        gap: 15px;
                        flex-shrink: 0;
                        flex-wrap: wrap;
                        height: 99px;
                        overflow-y: hidden;
                    }
                }
            }
        }

        .offers-container{
            display: flex;
            width: 1009px;
            flex-direction: column;
            align-items: flex-start;
            gap: 21px;

            .offer-details{
                display: flex;
                flex-direction: column;
                gap: 10px;
                position: relative;
                .offer-title{
                    color: #C16161;
                    font-size: 16px;
                    font-weight: 700;
                    margin:unset;
                    &-light{
                        font-weight: 400;
                    }
                }
                
                .offer-description{
                    @include default-text1;
                    transition: all 0.3s ease-in-out;
                    margin: unset;
                }
               

                button{
                    width: 100px;
                    padding: 5px 10px;
                    cursor: pointer;
                    background-color: #FFAB63;
                    color: white;
                    font-size: $paraFontSize;
                    border-radius: 34px;
                }
            }

            .dashed-line{
                @include generatePseudoElement("../../images/global-icon/dashed-line-grey.svg",2px,100%);
                &:last-child{
                    display: none;
                }
            }
            
        }

        .cancellation-container{
            @include cancellationItemStyles;
            overflow: unset;
        }
    }
}



.offers-container{
    display: flex;
    width: 1009px;
    flex-direction: column;
    align-items: flex-start;
    gap: 21px;

    .offer-details{
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: relative;
        .offer-title{
            color: #C16161;
            font-size: 16px;
            font-weight: 700;
            margin:unset;
            &-light{
                font-weight: 400;
            }
        }
        
        .offer-description{
            @include default-text1;
            margin: unset;
            transition: all 0.3s ease-in-out;
        }
       
    }

    .dashed-line{
        @include generatePseudoElement("../../images/global-icon/dashed-line-grey.svg",2px,100%);
        &:last-child{
            display: none;
        }
    }
}