.save_quotaion_popup{
    // position: relative;
    background-color: #fff;
    height: auto;
    width: 100%;
    padding: 80px 122px 87px 120px;
    // float: left;
    margin-top: 10px;
    .hide{
        display: none;
    }
    
    .accom-Heading{
        .labels:nth-child(2){
            width: 225px;
        }
        .labels:nth-child(3){
            width: 214px;
        }
    }

    .save_quotaion_head{
        display: flex;
        justify-content: space-between;
        position: relative;
        margin-top: 50px;
        .save_quotaion_headlft{
        //    p:nth-child(1){
        //     color: #FFA75D;
        //     font-size: 24px;
        //     font-weight: 700;
        //     margin-bottom: 5px;
        //    }
        //    p:nth-child(2){
        //     color: #0F7173;
        //     font-size: 16px;
        //     font-weight: 500;
        //    }

            .save_quotaion_headlft_num{
                display: block;
                font-size: 16px;
                color:#585858;
                font-weight: 400;
                text-decoration: underline;
                text-underline-offset: 4px;
                margin-bottom: 14px;
            }
            .save_quotaion_headlft_orange{
                color: #FFA75D;
                font-size: 24px;
                font-weight: 700;
                margin-bottom: 5px;

                .not-saved{
                    font-size: 18px;
                    color: #979797;
                    
                }
            }
            p.save_quotaion_headlft_name{
                color: #0F7173;
                font-size: 16px;
                font-weight: 500;
            }
            &>div{
                width: 320px;
                display: flex;
                justify-content: space-between;
                color: #4A4A4A;
                font-family: Satoshi;
                font-size: 14px;
                font-weight: 500;
                button{
                    width: 140px;
                    height: 34px;
                    border-radius: 16px; 
                    background: #FFF;
                    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
                    background-repeat: no-repeat;
                }
                button:nth-child(1){
                    border: 0.5px solid #0F7173;
                    background-image: url("../../images/quodown1.png");
                    background-position: 90% center;
                    
                }
                button:nth-child(2){
                    border: 0.5px solid #FFAB63;
                    background-image: url("../../images/edit_quo_hov.png");
                    background-position: 9.5% center;
                }
                button:hover{
                    background-color: #E6E6E6;
                }
                button.show-more{
                    border: 1px solid #FFC38F;
                    border-radius: 34px;
                    background-color: #FFC38F;
                    // padding: 0px 15px 0px 14px;
                    padding-left: 9px;
                    height: 23px;
                    color: #ffffff;
                    font-size: 14px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap:6px;
                    width:105px;
                    transition: background-color 0.3s ease;
                    background-image: none;
        
                    &::after{
                        content: url("../../images/accomodation/arrow-right-white.svg");
                        transform: rotate(90deg);
                        margin-top: 0.5px;
                    }
                    span{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 1em;
                        margin-top: -1.5px;
                    }
                    &:hover{
                        background-color:#FFBA80;
                    }
                }
                button.show-less{
                    border: 1px solid #0F7173;
                    border-radius: 34px;
                    background-color: #0F7173;
                    // padding: 0px 15px 0px 14px;
                    padding-left: 9px;
                    height: 23px;
                    color: #ffffff;
                    font-size: 14px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap:6px;
                    width:105px;
                    transition: background-color 0.3s ease;
                    background-image: none;
        
                    &::after{
                        content: url("../../images/accomodation/arrow-right-white.svg");
                        transform: rotate(270deg);
                        margin-top: 0.5px;
                    }
                    span{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 1em;
                        margin-top: -1.5px;
                    }
                    &:hover{
                        background-color:#0F7173;
                    }
                }
            }
            .timeline-container{
                position: relative; 
                width: 500px; 
                margin-top:63px;

                .show-less-container{
                    position: relative;
                    left: 30px;
                }
            }
            
            .back_items{
                margin-bottom: 22px;
                width: auto;
            }
        }
        .save_quotaion_headryt{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            img{
                width: 162px;
            }
            .cotDocdetails{
                width: 306px;
                height: auto;
                border-radius: 12px;
                border: 0.5px solid #E9E9E9;
                background: #F9F8F8;
                box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
                padding: 15px 1px 30px 24px;
                margin-top: 26px;
                span{
                    color: #434343;
                    font-family: Satoshi;
                    font-style: normal;
                    line-height: normal;
                    font-size: 13px;                   
                    margin-top: 8px;
                    &.label {
                            font-weight: 700;
                    }
                
                    &.value {
                        font-weight: 400;
                    }
                }
                p{
                    color: #0F7173;
                    font-size: 16px;
                    font-weight: 700;
                    margin-bottom: 26px;
                }
                // span,p:first-of-type {
                //     margin-top: 0;
                // }
                div{
                    display: grid;
                    grid-template-columns: 37% 60%;
                    word-wrap: break-word;
                }
            }
        }
        .save_quotaion_headryt_booking{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            position: absolute;
            right: 10px;
            img{
                width: 162px;
            }
            .cotDocdetails{
                width: 306px;
                height: auto;
                border-radius: 12px;
                border: 0.5px solid #E9E9E9;
                background: #F9F8F8;
                box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
                padding: 15px 1px 30px 24px;
                span{
                    color: #434343;
                    font-family: Satoshi;
                    font-style: normal;
                    line-height: normal;
                    font-size: 13px;                   
                    margin-top: 8px;
                    &.label {
                            font-weight: 700;
                    }
                
                    &.value {
                        font-weight: 400;
                    }
                }
                p{
                    color: #0F7173;
                    font-size: 16px;
                    font-weight: 700;
                    margin-bottom: 26px;
                }
                // span,p:first-of-type {
                //     margin-top: 0;
                // }
                div{
                    display: grid;
                    grid-template-columns: 37% 60%;
                    word-wrap: break-word;
                }
            }
        }
    }
    .save_quotaion_branch{
        width: 450px;
        position: relative;
        margin: 50px 0 0 20px;
        float:left;
        
        .save_quotaion-loop-parent{
            width: 100%;
            float:left;
            img{
                margin: 10px 0 8px;
                max-width: unset;
            }
            .css-18y2uza-MuiTypography-root-MuiTimelineContent-root {
                padding: 12px 16px 15px !important;
            }
            .css-1ami7lv-MuiTimelineConnector-root {
                width: 5px !important;
                border-radius: 5px !important;
            }
           
            .cotation-map-child{
                margin-top: 20px;
                float: left;
                p{
                    margin-bottom: 3px;
                }
                p:nth-child(1){
                    background-image: url("../../images/cotationcar.png");
                    background-repeat: no-repeat;
                    background-size: 12px;
                    padding-left: 26px;
                    background-position: 0 center;
                } 
                p:nth-child(2){
                    background-image: url("../../images/cotationprofile.png");
                    background-repeat: no-repeat;
                    background-size: 12px;
                    padding-left: 26px;
                    background-position: 0 center;
                } 
               p:nth-child(3){
                    background-image: url("../../images/cotationcalendar.png");
                    background-repeat: no-repeat;
                    background-size: 12px;
                    padding-left: 26px;
                    background-position: 0 center;
                } 
            }
            
        }
    }

    .booking-resume{
        width: 100%;
        // float: left;
        margin-top: 50px
    }

    .openPopup_save{
        @include popup;
        background: rgba(0, 0, 0, 0.5);
        opacity: 1;
    }

}

.save_quotaion_button{
    width: 100%;
    max-height: 87px;
    float:left;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    border-top: 2px solid #FFAB63;
    padding-top: 23px;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 23px 122px 23px 10px;
    background-color: #fff;
    .finsavecotation{
        min-width: 158px;
        padding: 8px 18px 8px 14px;
        border-radius: 9px;
        border: 1px solid #0F7173;
        background: #FFF;
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
        margin-right: 20px;
        color: #434343;
        font-size: 15px;
        font-weight: 700;
        height: 41px;
        @include flexCenter;
        gap: 11px;
        transition:all 0.3s ease;
        &::before{
            @include generatePseudoElement("../../images/global-icon/save.svg",11px,11px);
            flex: 0 0 auto;
            transition: background-image 0.3s ease;
        }

        &:hover{
            border-radius: 9px;
            background: #CAE6E7;
            color: #0F7173;
            &::before{
                background-image: url('../../images/global-icon//save-green.svg');
            }
        }

    }
    .finbooknow{
        width: 139px;
        padding: 8px 18px 8px 14px;
        border-radius: 9px;
        border: 1px solid #16A391;
        background: #0F7173;
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
        color: #fff;
        font-weight: 700;
        height: 41px;
    }

    .warning{
        color: $warning-red-color;
        font-size: 16px;
        font-weight: 700;
        @include flexCenter;
        gap:8px;
        margin-right:47px;
        &::before{
            @include generatePseudoElement("../../images/global-icon/info-red.svg",12px,12px);
        }
    }
}

.timeline-container div ul {padding: 0 !important;}
.timeline-container div ul li::before{padding: 0 !important;flex:unset !important}


.save_input_popup{
    width: 1071px;
    float: left;
    padding: 42px 26px 26px 52px;
    background-color: #fff;
    border-radius: 22px;
    //margin-top: 110px;
    height: 383px;
    position: relative;
    .close-button{
        width:29px;
        height:29px;
        position:absolute;
        top:19px;
        right:19px;
        @include close-button;
        box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
    }
    .save_input_head{
        @include flexStart;
        gap:8px;
        &::before{
            content:"";
            display: inline-block;
            width:17px;
            height:17px;
            background-image: url("../../images/global-icon/save.svg");
            background-repeat: no-repeat;

        }
        .save_head_name{
            width:auto;
            background-position: 0 14px;
            color: #434343;
            font-size: 20px;
            font-weight: 700;
            @include remove-white-space;
            @include flexCenter;
        }
    }
    .save-parent-input{
        display: grid;
        margin-top: 39px;
        grid-template-columns: 297px 291px 255px;
        gap: 31px 48px;

        .label{
            position: relative;
            left: 7px;
            margin-bottom: 10px;
            color: #0F7173;
            font-size: 16px;
            font-weight: 400;
            line-height: 11px;
        }
        div:last-child .label {
            position: relative;
            left: 0px;
          }
        input{
            padding: 5px;
            width: 100%;
            height: 42px;
            padding: 10px 14px;
            border-radius: 5px;
            background-color: #F2F2F2;
            border:none;
            color: #616161;
            box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
            transition: background-color 0.3s ease;
            &:focus{
                outline: none;
            }

            &.quotation-name{
                &:hover{
                    background: #EDEAE0;
                }
                &.error{
                    font-weight: 600;
                    line-height: 21.6px;                    
                }
            }
        }
    }

    
    .error-message{
        @extend .error;
        @include remove-white-space;
        @include flexStart;
        font-size: 16px;
        font-weight: 500;
        line-height: 21.6px;
        margin:21px 0 0 14px;
    }
    .savebtn_cot_btnblock{
        display: flex;
        justify-content: flex-end;
        height: 100%;
        .savebtn_cot{
            position: absolute;
            min-width: 165px;
            height: 36px;
            padding: 11px 18px;
            @include flexCenter;
            gap: 8px;
            border-radius: 17px;
            background-color: $myColor7;
            border: none;
            color:#fff;
            transition: background-color 0.3s ease;
            box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
            bottom: 26px;
            right: 26px;
            font-size: 16px;
            font-weight: 700;
            &>span{
                margin-bottom: 2px;
            }
            &::after{
                content: "";
                background: url("../../images/global-icon/next-arrow-white.svg") no-repeat center;
                width:13px;
                height:13px;
                display: inline-block;
            }
            &:hover{
                background-color: #0C5657;
            }
        }
    }

    .success_cotpopup{
        width: 1071px;
        height: 383px;
        border-radius: 22px;
        background: #EDF3EC url("../../images/succescot.png") no-repeat center 48px;
        position: absolute;
        left: 0;
        top:0;
        .success_popchild{
            text-align: center;
            position: absolute;
            top: 207px;
            color: #444;
            font-size: 22px;
            font-weight: 700;
            width: 100%;
            span{
                font-weight: 400;
                font-size: 16px;
                color: #444;
                
            }
            span:nth-child(2){
                color: #0F7173;
            }
            .success_popchild_btns{
                display: flex;
                justify-content: center;
                margin-top: 44px;
                gap:10px;
                button{
                    border-radius: 33px;
                    box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
                    font-size: 16px;
                    height: 36px;
                }
                button:nth-child(1){
                    width: 192px;
                    border: 0.5px solid #C8C8C8;
                    background: #FFF;
                    transition: all 0.3s ease;
                    &:hover{
                        border-radius: 33px;
                        border: 0.5px solid #0F7173;
                        background: #EDEAE0;
                        box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
                        // transition: 3ms;
                    }
                }
                button:nth-child(2){
                    color:#fff;
                    font-weight: 600;
                    width: 114px;
                    background: #0F7173;
                    transition: all 0.3s ease;
                    &:hover {
                        border-radius: 33px;
                        background: #0C5657;
                        box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
                        // transition: 3ms;
                    }
                }
              
            }
        }
    }
    
    
}

.success_cotpopup{
    width: 1071px;
    height: 383px;
    border-radius: 22px;
    background: #EDF3EC url("../../images/succescot.png") no-repeat center 48px;
    position: absolute;
    .success_popchild{
        text-align: center;
        position: absolute;
        top: 207px;
        color: #444;
        font-size: 22px;
        font-weight: 700;
        width: 100%;
        span{
            font-weight: 400;
            font-size: 16px;
            color: #444;
            
        }
        span:nth-child(2){
            color: #0F7173;
        }
        .success_popchild_btns{
            display: flex;
            justify-content: center;
            margin-top: 44px;
            gap:10px;
            button{
                border-radius: 33px;
                box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
                font-size: 16px;
                height: 36px;
            }
            button:nth-child(1){
                width: 192px;
                border: 0.5px solid #C8C8C8;
                background: #FFF;
                transition: all 0.3s ease;
                &:hover{
                    border-radius: 33px;
                    border: 0.5px solid #0F7173;
                    background: #EDEAE0;
                    box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
                    // transition: 3ms;
                }
            }
            button:nth-child(2){
                color:#fff;
                font-weight: 600;
                width: 114px;
                background: #0F7173;
                transition: all 0.3s ease;
                &:hover {
                    border-radius: 33px;
                    background: #0C5657;
                    box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
                    // transition: 3ms;
                }
            }
          
        }
    }
}

.selected_location{
    width: 100%;
    padding: 0px 0px 10px 0px;
    color:#000;
    .slectedLocation_head{
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 700;
        .remove_sel{
            color: #D24D4D;
            text-decoration: underline;
            cursor: pointer;
            text-underline-offset: 2px;
            font-weight: 400;
        }
    }
    .slectedLocation_body{
        font-family: 'Satoshi';
        border-radius: 9px;
        background-color: $myColor8;
        padding:7px 0 7px 45px;
        margin-top: 20px;
        background-image: url("../../images/gray-plane.png");
        background-repeat: no-repeat;
        background-position: 16px center;
        font-size: 14px;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 15px;
    span{
        font-weight: 300;
        font-size: 12px;
        line-height: 12px;

    }
    }

    .locationBack{
        background-image: url("../../images/location-pin5.png");
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
#amenities-popup {
    &.fadeOut {
        animation: fadeOut 0.5s ease-in-out forwards;
    }
}
.deletepopup_containerBooking {
    width: 100%;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    z-index: 122;
    animation: fadeIn 0.3s ease-in-out;
    
        // overflow: auto;
    &::before {
        content: "";
        position: absolute;
        inset: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.253);
        z-index: -1;
    }
}
.deletepopup_container{
    width: 100%;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    z-index: 122;
    
    // padding-top: 400px;
    animation: fadeIn 0.3s ease-in-out;
    // overflow: auto;
    &::before{
        content: "";
        position:absolute;
        inset: 0;
        width:100vw;
        height:100vh;
        background: rgb(0 0 0 / 70%);
        z-index:-1;
    }

    .amenities_block{
        width: 806px!important;
        height: auto!important;
        padding: 36px 29px 40px 29px;
        .amenities_list{
            height:auto!important;
        }
        &::before{
            content: "";
            display: none;
        }
        &>p{
            margin-bottom: 34px!important;
        }
    }
    .deletepopup_parent{
        width: 666px;
        height: 212px;
        border-radius: 12px;
        border: 1px solid #F66666;
        background: #FFF;
        box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
        font-style: normal;
        line-height: normal;
        color: #434343;
        padding: 32px 32px 25px 31px;
        float: left;
        top: 330px;
        position: relative;

        .deletepopup_text{
            font-size: 18px;
            font-weight: 700;
        }

        .popup_inputs{
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 32px;
            .inputbox{
                width: 184px;
                height: 44px;
                border-radius: 38px;
                border: 1px solid #0F7173;
                background: #FFF;
                box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
                font-size: 14px;
                font-weight: 700;
                color: #0F7173;
                padding: 17px 0 17px 49px;
                line-height: 8px;
                background-image: url("../../images/location-pin 1.png");
                background-repeat: no-repeat;
                background-size: 22px;
                background-position: 20px center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .popup_inputs_calandar{
                width: 175px;
                background-image: url("../../images/calendardark.png");
                background-repeat: no-repeat;
                background-size: 22px;
                padding: 9px 0 8px 53px;
                background-position: 20px center;
                line-height: 13.5px;
                span{
                    font-size: 13px;
                    font-weight: 500;
                    color: #0F7173;
                }
            }
        }


        .deletepopup_button{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            margin-top: 32px;
        
            button{
                width: 147px;
                height: 34px;
                color: #FF5050;
                font-size: 14px;
                font-weight: 500;
                background-color: #FFF;
                border-radius: 16.5px;
                border: 1px solid #FF5050;
                box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
                margin-left: 22px;
            }
            div{
                font-size: 14px;
                font-weight: 500;
                text-decoration-line: underline;
                text-underline-offset: 2px;
            }
        }
    }
    .container-transfer-list{
        background-color: white;
        padding: 52px;
        padding-top: 41px;
        border-radius: 34px;
        .noClick{
            pointer-events: none;
        }
        .title{
            font-family: Satoshi;
            font-weight: 700;
            font-size: 26px;
            line-height: 35.1px;
            color: rgba(15, 113, 115, 1);
            margin-bottom: 48px;
        }
        button{
            width: 197px;
            height: 48px;
            border-radius: 52px;
            background-color: rgb(255, 167, 93);
            color: white;
            position: relative;
            right: -1088px;
            top: 48px;
            font-family: Inter;
            font-weight: 700;
            font-size: 16px;
            line-height: 19.36px;
            letter-spacing: 0%;
            box-shadow: 0px 6px 7.9px 0px rgba(0, 0, 0, 0.08);
            margin-bottom: 40px;
            &:hover{
                background: rgba(255, 202, 157, 1);
                transition: 300ms;
            }
        }
    }
}




.accomSkeletonLoad1{
    background: url("../../images/loading/accomLoad1.png") no-repeat;
    width: 100%;
    height: 100vh;
}
.accomSkeletonLoad2{
    @extend .accomSkeletonLoad1;
   background: url("../../images/loading/accomLoad2.png") no-repeat;
   background-size: cover;
   z-index: 120;
    position:absolute;
}
.accomSkeletonLoad3{
    @extend .accomSkeletonLoad1;
   background: url("../../images/loading/accomLoad1.png") no-repeat;
   background-size: cover;
   z-index: 120;
    position:absolute;
}
.transferSkeletonLoad1{
    @extend .accomSkeletonLoad1;
   background: url("../../images/loading/transferLoad1.png") no-repeat;
   background-size: cover;
   z-index: 120;
    position:absolute;
}
.transferBoxSkeletonLoad {
    @extend .accomSkeletonLoad1;
    background: url("../../images/loading/carBoxSkeleton.png") no-repeat;
    background-size: cover;
    z-index: 119;
    height: 1504px;
    // position: absolute;
}
.success-delete{
    width:100%;
    position: fixed;
    top:100px;
    left: 0;
    display: grid;
    place-items: center;
    
    p{
        width: 600px;
        height: 79px;
        background-color: #F6F0B7;
        display: grid;
        place-items: center;
        margin-bottom: 0;
        border-radius: 11px;
        color:#ABA672;
        font-size: 20px;
        font-weight: 700
    }
}
.vehicle-fill-pop{
    @include popup;
    display: unset;
    width: unset;
    height: unset;
    position: unset;
    p{
        position: relative;
        margin: 0;
        width: 398px;
        height: 66px;
        background-color: #F27E65;
        display: grid;
        place-items: center;
        color: #fff;
        font-weight: 700;
        border-radius: 11px;
    }
}
.hotel-fill-pop{
    @include popup;
    display: unset;
    width: unset;
    height: unset;
    position: unset;
    p{
        position: relative;
        margin: 0;
        width: 398px;
        height: 66px;
        background-color: #F27E65;
        display: grid;
        place-items: center;
        color: #fff;
        font-weight: 700;
        border-radius: 11px;
    }
}


.errorFixed-background{
    @extend .deletepopup_container;
}
.deletepopup_container_wht-back{
    @extend .deletepopup_container;
    &::before{
        background: none
    }
}
.addservice{
    @extend .deletepopup_container;
    overflow-y: auto;
    overflow-x: hidden;
    &::before{
        height: 200vh;
    }
}

.agency-popup-background{
    @extend .deletepopup_container;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 90px 170px 0 0;
    &::before{
        content: "";
        position:absolute;
        inset: 0;
        width:100vw;
        height:100vh;
        background: none;
        z-index:-1;
    }

}

.error_popup_parent{
    position: fixed;
    width: 100%;
    height: 100%;
    top:120px;
    left:0;
    right:0;
    display: flex;
    justify-content: center;
    div{
        width: 600px;
        height: 79px;
        border-radius: 11px;
        background-color: #F6F0B7;
        box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.25);
        @include flexCenter;
    }
}

.errorBox{
    width: 550px;
    background: #fff;
    margin-top: 80px;
    border-radius: 15px;
    padding: 30px 20px;
    height: fit-content;
}
.popbtn{
    width: 65px;
    background:#FFA75D;
    border-radius: 25px;
}
