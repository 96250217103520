.skeletonDrop_parent{
    width: 400px;
    height: 72px;
   
    .skeletonDrop_loop{
        width: 400px;
        display: grid;
        grid-template-columns: 29px auto;
        align-items: center;
        float: left;
        padding: 26px 0 15px 16px;
        border-bottom: 1px solid #AEAEAE;
        opacity: 0.39;
    }

    .skeletonDrop_loop:nth-child(1){
        padding-top:0;
        height: 42px;
    }
    .skeletonDrop_loop:nth-child(3){
        border-bottom: unset;
    }
}

.skeletonCar_parent{

width: 500px;
position: relative;
background: linear-gradient(90deg, #F1EFEF -24.18%, #F9F8F8 50.26%, #E7E5E5 114.84%);
height: 500px;
.skeletonCar_datas{
    width: 100%;
    position: absolute;
    top: 190px;
    padding: 30px;
    .MuiSkeleton-text{
        margin-top: 24.5px;
    }
}
}

.footer_parent{
    width: 100%;
    font-family: Satoshi;
    height: 294px;
    background-color: #0D3233;
    box-shadow: 0px -4px 19px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    padding: 64px 0 60px 124px;
    display: grid;
    grid-template-columns: 125px 95px 320px 118px;
    gap: 0 102px;
    
    
        .footer_texthd{
            font-size: 18px;    
            font-weight: 700;
            @include lineHeight;
            margin-bottom: 28px;
        }
        .footer_text{
            font-size: 13px;
            font-weight: 400;
            @include lineHeight;
            text-decoration-line: underline;
            margin-bottom: 7px;
        }
        .footer_text2{
            font-size: 13px;
            font-weight: 500;
            @include lineHeight;
            margin-bottom: 7px;
            span{
                text-decoration-line: underline;
            }
        }
        .footer_imgblk{
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        .footer_name_rights{
            width: 260px;
            height: 130px;
            position: absolute;
            right: 82px;
            z-index: 95;
            .footer_logo{
                background-image: url("../../images/footer_logo.png");
                background-repeat: no-repeat;
                height: 54px;
                background-size: cover;
                width: 250px;

            }
            .footer_rightstxt{
                font-family: Satoshi-Medium;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                margin: 44px 0 0 25px;
            }
        }
}