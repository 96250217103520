@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Vithkuqi&family=REM:wght@800&display=swap");



@font-face {
  font-family: Satoshi;
  src: url("../public/fonts/Satoshi-Regular.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


@font-face {
  font-family: Satoshi-Medium;
  src: url("../public/fonts/Satoshi-Medium.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@font-face {
  font-family: Satoshi-Bold;
  src: url("../public/fonts/Satoshi-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}



.hide{
  display: none;
}
.hidden{
  visibility: hidden;
}

.disabledCss{
  opacity: 0.5;
  cursor: none !important;
}

.disabledCss2{
  opacity: 0.5;
  cursor: default !important;
  pointer-events: none;
}

/* ************************************************************************************************************************/

.mainContainer {
  /* position: absolute;
  top: 0px;
  display: flex;
  justify-content: center;
  width: 100%;
  font-family: Satoshi; */
}

/* #root{
  overflow-x: auto;
} */
.main_quotation{
  width: 100%;
  justify-content: flex-start;
    padding-left: 150px;
}
.mainParent::-webkit-scrollbar{
/* overflow-y: scroll; */
}


.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  top: 0 !important;
  border-bottom-color: none !important;
}

.react-datepicker {
  border: none;
  border-radius: 31px;
  background: #F9F9F9;
  padding: 119px 36px 20px;
  font-family: Satoshi;
  font-size: 16px;
  width: 616px;
  position: absolute;
  top: 0px;
  height: 509px;
  color: #434343;
  box-shadow: 8px 2px 4px 0px rgba(0, 0, 0, 0.18);
  z-index: 100;
  margin-bottom: 80px;
}

.react-datepicker__header {
  background: none;
  border-bottom: none;
}
.react-datepicker__day-names {
  color: #434343;
  font-family: Satoshi;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.react-datepicker__week {
  font-family: Satoshi;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.react-datepicker__month-container {
  width: 272px;
}
.react-datepicker__month-container:nth-of-type(2){
  padding-right:23px ;
}
.react-datepicker__month:nth-of-type(2){
  margin: 0.4rem 0rem;
}

.react-datepicker >.react-datepicker__month-container {
  font-size: 16px;
}

.react-datepicker__navigation-icon::before{
  border-color: #0a0a0a;
  border-width: 2px 2px 0 0;
}


.react-datepicker__navigation--previous {
  left: 23px;
  top: 135px;
}

.react-datepicker__navigation--next {
  right: 25px;
  top: 135px;
}
.react-datepicker__current-month {
  text-align: left;
  padding-left: 40px;
  padding-bottom: 10px;
  color: #434343;
  font-family: Satoshi;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.react-datepicker__current-month:hover{
  color: #0F7173;
}
.react-datepicker .react-datepicker__month-container:nth-of-type(2) .react-datepicker__header .react-datepicker__current-month {
 padding-left: 21px;
 padding-top: 5px;
}
.react-datepicker .react-datepicker__month-container:nth-of-type(3) .react-datepicker__header .react-datepicker__current-month {
 padding-left: 115px;
 padding-top: 5px;
}
.react-datepicker .react-datepicker__month-container:nth-of-type(3) .react-datepicker__month .react-datepicker__week .react-datepicker__day {
 position: relative;
 bottom: 11px;
}
.overlay{
  /* width: 100%;
  position: absolute;
  height: 100%;
  top:0;
  left: 0;
  background-color: #3f3e3e;
  opacity: 0.8; */
}

.react-datepicker__day {
  width: 36px;
  height: 36px;
  padding: 4px;
  margin: 0px;
  box-sizing: border-box;
  border-radius: 50%;
}

.react-datepicker__day-name {
  width: 36px !important;
  height: 36px;
  padding: 5px 9px 4px 8px;
  margin: 0px;
  box-sizing: border-box;
}

.react-datepicker__day:hover{
  border-radius: 50%;
    background-color: #DADCDC;
    opacity: 1;
    transition: all 188ms ease-out; 
}

.react-datepicker__day--selected{
  border-radius: 50%;
  background-color:  #FFA75D;
  color: #fff;
 
}

.react-datepicker__day--selected:hover {
  background-color: #FFA75D;
  color: #fff;
  transition: 0.3s;
}
.react-datepicker__day-name:nth-child(6){
color:#FFA75D ;
}


.react-datepicker__day-name:nth-child(7){
  color:#FFA75D ;
}

.react-datepicker__day--disabled{
  color: #A59E9E;

}
.inputDateContainer {
  /* //width: 250px; */
  width: 100%;
  float: left;
}
.buttonBlock {
  position: relative;
  width: 100%;
  float: left;
  margin-top: 50px;
}
.buttonBlockRight{
  display: flex;
  /* gap:15px; */
  position: relative;
  right: 20px;

}
.errormsg{
  color: #FF5050;
  font-family: Satoshi;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-image: url("./Assets/images/error_dis.png");
  background-repeat: no-repeat;
  background-size: 20.3px;
  background-position: 0 center;
  padding-left: 30px;

}

.buttonBlock_select{
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 460px auto;
}
.buttonBlock_select.update-search-results{
  grid-template-columns: 498px auto;
}

.buttonBlock_select_p{
  position: absolute;
  top:-30px;
  width: 100%;
  margin-bottom: 0px;
  background: url("./Assets/images/error_dis.png") no-repeat 0px 0;
  color:#FF5050;
  padding-left: 30px;
}

.buttonBlockLeft{
  position: relative;
  display: flex;
  padding-top: 15px;
  cursor: pointer;
  float: left;
  height: 39px;
}


.checkbox_same{
  width: 18px;
  height: 18px;
  border-radius: 2px;
  cursor: pointer;
  margin-top: 3px;
  
}

.checkbox_same::before{
  content:"";
  position: absolute;
  
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background-color:#E7E7E7;
  transition: 0.3s;
}


.checkbox_same:hover::before{
  content:"";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background-color: #97A9A9;
  transition: 0.3s;
  background-position: center;
}


.checkbox_same:checked::before{
  content:"";
  position: absolute;
  width: 18px;
  height: 18px;
  background-image: url("./Assets/images/checkboxtick.png");
  background-repeat: no-repeat;
  top: 18px;
  left: 0px;
  border-radius: 0px;
  background-color: #E7E7E7;
  background-position: center;

}

.checkbox_same:checked:hover::before{
  content:"";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background-color: #E7E7E7;
  transition: 0.3s;
}

.labelText{
  margin-left: 22px;
  color: #6D6D6D;
  font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}


.buttonBlockLeft:hover >.checkbox_same::before {
  content:"";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background-color: #97A9A9;
  transition: 0.3s;
}

.buttonBlockLeft:hover >.checkbox_same:checked::before {
  background-color: #E7E7E7;

}


.deleteAll{
  width: 228px;
  height: 48px;
  border-radius: 28px;
  background: #FF5050;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.28);
  border: none;
  color: #FFF;
  float: right;
  font-size: 18px;
  font-weight: 900;
}
.deleteAll:hover{
  background-color: #F67D7D;;
  color: #975E5E;
}
.calendarDropdown {
  /* position: fixed;
  z-index: 5;
  top: 120px; */
 
}

.inputLine {
  background-image: url("./Assets/images/yellow-line.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 62px;
  background-size: 20px;
  margin: 0px 11px;
}

.arrowLine{
  background-image: url("./Assets/images/static_arrow.png");
  /* height: 15px;
  width: 23px; */
  transform: scale(1.2);
}

.inputImgBackground {
  background-image: url("./Assets/images/Group24-new.png");
  width: 100%;
  height: 100px;
  background-repeat: no-repeat;
  background-position: 0 15px;
  float:left;
  cursor: pointer;
}

.inputBoxDb {
  width: 100%;
  float: left;
}

.inputBoxDb::-webkit-scrollbar {
  width: 8px;
  height: 250px;
}

.inputBoxDb::-webkit-scrollbar-track {
  border-radius: 4px;
  background: #e6e6e6;
}

.inputBoxDb::-webkit-scrollbar-thumb {
  width: 7px;
  height: 50px;
  border-radius: 4px;
  background: #696969;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}



.searchBtn {
  width: 228px;
  height: 48px;
  border-radius: 29px;
  background: #ffa75d;
  box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
  border: none;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-family: Satoshi-Bold;
}
.searchBtn:hover {
  background-color: #FFCA9D;
  transition: 0.3s;
}
.searchBtn.update-search-results{
  width: 270px;
}

.dropParent >.selectboxDiv{
  width: 253px;
    height: 57px;
    border-radius: 18px;
    border: none;
    box-shadow: 0px 2px 7px -1px rgba(0, 0, 0, 0.25);
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    box-sizing: border-box;
    cursor: pointer;
    color: #9f9f9f;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-repeat: no-repeat;
    background-position: 380px 15px;
    background-color: #f4f4f4;
}

.dropParent >.selectboxDiv::after{
  background: none;
  border-top: 0.5em solid;
    border-right: 0.5em solid transparent;
    border-bottom: 0;
    border-left: 0.5em solid transparent;
    margin: 15px 10px 0 0;
}

.react-datepicker__day--today{
  font-weight: unset;
}

.react-datepicker__day.react-datepicker__day--selected.react-datepicker__day--outside-month{
  background-color: #f9f9f9;
  pointer-events: none;
}
.react-datepicker__day.react-datepicker__day--outside-month{
  background-color: #f9f9f9;
  pointer-events: none;
}
.react-datepicker__day--outside-month:hover{
  background-color: #f9f9f9;
  pointer-events: none;
}
.react-datepicker__day--keyboard-selected{
  background-color: #f9f9f9;
  pointer-events: none;
}

.loading_components{
    width: 100%;
    float: left;
    position: relative;
    margin-top: 0px;
}

.wrapper {
  border: 1px solid grey;
  width: 200px;
  height: 200px;
  overflow-y: scroll;
}


.inputs-boxes{
  width: 100%;
    height: 62px;
    border-radius: 32px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    font-family: Satoshi;
    line-height: normal;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.15);
    padding: 6px 0 5px 54px;
    display: grid;
    grid-template-columns: 1fr;
    align-content: center;
    cursor: pointer;
}

.inputs-boxes:hover{
  border-radius: 38px;
  background-color: #edeae0;
  border: 0.5px solid #0f7173;
  box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}

.star-rating{
  background-image: url("../src/Assets/images/accomodation/accomstar.png");
  background-repeat: no-repeat;
  background-position: 22px center;
  background-size: 20px;
  font-size: 15px;
}

.selected-star-name{
  color: #434343;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
 
}
.selected-star-name b{
  font-weight: 700;
}

.star-rating div:nth-child(2){
  color: #0a0a0a;
}

.card-starrating{
  width: 351px;
  border-radius: 23px;
  padding: 20px;
 margin-top: 7px;
 background-color: #fff;
 box-shadow: 0px 2px 7px -1px rgba(0, 0, 0, 0.25);
 position: absolute;
 z-index: 99;
}

.star-icon{
  display: grid;
  grid-template-columns: 112px 101px 46px;
  grid-gap: 0px;
  border: 0.5px solid #d6d6d6;
  background-color: #fff;
	transition: background-color ease-out 100ms;
  padding: 12.5px 23px 12.5px 18px;
  border-radius: 13px;
  margin-bottom: 13px;
  align-items: center;
  cursor: pointer;
  width: 300px;
  height: 43px;
  font-size: 14px;
  font-weight: 600;
  color:#434343;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
}

.star-icon:hover{
background-color: #ebebeb;
}

.rating-menu-star{
 height: 18px;
}

.star-icon.addborder-star{
  border: 0.5px solid #0F7173;
}
.checkbox-star{
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #D9D9D9;
  margin-left: 28px;
}
.sel-star{
  background-color: #8CB5B0;
  box-shadow: 0px 1px 1.7px 0px rgba(0, 0, 0, 0.08) inset;
  background-image: url("./Assets/images/star-check.png");

}
.rating-valbtn{
  width: 94px;
  height: 29px;
  color:#fff;
  margin-top: 10px;
    float: right;
    border-radius: 15px;
background: #0F7173;

/* Layers */
box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
}
.rating-valbtn:hover{
  background: #0D3233
}

.css-1lauo1g-MuiRating-root {
  color: #FFD646 !important;
}
.MuiRating-iconEmpty{
  color:#dadada !important;
}




/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: #898989;
  }
}

@keyframes texthead {
  0% {
    width: 0px;
  }

  100% {
    width: 58%;
  }
}



/* DROP DWON DISABLE */


/* #Excursion_portal{
  opacity: 0.2;
  pointer-events: none;
}

.dropdown-optmenu:nth-child(4){
  opacity: 0.2;
  pointer-events: none;
} */



