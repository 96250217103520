@use 'sass:color';  

$error-color: #ff4c4c;
$background-color: #f9f9f9;
$button-color: #007bff;
$button-hover-color: color.scale($button-color, $lightness: -10%);
$container-padding: 2rem;
$border-radius: 10px;

.error-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: $background-color;
    font-family: Arial, sans-serif;
    color: #333;
}

.error-container {
    text-align: center;
    background: #fff;
    padding: $container-padding;
    border-radius: $border-radius;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.error-title {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: $error-color;
}

.error-message {
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.retry-button {
    background-color: $button-color;
    color: #fff;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border-radius: $border-radius;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: $button-hover-color;
    }
}
