
.booking_index{
    font-family: Satoshi;
    background-color: #fff;
    float: left;
    width: 100%;
    padding: 89px 146px 54px 145px;
    background: url("../../images/background/coconut-right.svg") no-repeat right top;
    background-size: (57vh);
    background-position: right calc(0% - 11vh);
    z-index:119;
    .show{
        display: block;
    }
    .hide{
        display: none;
    }



.booking_head_parent{
    font-size: 12px;
    font-weight: 400;
    .title{
        @include line-name;
        padding-left:16px;
    }
    .btnblock{
        display: flex;
        justify-content: flex-start;
        margin: 40px 0 70px 0;
        gap:19px;
        .page-selected{
            min-width: 172px;
            padding: 4px 41px;
            gap: 10px;
            height: 35px;
            border-radius: 20px;
            border: 1px solid #C58764;
            background: #F5A477;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
            backdrop-filter: blur(2px);
            @include flexCenter;
            font-size: 20px;
            font-weight: 500;
            color:#fff;
        }
        .bookbtn{
            @extend .page-selected;
            cursor: pointer;
            border: 1px solid #D9D9D9;
            background: #FFF;
            color: #F5A477;
            transition: background-color 0.3s ease;

            &:hover{
                border: 1px solid #D5D2C7;
                background: #EDEAE0;
            }
        }
    }
    .exportblock{
        display: grid;
        grid-template-columns: 50% 50%;
        align-items: center;
        margin-bottom: 49px;
        input{
            width: 387px;
            height: 40px;
            border-radius: 15px;
            border: 0.5px solid #D9D9D9;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
            backdrop-filter: blur(2px);
            background:  url("../../images/quosearch.png") no-repeat 12px;
            $iconPaddingLeft:24px;
            background-position: $iconPaddingLeft center;
            padding-left: calc($iconPaddingLeft + 12px + 10px);
            background-color: #fff;
            transition: background-color ease-in-out 120ms;
            color: #969696;
            font-weight: 500;
            outline: none;
            font-size: 16px;
            cursor: pointer;
            @include flexCenter;
            &::placeholder{
                font-size: inherit;
            }
            &:focus{
                color:#969696;
                background-color: #EDEAE0;
            }
            &:hover{
                background-color: #EDEAE0;
                    
            }
            &:focus::placeholder{
                color: transparent;
            }
        }
        input.visited{
            background-color: #EDEAE0;
        }
        div{

            @include flexEnd;
        button{
            width: 131px;
            margin-left: 14px;
            height: 35px;
            border-radius: 25px;
            border: 1px solid #0F7173;
            background:  url("../../images/expo_export_green.png") no-repeat 28px 8px;
            background-color: #fff;
            transition: background-color ease-in-out 120ms;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
            color: #0F7173;
            font-size: 16px;
            font-weight: 700;
            padding-left: 28px;
            &:hover{
                background: #EDEAE0 url("../../images/expo_export_green.png") no-repeat 28px 8px;
            }

        }
        button:nth-child(2){
            color: #fff;
            border: none;
            width: 144px;
            padding-left: 28px;
            background: #FFAB63 url("../../images/addtransfer_hover.png") no-repeat 20px center;
            background-size: 15px;
            &:hover{
                background: #E0914E url("../../images/addtransfer_hover.png") no-repeat 20px center;
                background-size: 15px;
            }
        }
    }
    }
    .export_popup{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        .export_popup_div{
            width: 655px;
            height: 244px;
            border-radius: 10px;
            background-color: #FFF;
            padding: 28px 21px 21px 33px;
            h5{
                background-image: url("../../images/expo_export.png");
                background-repeat: no-repeat;
                background-position: 0 center;
                padding-left: 20px;
                span{
                    background-image: url("../../images/expo_close.png");
                    background-repeat: no-repeat;
                    background-size: 15px;
                    width: 15px;
                    float: right;
                    height: 15px;
                    cursor: pointer;
                }
            }
            .expop_dropdown{
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-top: 46px;
            }
            .expobtn_blk{
                margin-top: 20px;
                @include flexEnd;
                button{
                    width: 106px;
                    height: 20px;
                    border-radius: 4px;
                    background: #86D7B0;
                    border: none;
                    color: #FFF;
                    font-size: 12px;
                    font-weight: 700;
                }
            }
        }
    }
   
}

// Table scss... part  start
.quotation_parent{
  
    .table_head_cell{
        text-align: left;
        font-size: 16px; 
        font-weight: 700;
        padding: 0;
        height: 42px;
        padding-left: 29px;
        color: #585858;
        border: unset !important;
    }
    .tablebody_row:hover{
        background: rgba(148, 184, 218, 0.30);
        cursor: pointer;
    }

    ///..............Table body cells nested div  STARTS...............................
 
    .table_body_cell{
        text-align: left;
        padding-left: 31px;
        font-size: 12px ;
        font-weight: 400;
        position: relative;
        .table_body_subcell{
            border: none;
        }
        .table_body_subcell:nth-child(1){
           padding-left: 0;
        }
        .color_pattee{
            background-image: url("../../images/quotation_colorpatte.png");
            background-repeat: no-repeat;
            background-size: 12px;
            background-position: center;
            border-radius: 50%;
            background-color: #D9D9D9;
            width: 26px;
            height: 26px;
            padding: 6px;
            margin: 0 30px 0 47px;
        }

        .paxloop_quo{
            display: flex;
            width: 360px;
            justify-content: space-between;
        }
        .cell_dots{
            background-image: url("../../images/quotation_dots.png");
            background-repeat: no-repeat;
            width: 4px;
            height: 18px;
        }
        .quo_booking {
            button{
                width: 142px;
                height: 23px;
                border-radius: 16px;
                background: #6590A9;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
                border: none;
                color: #FFF;
                font-size: 12px;
                font-weight: 500;
            }   
        }
        .free_btn {
            button{
                background: #5BB0DF;
            }
            
        }
        .pay_btn{
            button{
            background: #495257;
            }
        }
        .delpop1{
            position: absolute;
            top:60px;
            left:-111px;
            width: 160px;
            height: 33px;
            border-radius: 3px;
            background-color: #EEE;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14);
            font-size: 12px;
            font-weight: 400;
            padding: 10px 0 10px 39px;
            background-image: url("../../images/quo_delicon.png");
            background-repeat: no-repeat;
            background-position: 14px center;
        }
        .delcon_popup{
            width: 203px;
            height: 129px;
            border-radius: 3px;
            background-color: $warning-red-color;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14);
            color: #8F0D0D;
            text-align: center;
            padding: 43px 12px 0px 12px;
            position: absolute;
            left: -160px;
            z-index: 100;
            top: 65px;
            .delcon_popup_btn{
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
        }
        
    }
///..............Table body cells nested div   ENDS...............................


    .table_body_cell:nth-child(3){
        color: #fff;
        text-align: center;
        
    }   

    .table_body_cell:nth-child(9){
        display: flex;
        height: 93.65px;
        align-items: center;
        gap:30px
    }
    .table_body_cell:nth-child(10){
        border: none;
    }

    .table_head_cell:nth-child(5),.table_head_cell:nth-child(7),.table_body_cell:nth-child(5),.table_body_cell:nth-child(7){
      background-image: url("../../images/global-icon/calendar-grey.svg");
      background-repeat: no-repeat;
      background-position: 30px center;
      padding-left: 50px;
      background-size: 15px;
    }
    
    .statuscell div{
        cursor: pointer;
        border-radius: 3px;
        width: 110px;
        padding: 2px 0px;
    }

    .status_free div{
        background: #71E28A;
    }
    .status_req div{
        background: #F5A74C;
    }
    .status_payment div{
        background: #205C7E;
    }
    .paxselected div{
        border-radius: 16px;
        background: #86D7B0;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
        width: 73px;
        text-align: center;
        padding: 4px 0;
        font-size: 12px ;
        font-weight: 400;
        color: #fff;
    }
    .paxunselected div{
        border-radius: 16px;
        border: 0.5px solid #AAA;
        background: #FFF;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
        text-align: center;
        width: 73px;
        padding: 4px 0;
        font-size: 12px ;
        font-weight: 400;
        color: #686868;
        font-weight: 500;
    }
}
// Table scss... part  ends


//dropdowns part start
.dropdown_list_parent{
    display: grid;
    width: 100%;
    margin: 20px 0 66px;
    // grid-template-columns:195px 210px 155px;
    grid-template-columns:194px 165px 155px;
    gap: 13px;
    .select-container{
        width:auto;
    }
    .select-data{
        width: auto;
        height: 32px;
        border-radius: 25px;
        border: 1px solid #6F8C8D;
        background: #FFF;
        color: #797979;
        padding: 0px 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: background-color 0.3s ease;
        box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
        cursor: pointer;

        &::after{
            @include arrow-dropdown;
        }

        &.open{
            background: #EDEAE0;
            &::after{
                transform: rotate(180deg);
            }
        }
        &:hover{
            @extend .open;
        }

        &.selected{
            color: $myColor7;
        }
    }
    
    .select-options{
        @include select-options;
        padding: 12px 10px;
        white-space: nowrap;
        overflow: hidden;
    }
}



.dates_parent{
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #BCBCBC;
    font-size: 10px;
    font-weight: 400;
    margin-bottom: 14px;
  
    .dates_box{
        width: 34px;
        height: 14px;
        border-radius: 2px;
        background: #EFEFEF;
        position: relative;
        height: 14px;
        padding: 2px 5px;
        display: flex;
        align-items: center;
        background-image: url("../../images/quodown1.png");
        background-repeat: no-repeat;
        background-position: 90% center;
        cursor: pointer;
        .date_popup{
            width: 34px;
            border-radius: 2px;
            background: #EFEFEF;
            position: absolute;
            left: 0;
            top: 20px;
            padding: 5px 0 5px 0;
            box-sizing: border-box;
            z-index: 10;
            .quo_popup_scroll{
                overflow-y: scroll;
                width: 30px;
                height: 60px;
                text-align: center;
                .quo_date_data{
                    cursor: pointer;
                    padding: 3px 0;
                }
                .quo_date_data:hover{
                   background-color: #f4f4f4;
                   color: #000;
                }
            }

            .quo_popup_scroll::-webkit-scrollbar {
                width: 0.4em;
                border-radius: 1px;
            }
             
            .quo_popup_scroll::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            }
             
            .quo_popup_scroll::-webkit-scrollbar-thumb {
                border-radius: 1px;
                background: #8A8A8A;
            }


        }
    }
   
    .quotation_year {
        width: 44px;
        .date_popup {
            width: 44px;
        .quo_popup_scroll{
            width: 40px;
            .quo_date_data{
                cursor: pointer;
            }
        }
    }
    }
}

.dropdown_checkbox{
    padding-top: 0;
    .checkbox_same:checked::before{
        content:"";
        top: 2px;
        background-position:center;
    }

}


// if row is true .rowflex class was added to dropdown_list_parent
.rowflex{
    display: flex;
    justify-content: flex-end
}

.confirm_book_parent{
    .confirm_blocks_head{
        display: flex;
        justify-content: space-between;
        button{
            width: 218px;
            height: 35px;
            background-color: #71E28A;
            border: none;
            border-radius: 25px;
            color:#fff;
            font-size: 17px;
            font-weight: 700;
            background-image: url("../../images/selectedCar.png");
            background-repeat: no-repeat;
            background-position: 30px center;
        }
    }
    .confirm_blocks_body{
        display: flex;
        justify-content: space-evenly;
        gap: 100px;
    }    

}

}

.client_parent_dropdown{
    color: #585858;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    position: relative;
    p{
        font-weight: 400;
        font-size: 16px;
        line-height: 21.6px;
        margin-bottom: 15px;
    }
  
    .client-select{
        width:100%;
        height:35px;
        border-radius: 21px;
        border: none;
        box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
        // border: 1px solid #6F8C8D;
        padding-left:20px;
        display:flex;
        align-items:center;
        justify-content: flex-start;
        cursor: pointer;
        background-color: #fff;
        transition: background-color ease-in-out 120ms;
        color: #797979;
        font-size: 16px;
        font-weight: 500;
        &::after{
            content: "";
            // background:red url("../../images/quodown1.png")no-repeat 95% center;
            width: 14px;
            height: 14px;
            background-image: url("../../images/calendar/arrowLightGrey.png");
            // position: absolute;
            // background-position: right center;
            background-repeat: no-repeat;
            // right: 20px;
            // top: 12px;
            transition: 0.2s;
            background-size: 14px 14px;
            // align-self: flex-end;
            // justify-self: flex-end;
            margin-left: auto;
            margin-right: 20px;
        }
        &::before{
            content: "";
            // background:red url("../../images/quodown1.png")no-repeat 95% center;
            width: 12px;
            height: 12px;
            background-image: url("../../images/calendar/pax.png");
            // position: relative;
            background-position: left center;
            background-repeat: no-repeat;
            // left: 20px;
            // top: 12px;
            // transition: 0.2s;
            margin-right: 6px;
            background-size: 12px 12px;
        }

        &:hover::after{
            @include arrow-pax;
        }
        &:hover{
            background-color: #EDEAE0;
        }
    }

    .pax-clicked{
        &:hover{
            background-color: initial;
        }

        &::after{
            @include arrow-pax;
            // position: absolute;
            // background-position: right center;
            // background-repeat: no-repeat;
            // right: 20px;
            // top: 12px;
            background-size: 14px 14px;
        }
    }
    .selected{
        color: #0F7173;
        font-size: 16px;
        font-weight: 500;
    }
    .client-options{
        border-radius: 14px;
        background: #FFF;
        box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
        padding: 12px 15px;
        margin-top: 9px;
        position: absolute;
        z-index: 90;
        width: 100%;
        div{
            display: flex;
            padding: 7px 0 7px 12px;
            justify-content: flex-start;
            align-items: center;
            color: #797979;
            font-size: 16px;
            font-weight: 400;
            height: 23px;
            margin-bottom: 10px;
            cursor: pointer;
            &:hover{
                background: #E7E5E5;
                border-radius: 14px;
            }
        }
        .opt-selected{
            background: #0F7173;
            color:#fff;
            border-radius: 9px;
            &:hover{
                border-radius: 9px;
                background: #0F7173;
            }
        }

        .client-opt{
            &:hover{
                @extend .opt-selected
            }
        }
    }
}

//////////////////////// BOOKING SUMMARY /////////////////////////////
.icon-dashed-line-grey{
    height: 2px;
    width: 100%;
    margin-top:32px;
    @include generateIconClass("../../images/global-icon/dashed-line-grey.svg",2px,100%)
}
.booking-docket-container{
    width:  60.03700278vh; 
    position: fixed;
    right: $rightBookingDocket;
    top:116px;
}
.docket-zigzag{
    width:100%;
}
.airImg {
    &::before {
            background: url("../../images/savetransfer_plane.png") no-repeat center;
                background-size: 13px;
        }

}
.booking_summary_parent{
    padding:29px 35px 0 35px ;
    border-radius: 26px 26px 0 0;
    background: rgba(255, 255, 255, 0.50);
    border-top:0.5px solid rgb(242,242,242);
    border-left:0.5px solid rgb(242,242,242);
    border-right:0.5px solid rgb(242,242,242);
    height: max-content;
    // filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.25));
    backdrop-filter: blur(2px);
    // @include saw-tooth-vertical(32px,red);
    // background: url("../../images/global-icon/booking-docket.svg") center/70px 100% no-repeat;
    .docket-img-booking{
        position: absolute;
        top:0;
        left:0;
        z-index: -1;
        object-fit:cover;
        width: 100%;
        // height:100px;
    }
    .date-range-container{
        color: #797979;
        font-size: 18px;
        font-weight: 700;
        margin-top: 1.85013876vh;
        &>span{
            @include date-label-green;

            &:first-child{
                margin-left: 12px;
                &::after{
                    @include generatePseudoElement("../../images/global-icon/next-arrow-black.svg",10px,10px);
                    margin-right: 9px;
                }
            }
        }
    }
    .nb-item-container{
        padding-top: 29px;
        @include flexCenter;
        @include remove-white-space;
        color: #434343;
        font-size: 16px;
        font-weight: 400;
    }
    h5{
        color: $myColor7;
        font-size: 24px;
        font-weight: 900;
        background: url("../../images/mautourco-logo.png") center right/contain no-repeat;
        margin: 0;
    }
    .summary_loop{
        margin-top: 38px;
        max-height: 47.94107308vh;
        overflow-y: auto;
        overflow-x: hidden;
        @include flexStart;
        gap: 20px;
        flex-direction: column;
        align-items: flex-start;
        @include default-scrollbar;
        .summary_loopblk{
            display: flex;
            flex-direction: column;
            gap: 10px;
        label{
            margin-bottom: 0px;
            background: url("../../images/svg/chambre2.svg") no-repeat ;
            background-position: 14px 2px;
            color:#000;
            font-weight: 600;
            &:before{
                content:"";
                background: #FFA75D;
                width: 3px;
                height: 14px;
                float: left;
                margin: 3px 37px 0 0;
            }
           span{
            // width: 15px;
            // float: right;
            // height: 15px;
            // margin: 5px 0 0 5px;
           }
        }
        // .airImg{
        //     background: url("../../images/savetransfer_plane.png") no-repeat center;
        //     background-size: 13px;
        // }
        .hotelname{
            color: #4A4A4A;
            font-size: 16px;
            font-weight: 500;
            margin-top: 15px;
        }
    .summary_img_cnt{
        display: grid;
        grid-template-columns: 207px auto;
        gap: 20px;
        .summaryImg-container{
            width: 12.9375rem;
            height: 11.25rem;
            border-radius: 4px;   
        }
        .summaryImg{
            height: 90%;
            width:100%;
            border-radius: inherit;
            
            .summary-image{
                width: 207px;
                height: 162px;
                object-fit: cover;
            }
        }
        .summaryData{
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            color: #000;
            .summaryData-text{
                margin-bottom: 5px;
                
            }
            .summaryData-text-chambre{
                padding-left: 18px;
                background: url("../../images/svg/chambre1.svg") no-repeat 0px center;
            }
            .dates{
                color: #0F7173;
                .first-date{
                    background: url("../../images/calendardark.png") no-repeat 0 center;
                    background-size: 11px;
                    background-position-y: 4px;
                    padding-left: 19px;
                    color:#0F7173;
                }
            }
            .nights-docket{
                background: url("../../images/yellowmoon.png") no-repeat 0px center;
                padding-left: 16px;
                margin-bottom: 14px;
            }
            .summary_locations {
                margin-bottom: 1px;
            }
            .summaryPax_btn{
                display: flex;
                gap:10px;
                margin-bottom: 10px;
                .summaryPax-count{
                    width: 55px;
                    height: 17px;
                    border-radius: 3px;
                    background: #86D7B0;
                    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
                    font-size: 12px;
                    color: #fff;
                    text-align: center;
                    margin-bottom: 0;
                }
            }
            .sumVehicle{
                width: 100%;
                padding-left: 20px;
                margin-bottom: 5px;
                background: url("../../images/vehicle_summary.svg") no-repeat 0 0;
                color: #4A4A4A;
            }
            .summDate{
                background: url("../../images/global-icon/calendar-grey.svg") no-repeat 0 0;
                width: 100%;
                padding-left: 20px;
            }
            
        }
    }
        }
}
}



////////////////////////// QUOTE BOOKING //////////////////////////////
.booking-inputs-container{
    background-image: url("../../images/background/coconut-full-opaque.svg"),url("../../images/background/cocktail-booking.svg"); 
    background-size: 32.5vmax,35.88541667vmax;
    background-repeat: no-repeat;
    background-position: -16.66666667vmax 559px,119% 100vh;
    min-height:100vh;
}
.quote_input_parent{
    width: 100%;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 50px 146px 50px 126px;
    display: grid;
    grid-template-columns: 550px auto;
    gap: 400px;
   .quote_input_left{
        .quote_section_title{
            color:#2A6E95;
            margin-bottom:42px;
            font-size: 18px;
            font-weight: 700;
            line-height: 24.3px;
            text-align: left;
        }
        .quote_left_head{
            color: #000;
            margin-bottom: 40px;
            .quote_left_headtxt0{
                font-size: 16px;
                color:#585858;
                font-weight: 400;
                text-decoration: underline;
                text-underline-offset: 4px;
                margin-bottom:11px;
                
            }
            .quote_left_headtxt1{
                color: #FFA75D;
                font-size: 24px;
                font-weight: 700;
                margin-bottom: 8px;
            }
            .quote_left_headtxt2{
                color: #0F7173;
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 39px;
                
            }
            .quote_left_date{
                display: grid;
                grid-template-columns: repeat(2,10.625rem);
                gap: 20px;
                position: relative;
                font-size: 14px;
                margin-bottom: 20px;
                .rmdp-border-top{
                    border:none;
                }
                .rmdp-container .rmdp-wrapper .rmdp-calendar .rmdp-header{
                    margin-top: 30px;
                }
                .rmdp-container{
                    // width: 10.625rem;
                    &:nth-child(2){
                        .Calendar_comp__parent{
                            &::before{
                                background-image: url("../../images/global-icon/departure.svg");
                            }
                        }
                    }
                }
                .rmdp-container .rmdp-wrapper .rmdp-calendar{

                    .rmdp-day-picker {
                        .rmdp-disabled span{
                         color: #F66666 !important;
                         

                     }
                   }
                }
                .Calendar_footer{
                    margin-top: 0px;
                }

                .Calendar_comp__parent{
                    background-image: none;
                    display: grid;
                    grid-template-columns: 28px auto;
                    grid-template-rows: auto auto;
                    column-gap: 13px;
                    padding-left: 19px;
                    width: 10.625rem;
                    height: 3.4375rem;
                    border-radius: 2rem;
                    border: 0.5px solid $myColor7;
                    background: #FAFAFA;
                    transition: background-color 0.3s ease;
                    
                    /* Card */
                    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
                    &::before{
                        @include generatePseudoElement("../../images/global-icon/arrival.svg",27px,28px);
                        grid-column: 1 / 2; /* Occupies the first column */
                        grid-row: 1 / 3;  
                        align-self: center;  /* Center vertically */
                        justify-self: center; /* Center horizontally */
                    }
                    *:nth-child(2){
                        grid-column: 2 / 3; /* Occupies the first column */
                        grid-row: 1 / 2;  
                    }
                    *:nth-child(2){
                        grid-column: 2 / 3; /* Occupies the first column */
                        grid-row: 2 / 3;  
                    }

                    &:hover{
                        background: #EDEAE0;
                    }

                    &:has(.date_added){
                        border: 2px solid $myColor7;
                        background: #FBFFF7;
                    }
                }
            }
            .quote_left_mandatory{
                color : #FFA75D;
                font-size: 16px;
                font-style: italic;
                font-weight: 500;
                line-height: 21.6px;

            }
            // div{
            //     margin-bottom: 8px;
            // }
            // div:nth-child(1){ 
            //     font-size: 20px;
            //     font-weight: 700;
            // }
            // div:nth-child(2){
            //     font-size: 15px;
            //     font-weight: 500;
            // }
        }
        .quote_input_paxform.disabled {
            opacity: 0.5;
            pointer-events: none;
          }
          
          .quote_input_paxform.disabled input,
          .quote_input_paxform.disabled textarea {
            background-color: #f0f0f0;
          }
        
        .quote_input_paxform{
            width: 585px;
            float: left;
            .quote_inputs{
                width: 100%;
                float: left;
                margin-bottom: 20px;
                
                &_h5{
                    color:#0F7173;
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 14px;
                    @include flexStart;
                    gap:8px;
                    @include remove-white-space;
                    margin-bottom: 17.5px;
                    &::before{
                        @include generatePseudoElement("../../images/global-icon/user-blue.svg",13px,14px);
                        margin-right: -3px;
                    }
                }
                
            
            .quote_inputboxes{
                display: grid;
                grid-template-rows: auto 1fr ;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 10px;

                &_first_name, &_surname, &_contact{
                    height: 52px;
                    .input{
                            color: #969696;
                            width: 289px;
                            border-radius: 25px;
                            border: .3px solid rgba(15, 113, 115, .5);
                            background: #FAFAFA;
                            height: 40px;
                            padding-left: 22px;
                            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
                            font-size: 14px;
                            font-family: Satoshi;
                            font-weight: 700;
                            line-height: 18.9px;
                            text-align: left;
                            padding: 5px 19px;
                            line-height: 40px;
                            height: 50px;

                            overflow: hidden;
                            /* Empêche l'affichage de la barre de défilement */
                            white-space: nowrap;
                            /* Empêche le retour à la ligne */
                            text-overflow: ellipsis;
                            /* Affiche "..." si le texte dépasse */
                            resize: none;
                                &:hover{
                                    background: #EDEAE0;
                                    transition: 300ms;
                                }
                                // &:focus-visible {
                                //     outline-offset: 0px;
                                // }

                                &.error{
                                    color: #666666 !important;
                                    background: #F6E6E6;
                                    border: .1px solid rgba(202, 47, 20, 0.5);
                                }
                                &.error::placeholder {
                                    color: #C16161 !important;
                                    /* Placeholder color when error */
                                    opacity: 1;
                                }
                                &.success{
                                    color: #666666;
                                    background: #FBFFF7;
                                    border: 2px solid #0F7173;
                                }
                                &.input::placeholder {
                                    // text-align: center;
                                    padding: 0px 0px;
                                    line-height: 40px;
                                }
                                @-moz-document url-prefix() {
                                    .input::placeholder {
                                        padding: 1px 10px;
                                        /* Valeur spécifique pour Firefox */
                                    }
                                }
                        }
                        .quote_inputboxes_first_name_star{
                            position: relative;
                            font-family: Satoshi;
                            font-size: 18px;
                            font-weight: 700;
                            line-height: 24.3px;
                            top: -40px;
                            left: 224px;
                            color:#FFA75D;
                        }
                        .quote_inputboxes_surname_star{
                            position: relative;
                            top: -40px;
                            left: 215px;
                            color:#FFA75D;
                        }
                        .quote_inputboxes_contact_star{
                            position: relative;
                            top: -30px;
                            left: 461px;
                            color:#FFA75D;
                        }
                    
                    }
                    &_surname{
                        position:relative;
                        right: 2px;
                    }
                .quote_inputboxes_contact,textarea{
                    grid-column: span 2;
                    font-family: Satoshi;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 18.9px;
                    text-align: left;
                    color: rgba(102, 102, 102, 1)
                }
                .quote_inputboxes_contact{
                    max-width: 554px;
                    position: relative;
                    .custom-input {
                        width: 100%;
                        // padding-left: 102px;
                        font-size: 14px;
                        line-height: 18.9px;
                        text-align: left;
                    }
                    .icon-container {
                        position: absolute;
                        left: 4px;
                        top: 40%;
                        transform: translateY(-50%);
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        background-color: #E8E8E8;
                        padding: 5px;
                        height: 34px;
                        border-top-left-radius: 25px;
                        border-bottom-left-radius: 25px;
                        border-top-right-radius: 3px;
                        border-bottom-right-radius: 3px;
                        box-shadow: 0 0 0 0.5px #0F7173;
                        border: none;

                        &::after {
                            @include generatePseudoElement("../../images/global-icon/arrow-down-grey.svg", 10px, 10px);
                        }

                        &.active .dropdown {
                            display: block;
                        }
                    }

                    .flag-icon {
                        width: 30px;
                        height: auto;
                        margin-top: 3px;
                    }

                    .country-code {
                        font-size: 1rem;
                        color: #333;
                        font-family: Satoshi;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 18.9px;
                        text-align: left;
                    }

                    .dropdown {
                        display: none;
                        position: absolute;
                        bottom: -131px;
                        left: 0;
                        background-color: #e7e7e7;
                        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
                        padding: 10px;
                        z-index: 100;
                    }

                    .dropdown-item {
                        display: flex;
                        align-items: center;
                        padding: 8px;
                        cursor: pointer;

                        img {
                            width: 20px;
                            height: 20px;
                            margin-right: 8px;
                        }

                        span {
                            font-size: 14px;
                        }
                    }
                }
                textarea{
                    height: 213px;
                    padding: 24px 21px;
                    width: 585px;
                    border-radius: 25px;
                    border: .3px solid rgba(15, 113, 115, .5);
                    background: #FAFAFA;
                    padding-left: 17px;
                    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
                    font-size: 14px;
                    resize: none;
                    &:hover{
                        background: #EDEAE0;
                        transition: 300ms;
                    }
                    &::placeholder{
                        position: relative;
                        line-height: normal;
                        // top:10px;
                    }

                }
                textarea:focus,
                input:focus {
                    outline: none;
                }
            }

        }
        .quote_inputs:not(:first-of-type){
            margin-top: 24px;
            position: relative;
        }
        }
        .quote_input_paxchoice{
            width: 585px;
            float: left;
            margin-bottom: 70px;
            .quote_inputs{
                width: 100%;
                float: left;
                margin-bottom: 20px;
                
                &_h5{
                    color:#0F7173;
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 14px;
                    @include flexStart;
                    gap:8px;
                    @include remove-white-space;
                    margin-bottom: 17.5px;
                    &::before{
                        @include generatePseudoElement("../../images/global-icon/user-blue.svg",13px,14px);
                        margin-right: -3px;
                    }
                }
                
            
        }
        .selectDropdown-pax{
            max-width: 241px;
        }

        }
        .client-button{
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            .confirm_btn{
                background-color: #71E28A;
                color:#fff;
                border-radius: 15px;
                border: unset;
                height: 35px;
                width: 200px;
            }
        }
        
    }
    .quote_input_right{
        position: relative;
        .booking_summary_parent .summary_loopblk .summaryData{
           
            &-text.person-icon{
                    background: url("../../images/global-icon/user-grey.svg") no-repeat 0px center;
                    padding-left: 20px;
                    background-position-y: 4px;
                    font-weight: 600;
                    color: #4A4A4A;
                    margin-bottom: 8px;
                
            }
            &-text.meal-plan-icon{
                background: url("../../images/global-icon/cutlery-grey.svg") no-repeat 0px center;
                padding-left: 16px;
            }

        }
    }
    
}

/////////////////////// TABLE QUOTATION ///////////////////////////////

.table-quotation-parent{
    table{
            width: 100%;
            // float: left;
            position: relative;
            font-size: 14px ;
            font-weight: 400;
            border-collapse: separate;
            border-spacing: 0px $gridRowSpacing;
            margin-top: -$gridRowSpacing;
        tbody{
            transform: translateY(21px);
        }
            
        .date-cell{
            &::before{
                transform: translateY(0.5px);
                margin-right: 9px;
                @include generatePseudoElement("../../images/global-icon/calendar-grey.svg",11px,12px);
            }
        }

        .arrow-cell{
            img{
                position: relative;
                left: 16px;
            }
        }

        .three-dotted-container{
            position: relative;
            // width: 100%;
            // height: 100%;
            
            .three-dotted {
                transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
                transition-delay: 0.1s;
            }
        
            .three-dotted-container:hover .three-dotted {
                transform: scale(1.1);
                opacity: 0.9;
            }

            .toast-error{
                min-width: 276px;
                color: #F66666;
                font-weight: 400;
                border: 1px solid red;
                $borderLeftThickness:8px;
                border-left: $borderLeftThickness solid ;
                position: absolute;
                right: 0;
                top: 0;
                background-color: #FFF;
                box-shadow:0px 1px 6.7px rgba(0, 0, 0, 0.19);
                padding: 8px 14px 8px calc(16px - $borderLeftThickness);
                border-radius: 5px;
                @include flexStart;
                gap: 8px;
                transform: translateY(-30px);
                &::before{
                    flex: 0 0 auto;
                    @include generatePseudoElement("../../images/global-icon/alert-sign.svg",15px,15px)
                }
            }
    
            .dropdown-container{
                min-width: 173px;
                color: #877E7E;
                font-size: 18px;
                font-weight: 400;
                position: absolute;
                display: flex;
                padding: 16px 9px;
                flex-direction: column;
                align-items: flex-start;
                gap: 14px;
                border-radius: 7px;
                background: #FFF;
                box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
                margin-top: 8px;
                right:0;
                z-index: 1;

                &>div{
                    padding-left: 13.5px;
                    width: 100%;
                    cursor: pointer;
                    &:hover{
                        border-radius: 7px;
                        background: $myColor8;
                        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
                        transition: 300ms;
                    }

                    &.disabled{
                        opacity: 0.37;
                        cursor: default;
                        &:hover{
                            border-radius: none;
                            background: none;
                            box-shadow: none;
                        }
                    }
                }
            }
        }
           
        .table-quote-header{
            border-radius: 9px;
            // background: #FFF1E6;
            // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
            // background-image: url("../../images/thead-background.png");
            // background-size: 100% 100%;
            width: 100%;
            height: 52px;
            border-radius: 9px;
            border: 1px solid #CEC8C3;
            background: #FFF1E6;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
            .table-quote-header-row
            {
                color: #585858;
                font-weight: 700;
            }
            .table-quote-header-row > td:nth-child(1){
                border-radius:9px 0px 0px 9px;
                padding-left: 31px;
            }
          
            .table-quote-header-row > td:nth-child(6){
                background: url("../../images/quotation_arrow.png")no-repeat 0 ;
                width: 50px;
                background-position-x: 16px;
            }
            .table-quote-header-row > td:nth-child(10){
                border-radius:0px 9px 9px 0px;
            }
            .table-quote-header-row{
                td:nth-child(2){
                    padding-left: 36px;
                }
                td:nth-child(3){
                    padding-left: 12px;
                }
                td:nth-child(4){
                    padding-left: 21px;
                }
                td:nth-child(5){
                    padding-left: 13px;
                }
                td:nth-child(8){
                    width: 448px;
                    padding-left: 36px;

                }
            }
           
        }
        .table-quote-body{
            hr{
                margin:0;
                stroke-width: 1px;
                stroke: #8E8E8E;
            }
            .quotation-row{
                height: 73px;
                // border-bottom: 1px solid #8E8E8E;  
                color: #585858;
                cursor: pointer;
                td{
                    height: 73px;
                }
                &:hover{
                    td{
                        background: #E7E5E5;
                        transition: background-color 0.2s ease-in-out;
                        &:first-child{
                            border-radius: 15px 0 0 15px;
                        }
                        &:last-child{
                            border-radius: 0 15px 15px 0;
                        }
                    }
                }

            }
            .action-column{
                display: flex;
                align-items: center;
                height: 97px;
                gap: 10px;
                justify-content: flex-end;
            }
            .hr-container{
                height: unset;
            }
            tr > .file-nb{
                padding-left: 31px;
                @include id-link;
            }
            tr:nth-child(1){
                margin-top: 38px;
            }
            tr{
                td:nth-child(2){
                    padding-left: 20px;
                }
                td:nth-child(3){
                padding-left: 12px;
                }
                td:nth-child(4){
                    padding-left: 21px;
                }
                td:nth-child(5){
                    padding-left: 10px;
                    width: 111px; 
                }
            }
            tr:last-child {
                border-bottom: none;
            }
            /* tr > td:nth-child(5), tr > td:nth-child(7){
                background-image: url("../../images/global-icon/calendar-grey.svg");
                background-repeat: no-repeat;
                background-position: 30px center;
                padding-left: 50px;
                background-size: 15px;
            } */

            .pax-details{
                padding-left: 26px;
                
                &>div{
                    // display: inline-block;
                    margin: 12px 10px; /* Gap between elements */
                    width: fit-content;
                    padding: 1px 11px 1px 0;
                    border-radius: 13px;
                }
                .paxselected{
                    border: 1px solid #0F7173;
                    background: #0F7173;
                    color: #fff;
                }
                .paxunselected{
                    border: 1px solid #0F7173;
                    background: #FFF;
                    color: #0F7173;
                }
            }
                
            
            .statuscell{
                cursor: pointer;
                width: 99px;
                height: 24px;
                @include flexCenter;
                border-radius: 8px;
                text-align: center;
                box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
                color:#fff;
            }
            .status_free{background: #5E7C5C;}
            .status_req{background: #FFD84C;color:#624E06}
            .status_payment{background: #3252A4;}
            
            .status_free1{background: #379193;}
            .status_req1{background: #379193;color:#624E06;
                &:hover {
                    background: #28686A;
                    transition: 300ms;
                    }
            }
            .status_payment1{background: #10494B;}
            .quote-btn{
                width: 159px;
                height: 37px;
                @include flexCenter;
                border-radius: 24px;
               color:#fff;
               cursor: pointer;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
            }
        }
    }
    

    
}


.pagination {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 46px;
    float: right;
    align-items: center;
    span{
        margin-inline: 5px;
        margin-top: 9px;
        cursor: pointer;
    }
    .arrow-next, .arrow-prev{
        width: 11px;
        height: 11px;
        cursor: pointer;
    }
    .arrow-next{
        margin-left: 9px;
    }
    .arrow-prev{
        margin-right: 9px;
        transform: rotate(-180deg);
    }

}

.pagination button,button.active {
    margin: 0 5px;
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    width: fit-content;
    min-width: 25px;
    height: 25px;
    padding: 3px 5px;
    background-color: #f0f0f0;
    font-family: Satoshi;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    &:hover{
        transition: transform 0.3s ease, background-color 0.3s ease;
        background-color: #16a8aa42;
    }
}

.pagination button.active {
    background-color: #0F7173;
    border-radius: 3px;
    color: white;
}
 .icon_summary {
    margin-left: 6px;
    width: 11px;
    height: 11px;
 }
 .arrow{
    margin-left: 8px;
    width: 10px;
    height: 10px;
 }
 .locationFrom{
    font-family: Satoshi;
    font-size: 16px;
    font-weight: 500;
    line-height: 21.6px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #4A4A4A;
 }
.toast-error {
    max-width: 276px;
    color: #F66666;
    font-weight: 400;
    border: 1px solid red;
    $borderLeftThickness: 8px;
    border-left: $borderLeftThickness solid;
    position: absolute;
    right: 9px;
    top: 112px;
    background-color: #FFF;
    box-shadow: 0px 1px 6.7px rgba(0, 0, 0, 0.19);
    padding: 8px 14px 8px calc(16px - $borderLeftThickness);
    border-radius: 5px;
    @include flexStart;
    gap: 8px;
    transform: translate(-169px,-30px);
    z-index: 2;
    &::before {
        flex: 0 0 auto;
        @include generatePseudoElement("../../images/global-icon/alert-sign.svg", 15px, 15px)
    }

    &.unsaved-quotation{
       position: relative; 
       left: 145px;
        top: 88px;
        max-width: 309px;
    }
    &.unsaved-quotation-docket {
        position: relative;
        left: -222px;
        top: 153px;
        max-width: 309px;
    }
}
.inside-image-trash{
    position:absolute;
    .toast-error {
        min-width: 276px;
        color: #F66666;
        font-weight: 400;
        border: 1px solid red;
        $borderLeftThickness: 8px;
        border-left: $borderLeftThickness solid;
        position: relative;
        right: 290px;
        top: -7px;
        opacity: 0.8;
        background-color: #FFF;
        box-shadow: 0px 1px 6.7px rgba(0, 0, 0, 0.19);
        padding: 8px 14px 8px calc(16px - $borderLeftThickness);
        border-radius: 5px;
        @include flexStart;
        gap: 8px;
        transform: translateY(-30px);

        &::before {
            flex: 0 0 auto;
            @include generatePseudoElement("../../images/global-icon/alert-sign.svg", 15px, 15px)
        }
    }
}