.mapbox_parent{
    position: relative;
    // margin-top: 90px;
    font-family: Satoshi;
    .map_container{
        @include popup;
        z-index: 200;
        //opacity: 1;
        // confirmed by adrian
        &::before{
            content:"";
            background: #000;
            width: 100%;
            height: 100vw;
            position: absolute;
            left: 0;
            opacity: 60%;
        }
        &>div
        {
            .mapHeader{
            width: 100% ;
            height: 72px;
            background-color: #fff;
            padding: 0 44px 0 43px;
            display: flex;
            justify-content: space-between;
            border-radius: $mapBorderRadius $mapBorderRadius 0px 0px;
            align-items: center;
            position: relative;
            .headerName{
                color: #797979;
                font-size: 18px;
                font-weight: 700;
                span{
                    color: #797979;
                    font-weight: 700; 
                    margin-left: 8px;
                    padding-left: 6px;
                    border-left: 2px solid #859B99;
                }
            }
            .closeBtn{
                cursor: pointer;
                text-decoration: underline;
                text-underline-offset: 3px;
                text-decoration-thickness: 2px;
                color: #434343;
                font-size: 16px;
                font-weight: 400;
            }
            }
            .map_container_popup{
                position: absolute;
                top: 0;
                left: 0;
                width: 387px;
                height: 786px;
                border-radius: 18px;
                background: #F9F8F8;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                margin: 29px 0 0 33px;
                
                padding: 14px 14px 45.5px;
            }
            &:not(.amenities_list ){
                &>div:nth-child(2):not(.rating_location){
                    border-radius: 0 0 $mapBorderRadius $mapBorderRadius;
                        &>div>.gm-style:nth-child(3){
                            // height: auto!important;
                            &>div:nth-last-child(2),&>div:last-child{
                                &>div:first-child{
                                    right:calc($mapBorderRadius + 40px)!important;
                                    bottom:3%!important;
                                    &>div>div{
                                        height:auto!important;
                                    }
                                }
                            }
                        }
                }
            }
        }
       .customIcon{
        width: fit-content;
        background-color: #fff;
        transition: background-color 300ms ease-out 120ms;
         white-space: nowrap;
         color:#0F7173;
         border:0.5px solid #0F7173;
         font-size: 16px;
         font-weight: 600;   // as per malikah discussion
         padding: 7px 10px;
         border-radius: 7px;
         z-index: 100;
         &.hotel-name-label{
            max-width: 200px;
            height:40px;
            overflow-x: hidden;
            text-wrap: nowrap;
            color: transparent;
            background: linear-gradient(270deg, rgba(255, 255, 255, 0) 5.44%, #0F7173 32.31%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            &::before{
                content:"";
                position: absolute;
                height: 100%;
                width: 100%;
                background-color:#fff;
                inset: 0 auto auto 0;
                z-index: -1;
                border-radius: inherit;
            }
         }
         &:hover{
            background-color: #E7E5E5;
         }
       }
       .selectIcon{
        background: #0F7173;;
        color:#fff
       }
       .maphotel-Popup{
        position: absolute;
        display: grid;
        width: 921px;
        z-index: 90;
        bottom: 3.5%;
        .img-hotel-card{
            width: 249px;
            height: 263px;
        }

       }
       .navbtn-head{
            display: flex;
            justify-content: center;
            position: absolute;
            bottom: 50px;
            width: auto;
            color: #434343;
            font-size: 18px;
            font-weight: 700;
        .navBtn{
            width: 639px;
            height: 67px;
            border-radius: 18px;
            background: #F9F8F8;
            box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
            @include flexCenter
           }
       }
       .map-types{
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1;
        width: 160px;
        display: flex;
        gap: 10px;
    }
    }

}

// mappopup_parent
.mappopup_parent{
    .mappopup_cntblock{
        float: left;
    padding-left: 21px;
    margin-top: 37px;
    }
    .trackexcursion_map{
        width: 100%;
        float: left;
        overflow: auto;
    height: 200px;
    }
}

.gmnoprint{
    &.gm-style-cc{
        background-color: rgb(245, 245, 245,0.7)!important;
    }
    div:has(>button[aria-label="Zoom in"]){
        background-color: transparent!important;

        user-select: none;
        box-shadow: none!important;
        border-radius: 0!important;
        cursor: default!important;
        // background-color: rgb(255, 255, 255);
        width: auto!important;
        height: auto!important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
        position: absolute;
        right: 18px;
        bottom: calc(95px - 69px - 62px);


        .gm-control-active{
            width: 32px!important;
            height: 32px!important;
            flex-shrink: 0;
            border-radius: 6px;
            background: #F9F8F8!important;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
            margin-bottom: 5px;
            transition: background 0.2s ease;
            display: flex!important;
            justify-content: center;
            align-items: center;
            &:hover{
                background: #E7E5E5!important;
            }
            img{
                display: none;
            }
            &:first-child{
                background-image: url("../../images/map/mapZoomIn.svg")!important;
                background-repeat: no-repeat!important;
                background-position: center!important;
            }
            &:last-child{
                background-image: url("../../images/map/mapZoomOut.svg")!important;
                background-repeat: no-repeat!important;
                background-position: center!important;
                /* &::before{
                    content:"-";
                    color: #FFA75D;
                    font-family: Satoshi;
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-align: center;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    height: 100%;
                    // position: absolute;
                    transform: translateY(-2px);
                } */
            }
        }

        div{
            display: none!important;
        }
    }
}

img[alt="Google"]{
    bottom: 70%!important;
    top: auto!important;
    left:58px!important;
}