
.cancelation_policy_parent{
    position: relative;
    font-family: Satoshi;
    float: left;
    width: 100%;
    .cancelation_partxt{
        margin-bottom: 34px;
        color: $paraHeadColor;
        font-size: 22px;
        font-weight: 700;
    }
    .cancelation_maincnt{
        border: 0.5px solid #FFAB63;
        background: #FCFCFC;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        padding: 59px 52px;
        width: 100%;
        @include flexStart;
        flex-direction: column;
        gap:42px;
        max-height: 28.4375rem;
        overflow-y: hidden;
        border-radius: 1rem;
        position: relative;
        transition: max-height 0.3s ease;

        &::before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            pointer-events: none; /* Allows interactions with content */
            background: linear-gradient(to bottom, transparent 50%, white 100%);
        }

        &.show-more{
            max-height: 200rem;

            &::before{
                display: none;
            }
        }

        i:last-child{
            display: none;
        }
        .cancelation_policy_data{
        
            font-size: 14px;
            margin-bottom: 20px;
        
            .cancelation_hdtxt{
                color: #FFA75D;
                font-weight: 700;
            }
            .cancelation_txt{
                color: $paraColor;
                font-weight: 400;
            }
        }
    }
    .button-policy{
        @include flexCenter;
        padding: 12px 21px;
        gap: 9px;
        border-radius: 0.9375rem;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        float: right;
        position: relative;
        background: inherit;
        margin-top: 30px;
        font-weight: 500;
        &.green{
            color: $myColor7;
            border: 2px solid $myColor7;
            transition: background-color 0.3s ease;
            &::after{
                @include generatePseudoElement("../../images/global-icon/arrow-down-green.svg",14px,14px)
            }
    
            &:hover{
                border: 2px solid #0A4E50;
                background: rgba(10, 78, 80, 0.20);
            }
    
        }

        &.orange{
            color: #F5A477;
            border: 2px solid #F5A477;
            transition: background-color 0.3s ease;
            &::after{
                @include generatePseudoElement("../../images/global-icon/arrow-up-orange.svg",14px,14px)
            }
    
            &:hover{
                border: 2px solid #D68C63;
                background: rgba(214, 140, 99, 0.20);
            }

        }
    }
}
.icon-dashed-line{
    height: 2px;
    width: 100%;
 @include generateIconClass("../../images/global-icon/dashed-line.svg",2px,100%)
}
.cancellation-item-container{
    width:100%;
    @include flexStart;
    align-items: flex-start;
    flex-direction: column;
    gap:35px;
    margin-top: 35px;
    .cancellation-header{
        width: inherit;
        color:$myColor7;
        @include flexSpace;
        align-items: center;
    }

    .cancellation-title{
        @include line-name;
        font-size: 16px;
        border-left-width: 3px;
        @include flexStart;
        height: 1.13em;
    }

    .room-item{
        width: fit-content;
        border-radius: 7px;
        border: 1px solid $orange-color;
        background: #FFF;
        color: $myColor7;
        font-size: 14px;
        font-weight: 700;
        padding: 10px 21px 10px 16px;
        @include flexCenter;
        gap: 10px;
        &::before{
            @include generatePseudoElement("../../images/global-icon/bed-green.svg",11px,12px);
        }
    }

    .cancellation-description{
        color: $paraColor;
        font-size: 14px;
        font-weight: 700;
    }

    .cancellation-validity-container{
        @include flexSpace;
        align-items: center;
        gap: 18px;
        .cancellation-date-container{
            @include flexCenter;
            padding: 10px 21px 10px 16px;
            gap: 10px;
            border-radius: 28px;
            border: 1px solid $myColor7;
            background: #FFF;

            .arrow{
                @include generateIconClass("../../images/global-icon/next-arrow-green.svg",10px,10px);
            }

            .date-item{
                @include flexStart;
                gap:7px;
                color: #434343;
                &::before{
                    @include generatePseudoElement('../../images/global-icon/calendar-green.svg',13px,13px)
                }
            }
        }
    }
    .cancellation-rooms{
        @include flexStart;
        flex-wrap: wrap;
        gap:13px;
        &:empty{
            display: none;
        }
    }
}
.newcancellation-popup-title{
    font-family: Satoshi;
    font-weight: 700;
    font-size: 22px;
    line-height: 29.7px;
    letter-spacing: 0%;
    color: #434343;
}
.cancellation-popup{
    position: fixed;
    border-radius: 22px;
    width: 1127px;
    flex-shrink: 0;
    // padding: 20px;

    &>.cancellation-popup-title{
        color: #FFF;
        font-size: 18px;
        font-weight: 700;
        margin: 0;
    }

    .cancellation-popup-container{
        width: 100%;
        padding: 38px 29.5px 52px 37px;
        border-radius: 16px;
        border: 0.5px solid #FFAB63;
        background: #FCFCFC;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        // max-height: 66.142vh;
        margin: 34px 0;
        // overflow-y: auto;
        
        .concellation-item-container{
            max-height: calc(66.142vh - 104px);
            overflow-y: auto;
            @include flexStart;
            flex-direction: column;
            gap:42px;
            padding-right: 29.5px;
            padding-left: 15px;
            /* @supports (scrollbar-gutter: stable) {
                & {
                    scrollbar-gutter: stable both-edges;
                    padding-right: 10px;
                }
            } */
            &::-webkit-scrollbar {
                width: 7px;
                height: 250px;
                // visibility: hidden;
                // padding-left: 28px;
            }
            
            &::-webkit-scrollbar-track {
                border-radius: 4px;
                background: #EBEBEB;
            }
            
            &::-webkit-scrollbar-thumb {
                width: 7px;
                height: 79px;
                border-radius: 4px;
                background: #D0D0D0;
                // box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
            }
        }
        i:last-child{
            display: none;
        }  
    }

    .cancellation-validation{
        position: relative;
        @include flexEnd;
        gap:34px;
        top: 20px;
        .validate-button{
            @include button-success;
            padding:5px 32px;
        }

        .input-row-cancellation{
            @include flexCenter;
            gap:15px;
            input[type="checkbox"] {
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                @include flexCenter;
                width: 18px;
                height: 19px;
                width: 18px;
                height: 19.059px;
                padding: 3px 1px;
                flex-direction: column;
                gap: 10px;
                flex-shrink: 0;
                border-radius: 2px;
                background: #E7E7E7;
                transition: background-color 0.3s, border-color 0.3s;
                cursor: pointer;
                
                &:checked {
                  
                  &::before {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: url("../../images/global-icon/check-green.svg") no-repeat;
                    background-size: cover;
                  }
                }
            
                &:hover {
                  background: #E2F2F2;
                }
              }
        }

        .label-acceptance{
            font-family: Satoshi;
            font-weight: 500;
            font-size: 18px;
            line-height: 24.3px;
            color: #434343;
        }
    }

    .cancellation-error-message{
        color: #FFB2B2;
        font-size: 18px;
        font-weight: 700;
        @include flexEnd;
        gap:15px;
        // margin-top: 37px;
        margin:0;
        bottom: -37px;
        right:0;
        position:absolute;
        // &:not(:empty){
            &::before{
                @include generatePseudoElement("../../images/global-icon/warning-red.svg",19px,18px);
            }
        // }
    }
}