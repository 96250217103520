.navContainer{
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 99;

}

.navbar-head{
    @include float_width;
    height: $navbarHeight;
background: rgba(255, 255, 255, 0.96);
box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
backdrop-filter: blur(33.5px);
position: sticky;
position: -webkit-sticky;
top:0;
z-index:120;
}


.navbody2 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    ul {
        list-style-type: none;
        display: flex;
        padding-left: 0;
        padding-right: 57px;
        margin-bottom: 0;
        li {
            color: #797979;
            font-family: Satoshi;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            cursor: pointer;
            display: flex;
            align-items: center;
            height: 23px;
            position: relative;
            &:hover {
                color: #F1B989;
                transition: 300ms;
                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -28px;
                    height: 3px;
                    background-color: #F1B989;
                    /* transform: scaleX(0); */
                    transform-origin: center;
                    transition: transform 300ms ease;
                }
            }
            &:nth-child(1) {
                margin-right: 34px;
            }
            &:nth-child(2) {
                margin-right: 40px;
            }
            &:nth-child(3) {
                margin-right: 40px;
            }
            &:nth-child(4) {
                margin-right: 40px;
            }
            &:nth-child(5) {
                border-radius: 11px;
                color: rgba(15, 113, 115, 1);
                padding: 6px 15px 7px 16px;
                color: #0F7173;
            
                border-radius: 11px;
                border: 1px solid #0F7173;
                background: #FFF;
                box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.25);
                
                font-family: Satoshi;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                &:hover {
                    background-color: rgba(15, 113, 115, 1);
                    color: #ffffff;
                    transition: 300ms;
                    &::after {
                        content: none;
                    }
                }
            }
        }

        .border-bottom {
            font-weight: 700;
            color: #FFA75D;
            border-bottom: none !important;
            &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -28px;
                    height: 3px;
                    background-color: #F1B989;
                    /* transform: scaleX(0); */
                    transform-origin: center;
                    transition: transform 300ms ease;
                }
        }
    }
}

.chatbtn{
    padding: 2px 2px;
    box-sizing: border-box;
    width: 93px;
    height: 33px;
    border-radius: 11px;
    border: 1px solid #0F7173;
    background: #FFF;
    box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.25); 
}

.chatbtn_disable{
    opacity: 0.5;
   
}

.chatbtn_enable{
    opacity: 1;
   
}

.navbar-body-container{
    @include float_width;
    display: grid;
    align-items: center;
    height: 78px;
    padding: 0px 10px 0 124px;
    box-sizing: border-box;
    // grid-template-columns: 40% 35% 25%
    grid-template-columns: 20% 50% 30%;

    .navbody1{
        position: relative;
        cursor: pointer;
        img{width: 175px;}
        // as per patrick approval we removed the beta icon
        // &::before {
        //     content: "";
        //     position: absolute;
        //     top: -12px;
        //     left: -30px;
        //     width: 50px;
        //     height: 50px;
        //     background: url("../../images/beta-img.png") no-repeat center center;
        //     background-size: 40px;
        //     z-index: 1;
        //     transform: rotate(626deg);
        // }

    }

    
}


.navbody3 img {
cursor: pointer;
}

.navbody3 div:nth-child(2):hover{
// border:2px solid red;
//     border-radius:50%    ;
//     box-sizing: border-box;
}
// .navbody3 img:hover {
   
//     }

.navbody3{
    height: 55px;
    border-left: 1px solid #C8C8C8;
    display: flex;
    // justify-content: flex-start;
    justify-content: space-between;
    align-items: center;
    // gap: 70px;
    color: #000;
    font-size: 14px;
    padding-left: 13px;
    margin-right: 70px;
    button{
        // background-image: url("../../images/svg/logout.svg") no-repeat 10px center;
        border: none;
        border-radius: 23px;
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.21);
        width: 100px;
        height: 36px;
        font-size: 16px;
        
    }
}

.login-popup-container{
    position: absolute;
    position: absolute;
    width: 400px;
    padding: 20px;
    right: 95px;
    top: 60px;
}

.login-block {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  
    .background-layer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: 0 0;
      background-repeat: no-repeat;
      opacity: 0;
      transition: opacity 1s ease-in-out;
      z-index: 0;
  
      &.visible {
        opacity: 1;
        z-index: 1;
      }
    }
  }
  

// 
.login-logo {
    background: url("../../images/mautourco-logo-white.png") no-repeat 0 0;
    width: 100%;
    height: 53px;
    background-size: 255px;
    margin-bottom: 20px;
    position: relative;
// as per patrick approval we removed the beta icon
    // &::before {
    //     content: "";
    //     position: absolute;
    //     top: -11px;
    //     left: -28px;
    //     width: 50px;
    //     height: 50px;
    //     background: url("../../images/beta-img.png") no-repeat center center;
    //     background-size: cover;
    //     z-index: 1;
    //     transform: rotate(626deg);
    // }
}

.login-left{
    width: 30%;
    height: 100vh;
    backdrop-filter: blur(8px); /* Adjust the blur radius as needed */
    position: relative;
    z-index: 2;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 250ms ease-out;
}
.formbox{
width: 353px;
color:#fff;
}
.log-hdtxt{
font-weight: 500;
font-size: 18px;
margin-bottom: 50px;
}

.logErrorMsg{
    display: grid;
    grid-template-columns: 56px 260px;
    width: 100%;
    padding-left: 110px;
    margin-top: 30px;
    color: #BB5050;
}

.log-errimg{
    background: #BB5050 url("../../images/information2.png")no-repeat center;
    background-size: 25px;
}
.log-err-box{
    padding: 8px;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 12px;
    background: #fff url("../../images/closeLog.png") no-repeat 95% center;
    background-size: 13px;
    label{
        font-weight: 800;
        
    }
}

.loginput{
    width: 353px;
    height: 53px;
    border-radius: 14px;
    background-color: #fff;
    padding: 10px 15px;
    outline: unset;
    border:none;
    margin-bottom: 20px;
}
.error-input{
    border:2px solid #BB5050;
}
.error-input::placeholder{
    color:#BB5050;
}
.loginbtn{
    width: 102px;
    border-radius: 13px;
    font-size: 16px;
    display: grid;
    place-items: center;
}
.not-sel-carosel{
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #D9D9D9;
    cursor: pointer;
}
.sel-carosel{
    @extend .not-sel-carosel;
    width: 43px;
    border-radius: 16px
}
.carosel-dots{
    display: flex;
    gap: 5px;
    position: absolute;
    bottom: 50px;
    right: 50px;
  z-index: 3; /* Ensures dots are above backgrounds */

}

.css-ha3bif-MuiTimelineItem-root::before {
    content: "";
    -webkit-flex: unset !important ;
    -ms-flex: 1;
    flex: unset !important;
    padding: 0px !important;
}

.css-55vwbm-MuiTimeline-root {
    margin-bottom: 0px !important;
    padding: 0px 0px !important;
}

.css-v6mo1y-MuiTimelineDot-root {
    margin: 0 !important;
    width: 16px;
    height: 16px;
    padding: 0 !important;
}

.css-1ami7lv-MuiTimelineConnector-root {
    width: 4px !important;
}
.css-18y2uza-MuiTypography-root-MuiTimelineContent-root:nth-child(2) {
    // padding: 0px 0px 0px !important; // if u r chnaging means it will affect the docket quotation body classname padding 
    padding: 0px 0px 0px 4px !important;
}
.css-18y2uza-MuiTypography-root-MuiTimelineContent-root:nth-child(1) {
    padding: 0px 16px 15px;
}
.css-18y2uza-MuiTypography-root-MuiTimelineContent-root {
    line-height: 1 !important;
}


.dropdown-menu.show{
    position: absolute;
    inset: unset !important;
    margin: 0px;
    transform: translate(0px, 0px) !important;
    background: none;
    border: none;
    padding: 0;
}

.dropdown-toggle::after {
    display: unset;
    margin-left: 0;
    content: "";
    border:none !important;
}

.logoutPop{
    background-color: #fff;
    padding: 10px;
    @include flexCenter
}

.choice-hotel-list-container{
    width:543px!important;
    padding:31px 23px 18px 23px;
    height: 100%!important;
    display: table;
}

.choice-hotel-list{
    overflow:hidden;
    height: 100%;

    .OptionBox:last-child{
        border-bottom: none;
        padding-bottom: 0;
    }
}