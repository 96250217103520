@import "./variables.scss";
@import "./mixin.scss";
@import "./navgationBar.scss";
@import "./cotation.scss";
@import "./quotation.scss";
@import "./loading.scss";
@import "./excursion.scss";
@import "./accomodation.scss";
@import "./map.scss";
@import "./minicomponent.scss";
@import "./rework.scss";
@import "./paxdropdown.scss";
@import "./calendar.scss";
@import "./errorPage.scss";
@import "./booking.scss";
@import "./policy.scss";
@import "./slider.scss";

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Vithkuqi&family=REM:wght@800&display=swap");

@font-face {
  font-family: Satoshi;
  src: url("../../../../public/fonts/Satoshi-Regular.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@font-face {
  font-family: Satoshi-Bold;
  src: url("../../../../public/fonts/Satoshi-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@font-face {
  font-family: Satoshi-Medium;
  src: url("../../../../public/fonts/Satoshi-Medium.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

*{
  font-family: Satoshi;
  font-style: normal;
  line-height: normal;
}

body::-webkit-scrollbar {
}

.quotation-map-parent {
  transition: opacity 0.3s ease;

 
}

.disable {
  opacity: 0.5;
  pointer-events: none;
}
.paxNumber2{
  text-align: right;
  margin-right: 6px;
}
.statusRoom{
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 35px;
      // top: 350px;
      bottom: 3.5vh;
      width: 126px;
      height: 26px;
      // background: green;
      color: #fff;
      text-align: center;
      border-radius: 12px;
      border: 0.5px solid white;
      box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.14);
      -webkit-backdrop-filter: blur(2px);
      backdrop-filter: blur(2px);
}
.hide{
  display: none !important;
}
.disable{
  opacity: 0.5;
  pointer-events: none;
}
.disable2{opacity: 0.5;}


.HeadParent {
  width: calc(100% - 190px);
  position: absolute;
  left: 190px;
  top: 150px;
}
.autoHeight{
  // height: auto !important;
  max-height: 490px;
}
.textHeader1 {
  color: $myColor1;
  font-family: "REM", sans-serif;
  font-size: 62px;
  padding-bottom: 15px;
}

.input_container_parent{
  width: 100%;
  position: relative;
  font-family: Satoshi;
  float: left;

}
.infoVehiculeList {
  width: 349px;
  height: 31px;
  // gap: 10px;
  font-family: Satoshi;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 20.25px;
  letter-spacing: 0%;
  color: rgba(246, 102, 102, 1) !important;
  //opacity: 0px;
}
.visitedTransfer{
  display: flex;
  .containerInfo{
    display: flex;
    align-items: center;
    margin-left: 28px;
    margin-top: -15px;
    gap: 8.5px;
      // img{
      //     width: 15px;
      //     height: 16px;
      //   margin-top: 5px;
      // }
      
    }
}
.textHeader2 {
  width: 672px;
  height: 43px;
  font-size: 32px;
  font-weight: 400;
  line-height: 43px;
  letter-spacing: 0em;
  text-align: left;
  color: $myColor2;
}
.hotel_btnblk{
  .exprice{
      color: #0F7173;
      font-family: Satoshi;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
  }
  .paxNumber{
    @include pax-number;
    color:$paraHeadColor;
      // margin-top: 2px;
      // color: #434343;
      // font-family: Satoshi;
      // font-size: 14px;
      // font-style: normal;
      // font-weight: 400;
      // line-height: normal;
      // text-align: right;
  }
}
.noclick{
  pointer-events: none
}

// .back_items{
//   cursor: pointer;
// }

.inputGroup {
  @include inputGroupStyles;
  color: $myColor4;
}

.select-container {
  width:100%;
  position: relative;
  float: left;
  // cursor: pointer;

  &.same-vehicule{
    max-width: 382px;
    height: 62px;
    position: relative;
    bottom: 15px;
    cursor: pointer;
  }
}




.selectbox {
  width: 422px;
  height: 57px;
  border-radius: 18px;
  border: none;
  box-shadow: 0px 2px 7px -1px rgba(0, 0, 0, 0.25);
  position: relative;
  @include flexStart;
  padding-left: 20px;
  box-sizing: border-box;
  cursor: pointer;
  color: #9f9f9f;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  background-repeat: no-repeat;
  background-position: 380px 15px;
  background-color: #f4f4f4;
}

.selectbox:focus {
  outline: none !important;
  background-repeat: no-repeat;
  //background-position: 380px 15px;
  //background-color: #f4f4f4;
  //color: black;
}
.selectboxDiv::before{
content:"";
    background-image: url("../../images/down1.png");
    background-repeat: no-repeat;
    width: 12.5px;
    height: 12.5px;
    background-position: 0 0;
    position: absolute;
    right: 24.5px;
    transform: rotate(360deg);
    transition: 0.3s;
}

.selectedbox::before{
 content:"";
  background-image: url("../../images/whiteArrow.png");
    background-repeat: no-repeat;
    width: 12.5px;
    height: 12.5px;
    background-position: 0 0;
    position: absolute;
    right: 24.5px;
    transform: rotate(180deg);
    transition: 0.3s;
}

.selectbox:hover {
  background-color: #f1ebdc ;
  transition: 0.3s
}


.dateBox:hover {
  background-color: #f1ebdc;
  cursor: pointer;
  transition: 0.3s
}

.optionBoxDta:hover {
  background-color: #F3F1EB;
  border-radius: 10px;
  transition: 0.3s
}

.selectbox-dropdown {
  @include selectDropdown($selectWidth);
  border-radius: $selectBorderRadius;
  background: $myColor6;
  top: 72px;
  text-align: left;
  padding: 25px 23px 18px 22px;
  box-sizing: border-box;
  color: #646464;
  box-shadow: 0px 2px 7px -1px rgba(0, 0, 0, 0.25);
  z-index: +1;
  width: 100%;
height: auto;
  transition: opacity 0.9s ease-in-out;
 
}
.containerPrice{
  display:flex;
  justify-content: space-between;
}
.docket-price{
  background: rgb(15, 113, 115);
  width: fit-content;
  height: 22px;
  border-radius: 16px;
  margin-left: 10px;
  padding-top: 2px;
  margin-top: -4px;
  padding: 2px 10px;
  p{
    color: white;
    margin: auto; 
    text-align: center;
    font-family: Satoshi;
    font-size: 14px;
    font-weight: 500;
    line-height: 18.9px;
    @include remove-white-space;
    
  }
}

.popsearch{
  margin: 21px 0 016px;
  font-family: Satoshi-Bold;
  color:#000;
  font-size: 14px;
  font-weight: 700;
}
.loading-dropdown {
  @extend .selectbox-dropdown;
  padding: 43px 32px 41px 32px;
}
.selected_data_location{
  font-family: Satoshi;
}
.hoteldrop-loading{
  background: url("../../images/loading/dropdown-loading.png")no-repeat top left;
  width: 100%;
  height: 190px;
  background-size: 90%;
}
.scroll {
  height: 190px;
  overflow-y: auto;
  overflow-x: hidden;
}

.boxscroll{
  height: 380px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 10px;
}

.boxscroll::-webkit-scrollbar {
  width: 7px;
  height: 250px;
}

.boxscroll::-webkit-scrollbar-track {
  border-radius: 4px;
  background: #e6e6e6;
}

.boxscroll::-webkit-scrollbar-thumb {
  width: 7px;
  height: 79px;
  border-radius: 4px;
  background: #696969;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

.scroll::-webkit-scrollbar {
  width: 7px;
  height: 250px;
}

.scroll::-webkit-scrollbar-track {
  border-radius: 4px;
  background: #e6e6e6;
}

.scroll::-webkit-scrollbar-thumb {
  width: 7px;
  height: 79px;
  border-radius: 4px;
  background: #696969;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

.loading-dropdown .line {
  height: 12px !important;
  margin: 30px 0;
  border-radius: 6px;
  background: #e4e4e4;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, rgba(130, 130, 130, 0.2)),
    color-stop(18%, rgba(130, 130, 130, 0.3)),
    color-stop(33%, rgba(130, 130, 130, 0.2))
  );
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  background-size: 800px 100px;
  animation: wave-lines 3s infinite ease-out;
}

.OptionBox {
  @include optionSpace;
  // height: 72px;
  padding: 15px 0 15px 0;
  margin: 0;
  width: 98%;
  // border-bottom: 1px solid #AEAEAE;
  border-bottom: 1px solid rgba(174, 174, 174, 0.39);
}

.OptionBox:nth-child(1) {
  // height: 54px;
    padding-top: 0;
}

.OptionBox:nth-child(6) {
  margin-bottom: 10;
}

.inputBox {
  width: 165px;
  background: $myColor3;
  height: 57px;
  border: $borderNone;
  border-radius: $selectBorderRadius;
  box-sizing: $boxSizing;
  padding-left: 20px;
}

.inputBox:focus {
  outline: none;
}
.datePicker_container:focus{}
.calendarbox {
  @extend .selectbox;
  width: 230px;
}

.calendar-dropdown {
  @extend .selectbox-dropdown;
  width: 230px;
}

.datesSetting {
  @include inputGroupStyles;
}

.calendarDay {
  padding: 10px 0 15px;
}

.subboxDate {
  @include positionRelative;
  width: 90px;
  text-align: center;
}

.lineBorder {
  border-right: 1px solid #7e7d7d;
}

.inputBoxBranch {
  width: 80%;
  position: absolute;
  top: 325px;
  left: 170px;
}

////////////////////////////////////***********  PROJECT 1  ***********************//////////////////////////////////////////////////////////////////////////////////////

.mainParent {
  @include parentDiv;
  
  &::-webkit-scrollbar {
    width: 0;
    // height: 250px;
    visibility: hidden;
  }
  
  &::-webkit-scrollbar-track {
    // border-radius: 4px;
    // background: #e6e6e6;
    visibility: hidden;
  }
  
  &::-webkit-scrollbar-thumb {
    // width: 2px;
    // height: 79px;
    // border-radius: 4px;
    // background: #696969;
    // box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    visibility: hidden;
  }
}

// background-image: url("../../images/paxImg.png"), url("../../images/paxuparr.png");
//   background-repeat: no-repeat;
//   background-position: 20px center, 88% center;
//   background-size: 25px, 16px;
.stars{
    color: rgb(255, 167, 93);
    font-family: Satoshi;
    font-size: 18px;
    font-weight: 700;
    line-height: 24.3px;
    text-align: left;
}
.iconNew{
  width: 47px;
  height: 26px;
  border-radius: 19px;
  padding: 6px 11px;
  // margin-left: 5px;
  gap: 10px;
  background-color: rgb(140, 181, 176);
  color: white;
  border: 0.5px solid rgb(15, 113, 115);
  font-family: Satoshi;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 18.9px;
  text-align: left;
  @include flexCenter;
}
.profil-user{
  width: 28px;
    height: 28px;
    flex-shrink: 0;
    border-radius: 28px;
    border: 1px solid #FFF;
    // background: url(<path-to-image>) lightgray 50% / cover no-repeat;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
  
}
.flagLangage-container {
  position: relative;
  width: 34px;
  height: 23px;
display: flex;
  align-items: center;
  .flagLangage {
    width: 100%;
    height: 100%;
  }

  &::after {
    cursor: pointer;
    margin-left: 8px;
    flex: 0 0 auto;
    @include generatePseudoElement("../../images/flag/flagdownarr.png", 5px, 8px);
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
  }
}
.welcome{
  font-family: Satoshi;
  font-size: 13px;
  font-weight: 400;
  line-height: 17.55px;
  text-align: right;
  color: rgba(121, 121, 121, 1);
}
.maubackground {
  background-image: url("../../images/maubackimg.png"), url("../../images/landing-page/landingImg.png"),url("../../images/Rectangle\ shadow.png");
  background-repeat: no-repeat,no-repeat,no-repeat;
  background-size: cover,100%, calc(100% + 20px) auto;
  height: calc(100vh - 79px);
  background-position: 0 594px , 0 0px,-10px -14px;
  z-index: 5;
  position: relative;
}


.background_newimg{
  background-image: url("../../images/background_carList.png") !important;
  background-repeat: no-repeat !important;
    background-position: 0 50px !important;
    background-size: contain !important;
    height: 100vh;
}

.headText div:nth-child(1) {
  position: absolute;
  top: 100px;
  text-align: center;
  width: 100%;
  color: #ffe5ce;
  font-family: Satoshi;
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  


  span {
    color: #ffab63;
  }
}

.headText div:nth-child(2) {
  color: #797979;
  font-family: Satoshi;
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  top:144px;
  left: 120px;
  background-image: url("../../images/backimg.png");
  background-repeat: no-repeat;
  background-position: 0px center;
  padding-left: 15px;
  cursor: pointer;
  span{
    font-weight: 500;
    text-decoration: underline;
    text-underline-offset: 3px;
    cursor: pointer;

  }
}

.transferPage_container{
  width: 100%;
    position: absolute;
    top: 303px;
    display: flex;
    justify-content: center;
}
.accomContainer{
  @extend .transferPage_container;
  top:303px
}

.topAdded70{
  top:260px;
}
.left120{
  margin-left: 120px;
}
.transferPage_box{
  display: flex;
  justify-self: center;
}

.selectDropdown {
  @extend .select-container;
}

.selectboxDiv {
  @extend .selectbox;
  padding-left: 66.25px;
  width: $selectWidth1;
  color: #0f7173;
  width: 253px;
  height: 50px;
  font-size: 16px;
  font-weight: 700;
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  background-size: 16px;
  background-position: 30px, 90% center;
  @include lineHeight
}

.selectboxDiv:hover{
  background-color: #f1ebdc ;
  transition: 0.3s;
}

.selectedbox {
  //background-image: url("../../images/selchambre2.png"),url("../../images/down 1.png") !important;
  background-size: 20px, 13px !important;
  background-position: 30px, 90% center !important;
  background-color: #83A6A7 !important;
  color: #fff !important
}

.optionBox {
  @include selectDropdown($selectWidth1);
  background: #fff;
  border-radius: 27px;
  top: 65px;
  text-align: left;
  padding: 6px 14px;
  box-sizing: border-box;
  color: #646464;
  box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
  z-index: 999;
  height: 196px;

    div{
      
    }
}

.dropdown-option{
  @include selectDropdown($selectWidth1);
  background: #fff;
  border-radius: 27px;
  top: 65px;
  text-align: left;
  padding: 18px 14px 17px;
  box-sizing: border-box;
  color: $paraColor;
  box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
  z-index: 999;
  height: 196px;
  .dropdown-optmenu{
    width: 100%;
    height: 30px;
   
    margin-bottom: 12px;
    .OptionValueBox {
      padding: 0 0 0 13px;
      height: 30px;
      display: flex;
      align-items: center;
      &:hover{
        border-radius: 27px;
        background: #C6C6C6;
        box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
      }
    }
    .OptionValueBox_sel{
      height: 30px;
      display: flex;
      padding: 0 0 0 13px;
      align-items: center;
      border-radius: 13px;
      //opacity: 0.43;
      background: #9CBCBD;
      //font-size: 14px;
      //font-weight: 600;
      color: #0A4E50;
      
    }
    
  }
}

// .OptionValueBox {
//   margin: 12px 0 0;
//   cursor: pointer;
//   height: 30px;
//   @include flexStart;
//   padding-left: 13px;
//   box-sizing: border-box;
//   border-radius: 13px;
//   //opacity: 0.43;
//   font-family: Satoshi;
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: normal;
//   color: #797979;
// }

// .OptionValueBox_sel{
//   @extend .OptionValueBox;
//   border-radius: 13px;
//   //opacity: 0.43;
//   background-color: #9CBCBD;
//   color:#0A4E50;
//   font-weight: 500;
// }

.dropValue:hover{
  background: #f6f6f6;
}

.dropopt_img{
    margin-right: 18px;
    background-size: 16px;
    background-position: 0 center;
    background-repeat: no-repeat;
    width: 16px;
    height: 30px;
    float: left;
}

.pax-done-btn {
  display: flex;
  justify-content: flex-end;
  width: 300px;
  gap: 28px;
  float: right;
}

.OptionValueBox{ //refer 1376 for the reason
  //color: #797979;
 // border-radius: 13px;
 // background-color: #c6c6c6;
  //opacity: 0.15;
}

.mapping_car{
  width: 608.5px;
  float: left; 
  margin-bottom: 15px;
  position: relative;
}

.carInfo-img{
  width:608.5px;
  height:369px;
  border-top-left-radius:17px;
  border-top-right-radius:17px;

}
.font-location-hotel{
  font-family: Satoshi;
  font-weight: 500;
  font-size: 15px;
}

.optionBox-pax .blocks{
  display: grid;
  grid-template-columns: 117px 52.23px 87.77px 33px; // changed by malikah
  margin-top: 18px; // changed by malikah
}

.optionBox-pax .blocks:nth-child(1){
  display: grid;
  grid-template-columns: 117px 52.23px 87.77px 33px; // changed by malikah
  margin-top: 0px;
}














//////   INPUTS BOXES

.inputBlocks {
  width: 100%;
  float: left;
  position: relative;

  .transfer-new-design{
    margin-bottom:28px;

    &:last-child{
      margin-bottom: 0;
    }
  }

}

// .inputBlocks:nth-child(1){
//   margin-top: 0;
// }

.listsParent{
  margin-top: 15px;
}
.listsParent:nth-child(1){
  margin-top: 0px;
}
.listsParent .numOfPax.numOfPax_sel{
  height: 60px;
}
.listsParent .numOfPax.paxpop_open{
  height: 60px;
}
.transfer_box .inputBlocks .listsParent .numOfPax.paxpop_open{
  padding :3px 0px 3px 50.25px;
  background-image: unset;
  &::before{
    right: 22px;
  }
}

.selectbox1 {
  width: 100%;
  height: $critereaGlobalSearchHeight;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
  background: #fff url("../../images/location-pin.png") no-repeat 20px center;
  border-radius: 35px;
  padding: 0 10px 0 60px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: #ACACAC;
  font-size: 20px;
  font-weight: 400;
}
.selectbox2 {
  width: 100%;
  height: $critereaGlobalSearchHeight;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
  background: #fff url("../../images/location-pin.png") no-repeat 20px center;
  border-radius: 35px;
  padding: 0 10px 0 60px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: #ACACAC;
  font-size: 20px;
  font-weight: 400;
}

.locInput::placeholder {
  font-size: 20px;
  font-weight: 400;
}

.locInput{
  @extend .selectbox1;
  font-size: 20px;
  font-weight: 700;
  background: #edeae0 url("../../images/location-pin.png") no-repeat 20px center;

}
.CarIconImgSameVehicule{
  @extend .selectbox1;
  font-size: 20px;
  font-weight: 700;
  background: #fff url("../../images/car_icon.png") no-repeat 20px center;
  border:0.5px solid #0f7173;

  &:hover{
    background: #edeae0 url("../../images/car_icon.png") no-repeat 20px center !important;
  }
  &::before{
    width: 24px !important;
    height: 24px !important;
    position: absolute;
    background-size: 24px !important;
    right: 30px !important;
    top: 19px !important;
    transition: 0.3s;
    background-image: url("../../images/infoGreenSameVehicule.png") !important;
    transform: rotate(0deg) !important;
  }

}


.selectbox1:focus {
  outline: none !important;
  color: black;
}

.selectbox1:hover {
  border-radius: 38px;
  background-color: #edeae0;
  border: 0.5px solid #0f7173;
  box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}

.selectbox1::before{
    content: "";
    background-image:  url("../../images/paxdownarr.png");  
    width: 14px;
    height: 14px;
    position: absolute;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 14px;
    right: 25px;
    // top: 23px;
    transition: 0.3s;
}
.selectbox1cnt::before{
  background:  url("../../images/paxdownarr.png")no-repeat right 0;  
    width: 14px;
    height: 14px;
    position: absolute;
    background-size: 14px;
    right: 25px;
    top: 26px;
    transition: 0.3s;
} 
.selectbox1:hover::before{
  content: "";
  background-image:  url("../../images/paxdownarr.png");
  transition: 0.3s;
  transform: rotate(180deg);

 }
.selectbox2:focus {
  outline: none !important;
  color: black;
  border: 1px solid #0F7173;
}

.selectbox2:hover {
  border-radius: 38px;
  background-color: #edeae0;
  border: 0.5px solid #0f7173;
  box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}

.selectbox2::before{
    content: ""; 
    width: 14px;
    height: 14px;
    position: absolute;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 14px;
    right: 25px;
    // top: 23px;
    transition: 0.3s;
}
.selectbox2cnt::before{
    width: 14px;
    height: 14px;
    position: absolute;
    background-size: 14px;
    right: 25px;
    top: 26px;
    transition: 0.3s;
} 
.selectbox2:hover::before{
  content: "";
  transition: 0.3s;
  transform: rotate(180deg);

 }


.inputDateBox:hover {
  border-radius: 38px;
  border: 0.5px solid #0f7173;
  background-color: #edeae0;
  transition: 0.3s;
}

.transferPage_parent{
    width: 100%;
    position: relative;
    float: left;
    font-family: Satoshi;

    .transfer_box{
      margin-bottom: 300px;
    }
}

.delhover:hover {
  cursor:pointer;
  transition: 0.3s;
  //
}
.delhover:hover >.inputDateContainer >.inputDateBox {
  background-color: #FFD0D0;
  background-image: url("../../images/red_calendar.png");
  border: 1px solid transparent;
  pointer-events: none;
  transition: 0.3s;
}

.delhover:hover >.inputDateContainer >.inputDateBox > .dateTextBox > div{
  color: #F67D7D !important;
  transition: 0.3s;
}
.delhover:hover >.inputDateContainer >.inputDateBox > .dateTextBox p{
  color: #F67D7D;
}

.delhover:hover >.inputDateContainer >.inputDateBox > .dateTextBox > div >span{
  color: #F67D7D;
}
.delhover{
  .selLabel_details,
  .no_selLabel,
  .selLabel,
  .calander_txt1 {
    color: #A75555;
  }

}

.delhover:hover >.select-container > div >.locationImg{
  border: 1px solid transparent;
  color: #F67D7D;
  background-image: url("../../images/red_location.png") !important;
  background-position: 21px center;
    background-repeat: no-repeat;
}

.delhover:hover >.select-container > div >.locationArrival_plane {
  border: 1px solid transparent;
  color: #F67D7D;
  background-image: url("../../images/arrival-red.png") !important;
  background-position: 21px center;
    background-repeat: no-repeat;
}
.delhover:hover>.select-container>div>.locationBack_plane {
  border: 1px solid transparent;
  color: #F67D7D;
  background-image: url("../../images/departure-red.png") !important;
  background-position: 21px center;
  background-repeat: no-repeat;
}
.delhover >.select-container>div>.locationArrival_plane {
  border: 1px solid #A75555;
  color: #F67D7D;
  background-image: url("../../images/arrival-red.png") !important;
  background-position: 21px center;
  background-repeat: no-repeat;
}

.delhover >.select-container>div>.locationBack_plane {
  border: 1px solid #A75555;
  color: #F67D7D;
  background-image: url("../../images/departure-red.png") !important;
  background-position: 21px center;
  background-repeat: no-repeat;
}



.delhover:hover >.select-container > div >.selectbox1 > .selectbox1cnt_sub > .selLabel {
  background-image: unset;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
}

.delhover:hover >.select-container > div >.selectbox1 >.selectedLocation{
  color: #F67D7D;
}

.delhover:hover >.select-container > div >.selectbox1> div >.no_selLabel{
  color: #F67D7D;
}

.delhover:hover >.select-container > div >.selectbox1 >div >p{
  color: #F67D7D;
}


.delhover:hover >.select-container >div >.selectbox1 {
  border: 1px solid transparent;
  background-color: #FFD0D0;
  pointer-events: none;
  //transition: 0.3s;
}
.delhover:hover >.select-container > div >.selectbox2 > .selectbox2cnt_sub > .selLabel {
  background-image: unset;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
}

.delhover:hover >.select-container > div >.selectbox2 >.selectedLocation{
  color: #F67D7D;
}

.delhover:hover >.select-container > div >.selectbox2> div >.no_selLabel{
  color: #F67D7D;
}

.delhover:hover >.select-container > div >.selectbox2 >div >p{
  color: #F67D7D;
}


.delhover:hover >.select-container >div >.selectbox2 {
  border: 1px solid #A75555;
  background-color: #FFD0D0;
  pointer-events: none;
  //transition: 0.3s;
}
.delhover:hover .selectDropdown-pax .numOfPax.numOfPax_sel {
  background-image: url("../../images/paxImg-hover.png");
  background-repeat: no-repeat;
  background-position: 20px center;
  background-size: 25px;
  border: 1px solid transparent;
  background-color: #FFD0D0;
  pointer-events: none;

  &::before{
    background-image:  url("../../images/paxdownarr-hover.png");
    background-position: right center;
    background-size: 14px;
    background-repeat: no-repeat;
  }
  .pax-text span {
    color:#F67D7D !important;
  }
  .dropdown-paxmenu {
    .selLabel2{
      // background-color:#F67D7D !important; 
      color: #F67D7D !important; 
      // background: linear-gradient(90deg, #F67D7D 44.7%, rgba(74, 74, 74, 0) 75%);
      background: none;
      -webkit-text-fill-color: #F67D7D;
      // 
    }

  }
  //transition: 0.3s;
}





.selectbox1cnt {
  padding: 14px 0 10px 65px;
  color: $para-grey;
  font-size: 17px;
  font-weight: 700;
  height: 62px;
  //padding-top: 7px; accom hotels
}
.selectbox2cnt {
  padding: 14px 0 10px 58px;
  color: $para-grey;
  font-size: 17px;
  font-weight: 700;
  height: 62px;
  //padding-top: 7px; accom hotels
}
.no_selLabel{
  color: $para-grey;
  font-size: $globalSearFontSize;
  font-weight: 700;
  line-height: $lineHeightFirstLabelSearch;
}
.no_details{
  color: $paraHeadColor;
  font-size: $globalSearFontSize;
  font-weight: 300;
  // line-height: 1;
}
.delhover{
  .selLabel{
    background-image: linear-gradient(90deg, #A75555 150px, rgba(15, 113, 115, 0) 100.26%);
  }
}
.selLabel{
  background-image: linear-gradient(90deg, #0F7173 150px, rgba(15, 113, 115, 0) 100.26%);  
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 15px;
  line-height: 16px;

  @media screen {
    
  }
}

.selLabelLonger{
  color: (#0F7173);  
  font-weight: 700;
  font-size: 15px;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) 5.44%, #0F7173 32.31%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-wrap: nowrap;
  // line-height: $lineHeightFirstLabelSearch;
}
.selLabel_details{
  margin: 0px;
  white-space:nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.selLabel2{
  background: linear-gradient(90deg, $para-grey 44.7%, rgba(74, 74, 74, 0.00) 75%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-size: $globalSearFontSize;
  //font-weight: 500; its too low
div{
  font-family: Satoshi-Bold;
  font-size: 15px;
  font-weight: 500;
}
}

.error{
  // color:$color-red-error !important
  color:#FF5050 !important
}


.rating-lab2 {
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) 5.44%, #0F7173 32.31%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 15px;
  font-weight: 700;
}

.selectbox1cnt p {
  color: #797979;
  font-size: 15px;
  font-weight: 300;
  line-height: normal;
}
.selectbox2cnt p {
  color: #797979;
  font-size: 15px;
  font-weight: 300;
  line-height: normal;
}
.selbox1input {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}



.selbox1input::placeholder {
  color: #AEAEAE;
  font-size: 20px;
  font-weight: 400;
}

.optionBoxDta {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Satoshi;
  // height: 44px;
  padding: 9px 0 9px 16px;
  img{
    width: 17px;
    height: 16px;
  }

  &.default{
    padding-bottom:  0!important;
    padding-top:  0!important;

    &:hover{
      background-color: transparent;
    }
  }
}

.inputDateBox {
  width: 247px;
  //width: 100%;
  height: 62px;
  border-radius: 38px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  background-image: url("../../images/Group.png");
  background-repeat: no-repeat;
  background-position: 30px center;
}

.placeName{
  color: #000;
  font-size: 14px;
  @include lineHeight;
  font-weight: 400;
  margin-left: 12px;
}
.placeDetails {
  color: $myColor1;
  margin: 0px;
  @include lineHeight;
  font-size: 12px;
  font-weight: 300;
  line-height: 12px;
}

.addTransferBtn {
  width: 310px;
  height: 48px;
  border-radius: 29px;
  border: 1px solid #0f7173;
  background: #fff;
  box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
  color: #0f7173;
  font-size: 16px;
  font-weight: 500;
  @include lineHeight;
  margin-right: 23px;
  cursor: pointer;
  background-image: url("../../images/addtransfer.png");
  background-repeat: no-repeat;
  background-position: 24px center;
  padding-left: 23px;
  font-family: Satoshi-Medium;
}
//animation fondu pour le skeleton
@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}
.addTransferBtn:hover {
  background-color: #0f7173;
  color: #fff;
  background-image: url("../../images/addtransfer_hover.png");
  background-repeat: no-repeat;
  background-position: 33px center;
  padding-left: 23px;
  transition: 0.3s;
  background-position: 24px center;
}

.delTransferBtn {
  @include flexCenter;
  color: #ff5050;
  width: 147px;
  height: 34px;
  border-radius: 16.5px;
  border: 1px solid #ff5050;
  // margin-left: auto;
  font-size: 14px;
  @include lineHeight;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
}

.delTransferBtn:hover {
  background: #ffc1c1;
  transition: background-color 0.3s ease;
}
.deliconbox {
  align-items: center;
  margin-left: 10px;
  display: flex;
  background: url("../../images/transferDelicon.png") no-repeat 0 center;
}

.cancel-remove{
  display: flex;
  padding: 12px 27px 12px 21px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 48px;
  border: 1px solid #0F7173;
  background: #FFF;
  cursor: pointer;
  @include remove-white-space;
  transition: background-color 0.2s ease;
  &::before{
    @include generatePseudoElement("../../images/global-icon/trash-cancel.svg", 10px, 13px);
  }

  &:hover{
    background-color: #EDEAE0;
  }
}

.dateTextBox {
  width: 130px;
  //width: auto;
  position: relative;
  left: 80px;
  color: #797979;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  p {
    margin: 0;
    color: $para-grey;
    font-weight: 700;
  }
  div{
    color: #0F7173;
     font-weight: 500;
     font-size: 17px
  }

}


.calendarbox2 {
  @extend .inputDateBox;
  position: absolute;
  top: 34px;
  box-sizing: border-box;
  border-radius: 20px;
  //border: 1px solid #0f7173;
  //border: 1px solid #0f7173;
  background-color: #edeae0;
  flex-direction: column;
  padding: 7px 0 10px 42px;
  background-image: url("../../images/calendardark.png");
}
.calen_validate_btn {
  width: 124px;
  height: 31px;
  border-radius: 10px;
  background-color: #0f7173;
  box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
  border: none;
  color: #fff;
  margin-left: 35px;
  font-family: Satoshi;
  font-weight: 600;
  opacity: 0.44;
 
}

.calen_validate_btn:hover {
  background-color: #033132;
  transition:background-color 169ms ease-in-out;
}
.format_caldate2{
  color: #0F7173; 
  font-weight: 500;
  font-size: 17px;
  
}

.hovercss:hover{
  //opacity: 1;
  background-color: #033132;
  transition: 0.3s;
}

.hovercss1{
  opacity: 1;
}
.datesel_back{
  background-color: #1D8284;
  background-image: url("../../images/calendar-white.png");

  //color:#fff
}

.datesel_back >.dateTextBox2{
  color:#fff
}

.datesel_back >div >.format_caldate2{
  color:#fff !important
}


.datesel_back:hover{
  background-color: #1D8284;
  color:#fff
}

.inner_calendar_butContainer {
  position: absolute;
  bottom: 18px;
  right: 36px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  div{
    text-decoration: underline;
    text-underline-offset: 2px;
    color: #767676;
    font-size: 16px;
    font-weight: 700;
  }
}

.calendarbox2:hover {
   border-radius: 20px;
 }
.dateTextBox2 {
  @extend .dateTextBox;
  left: 0;
  color: #0F7173;
  font-weight: 700;
}

.carDisplayContainer{
  width: 1183px;
  float: left;
  font-family: Satoshi;
  position: relative;
}

.scroll-redht{
  height: auto;
}

.carMapParent {
  float: left;
  margin-bottom: 15px;

  div {
    width: 155px;
    height: 33px;
    border-radius: 8px;
    border: 1px solid #ffab63;
    background-color: #fff;
    margin-left: 10px;
    color: #ffab63;
    font-size: 18px;
    font-weight: 700;
    @include lineHeight;
    @include flexCenter;
      // opacity: 0.2;
  }
}
.transfer_disable div{
    opacity: 1;
    border: 1px solid #000000;
    background-color: #ffffff;
    color: #FFAB63;
}
.transfer_to_fill_focus div{
  opacity: 1;
  border: 1px solid #FFAB63;
  background-color: #FFAB63;
  color: #fff;
}
.transfer_enable div{
    background: #fff;
    border: 1px solid #DD7C29;
    color: #DD7C29;
}
.transfer_fill_focus div{
  opacity: 1;
    border: 1px solid #8DB58C;
      background: #8DB58C;
  color:#fff
}
.transfer_fill div{
  opacity: 1;
  border: 1px solid #189657;
  background-color: #ffffff;
  color:#189657
}


.carMapParent.selected{
  cursor: pointer;
}




.pickCarsText {
  color: #0f7173;
  font-size: 38px;
  font-weight: 700;
  @include lineHeight;
  padding-left: 16px;
  margin-bottom: 62px;

  span{
    display: block;
    font-size: 27px;
    color: #797979;
    font-weight: 400;
  }
}

.pickCarsText::before {
  content: "";
  width: 5px;
  height: 80px;
  background: #ffab63;
  position: absolute;
  left: 0;
  top: 10px;
}
.mainBranch {
  width: 1300px;
  border-radius: 31px;
  float: left;
  background: #fff;
  box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.26);
  margin-top: 25px;
  padding: 32px 54px 36px 54px;
  box-sizing: border-box;
}
.incheight{
  height: auto
}
.carsDisplay {
  @extend .mainBranch;
  background: none;
  box-shadow: none;
  padding: 0;
  margin-top: 110px;
  margin-bottom: 250px;
}

.twoBlocks {
  display: flex;
}
.quotationBlock {
    width: $docketWidth;
    min-height: 600px;
    max-height: 726px;
    // margin: 25px 0px 0 15px;
    border-radius: 11px;
    background:  rgba(255, 255, 255, 0.5);
    padding: 29px 21px 33px 21px;
    box-sizing: border-box;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: 99;
    float: left;
    &::before{
      content:"",
    }

    
    &.hide{
      display: none;
  }
}



.bookButtons {
  width: 100%;
  float: left;
  display: grid;
  grid-template-columns: repeat(2,auto);
  margin-top: 38px;
  grid-gap: 33px;
  height: 41px;
  position: relative;
  button{
    border-radius: 9px;
    border: 1px solid #0F7173;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
    font-size: 18px;
    cursor: pointer;
  }
  .save-quote-btn{
    background-color: #FFF;
    font-weight: 400;
    color: $paraColor;
    width:168px;
    &:hover{
      background-color: #EDEAE0;
    }
  }
  .pay-book-btn{
    background: #0F7173;
    font-weight: 700;
    color:#fff;
    grid-column: 2;
    min-width: 139px;
    border-radius: 9px;
    border: 1px solid #16A391;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
    &:hover{
      background: #0C5657;
    }
  }
  .update-btn{
    @extend .pay-book-btn;
    width: 191px;

    &:disabled{
      border-radius: 9px;
      border: 1px solid #74A39D;
      opacity: 0.34;
      background: #617F80;
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
      pointer-events: all;
      cursor:default;
    }
  }
  .cancel-update-btn{
    @include flexCenter;
    height: 41px;
    width: 115px;
    color: $color-red-light;
    font-size: 18px;
    font-weight: 700;
    padding: 14px 28px;
    gap: 10px;
    border-radius: 9px;
    border: 1px solid $color-red-light;
    background: #FFF;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
    transition: background-color 0.3s ease;
    &:hover{
      background: #D2CACA;
    }
  }

  .update-warning-red{
    position: absolute;
    top: 50%;
    transform:translate(calc(115px - 100%), -50%) ;
    width: 276px;
    height: 53px;
    flex-shrink: 0;
    background-color: #FFF8F8;
    color: $color-red-light;
    font-weight: 500;
    font-size: 0.875rem;
    border: 1px solid $color-red-light;
    border-radius: 6px;
    padding: 12px 16px;
    filter: drop-shadow(0px 1px 6.7px rgba(0, 0, 0, 0.19));
    @include flexCenter;
    gap:8px;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: -1;
    &::before{
      flex:0 0 auto;
      @include generatePseudoElement("../../images/global-icon/alert-sign.svg",17px,17px);
    }

    &:has(~ button:disabled:is(:hover)){
        opacity: 1;
        z-index: 1;
        transition: opacity 0.3s ease;
    }
  }
}
.bookButtons-update {
  width: 100%;
  float: left;
  display: grid;
  grid-template-columns: repeat(2,auto);
  margin-top: 38px;
  grid-gap: 33px;
  height: 41px;
  position: relative;
    display: flex;
  justify-content: flex-end;
  button{
    border-radius: 9px;
    border: 1px solid #0F7173;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
    font-size: 18px;
    cursor: pointer;
  }
  .save-quote-btn{
    background-color: #FFF;
    font-weight: 400;
    color: $paraColor;
    width:168px;
    &:hover{
      background-color: #EDEAE0;
    }
  }
  .pay-book-btn{
    background: #0F7173;
    font-weight: 700;
    color:#fff;
    grid-column: 2;
    min-width: 139px;
    border-radius: 9px;
    border: 1px solid #16A391;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
    &:hover{
      background: #0C5657;
    }
  }
  .update-btn{
    @extend .pay-book-btn;
    width: 191px;

    &:disabled{
      border-radius: 9px;
      border: 1px solid #74A39D;
      opacity: 0.34;
      background: #617F80;
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
      pointer-events: all;
      cursor:default;
    }
  }
  .cancel-update-btn{
    @include flexCenter;
    height: 41px;
    width: 115px;
    color: $color-red-light;
    font-size: 18px;
    font-weight: 700;
    padding: 14px 28px;
    gap: 10px;
    border-radius: 9px;
    border: 1px solid $color-red-light;
    background: #FFF;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
    transition: background-color 0.3s ease;
    &:hover{
      background: #D2CACA;
    }
  }

  .update-warning-red{
    position: absolute;
    top: 50%;
    transform:translate(calc(66px - 100%), -50%) ;
    width: 276px;
    height: 53px;
    flex-shrink: 0;
    background-color: #FFF8F8;
    color: $color-red-light;
    font-weight: 500;
    font-size: 0.875rem;
    border: 1px solid $color-red-light;
    border-radius: 6px;
    padding: 12px 16px;
    filter: drop-shadow(0px 1px 6.7px rgba(0, 0, 0, 0.19));
    @include flexCenter;
    gap:8px;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: -1;
    &::before{
      flex:0 0 auto;
      @include generatePseudoElement("../../images/global-icon/alert-sign.svg",17px,17px);
    }

    &:has(~ button:disabled:is(:hover)){
        opacity: 1;
        z-index: 1;
        transition: opacity 0.3s ease;
    }
  }
}

.displayCarParent {
  width: calc(100% - 24px);
  float: left;
  display: grid;
  grid-template-columns: 608.5px 608.5px;
  gap: 84px;
  // margin-left: 24px;
}
.car_details {
  width: 100%;
  margin-top: 23.5px;
  // float: left;
  color: #797979;
  font-size: 14px;
  font-weight: 400;
}

.pickCar_parentloop{
  width: 100%;
  float: left;
  // margin-bottom:28px;
  overflow-x:auto;
  display: flex;
}



.single{
  display: flex;
  justify-content: flex-start;
}

.double{
  display: flex;
  justify-content: space-between;
}

.car_details span{
  float: left;
  width: 239px;
  border-right: 0.5px solid #797979;
  font-family: Satoshi-Bold;
  font-weight: 700;
  color: $para-grey;
  font-size: 16px;
  margin-right: 14px;
 
}


.headTransfer {
  @include flexStart;
  margin-bottom: 36px;
    // grid-template-columns: 147px 241px;

    .transfer-input-title{
      color: #0F7173;
      font-size: 26px;
      font-weight: 700;

      &.danger{
        color: #C16161;
      }
    }
}

.selectDropdown-pax {
  width: 101%;
  position: relative;
}






.numOfPax {
  width: 100%; // using in excursion
  position: relative;
  background-image: url("../../images/paxImg.png");
  background-repeat: no-repeat;
  background-position: 20px center;
  background-size: 25px;
  padding: 3px 0px 3px 55.25px;
  color: #0f7173;
  height: $critereaGlobalSearchHeight;
  font-size: 16px;
  font-weight: 700;
  border-radius: 32px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: 0.3s;
  @include lineHeight;
  border: 1px solid #D9D9D9;
  &:not(.paxpop_open){
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &.delhover{
    background-image: url("../../images/paxImgRed.png");
    background-size: 15px !important;
  }
}
.selectDropdown-pax{

    .numOfPax.transfer{
      background-size: 15px;
      padding: 2px 0px 3px 51.25px;

      .pax-text.transfer{
        color: #AEAEAE;
        font-size: 17px;
        font-weight: 400;
      }

      .dropdown-paxmenu.delhover >div{
        -webkit-text-fill-color: #A75555;
      }
    }
    .numOfPax.paxpop_open.transfer{
      padding: 3px 0px 3px 21px !important;

    }
    .numOfPax_sel{
      background-size: 18px !important;
    }
  
}

.paxpop_open{
  border:1px solid #0F7173;
  background-color: #EDEAE0;
  display: grid;
  place-items: center;
    .pax-text {
        width: 100%;
        float: left;
        color: #ACACAC;
        // font-size: $globalSearFontSize;
        font-size: 19px;
        font-weight: 400;
        line-height: unset;
        padding-top: unset;
        line-height: unset;
    
        span {
          color: #797979;
          font-size: 15px;
          font-weight: 300;
        }
      }
    }

.pax-text{
  width: 100%;
   float: left;
   color: #ACACAC;
   font-size: 15px; 
   font-weight: 400;
   line-height: $lineHeightFirstLabelSearch;
   padding-top: 3px;
   span{
    color: $paraHeadColor;
    font-size: 15px; 
    font-weight: 300;
    line-height: 0.9;
   }
}

.dropdown-paxmenu{
 width: 100%;
 float: left;
 color: $para-grey;
}



.paxpop_open::before{
  content: "";
  width: 14px;
  height: 14px;
  position: absolute;
  background-position: right center;
  background-size: 14px;
  right: 21px;
  // top: 23px;
  // transition: 0.3s;
  background-image: url("../../images/paxuparr.png")

}

.selectbox2cnt_sub{
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.numOfPax:hover{
   background-image: url("../../images/paxImg.png");
  background-color: #EDEAE0;
  border: 0.5px solid #0F7173;
  transition: 0.3s;
}
.delhover:hover {
  .numOfPax {
    transition: none;
  }
}
.end_hover{
 display: flex;
 white-space: nowrap; /* Prevent text from wrapping */
      overflow: hidden; /* Hide any overflowing content */
      text-overflow: ellipsis;
     
}

.numOfPax_sel::before{
    content: "";
    background-image:  url("../../images/paxdownarr.png");
    width: 14px;
    height: 14px;
    position: absolute;
    background-position: right center;
    background-size: 14px;
    background-repeat: no-repeat;
    right: 25px;
    transition: 0.3s;
 }
 .numOfPax_sel:hover::before{
  content: "";
  background-image:  url("../../images/paxdownarr.png");
  transition: 0.3s;
  transform: rotate(180deg);

 }



 //.OptionValueBox-pax:hover {  // it was removed based on number of pax if i hover the name adult it changes the background
  //  background-color: #fff;
  //  color: #434343;
  //  transition: 0.3s;
 //}

.pax-button {
  width: 100%;
  float: left;
  // margin-top: 18.4px; // chnaged by malikah

  .errMsg{
    color: $color-red-light;
    font-size: 14px;
    font-weight: 300;
    margin: 15px 0 10px 0;
  }
 
}
.addRoom-accom{
  font-weight: 600;
  cursor: pointer;
  text-align: right;
  margin-bottom:14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 7px;
  &::before{
    content:"";
    width:16px;
    height:14px;
    background-image: url("../../images/whitechambre1.png");
    background-repeat: no-repeat;
    background-position: center;
  }
}

.addRoom-accom:hover{
  color: #0F7173;
  &::before{
    background-image: url("../../images/chambre1.png");
  }
}

.paxClear {
  color: #7e7e7e;
  font-size: 18px;
  @include lineHeight;
  font-weight: 400;
  text-decoration: underline;
  text-underline-offset: 3px;
  cursor: pointer;
  transition: color 0.1s;
}
.paxClear:hover { color:#FF7400;transition-delay: 0.2s;}

.paxDone {
  width: 146px;
  height: 32px;
  border-radius: 17px;
  background-color: #0f7173;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.28);
  @include flexCenter;
  @include lineHeight;
  color: $myColor6;
  border: none;
  font-size: 16px; //chnaged by malikah
  font-weight: 700; //chnaged by malikah
  transition: background-color 0.1s;
}

.paxDone:hover {
  background-color: #2DA0A3;
  transition: 0.2s;

}

.display-car-data {
  width: 100%;
  border-top-style: solid;
  border-top-color: #0f7173;
  border-top-width: 10px;
  background: #fafafa;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.28);
  padding: 49px 31px 32px 39px;
  box-sizing: border-box;
  border-bottom-left-radius: 17px;
  border-bottom-right-radius: 17px;
  position: relative;
  // float: left;
}
.details-block{
  // border-bottom: 2px dashed #FFAB63;
  // float: left;
  // padding-bottom: 257px;
  // margin-bottom: 20px 
}

.supplement-block{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.sup-hdtxt{
  width: 100%;
  color:#434343;
  font-size: 20px;
  font-weight: 700;
  span{
      color:#F67D7D;
      font-size: 16px;
      font-weight: 400;
      padding-left: 25px;
  }
}
.carCategory {
  color: #0f7173;
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    line-height: 21px;
    margin-bottom: 10px;
    padding-left: 16px;
}

.carCategory::before {
  content: "";
    width: 7px;
    height: 22px;
    background: #ffab63;
    position: absolute;
    left: 39px;
    top: 49px;
}

.carselBtn {
  width: 165px;
  height: 39px;
  border-radius: 17px;
  background: #ffab63;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.28);
  border: none;
  color: #fff;
  @include lineHeight;
  @include flexCenter;
  //padding: 0px 0px 0px 31.5px;
  font-size: 20px;
  font-weight: 700;
  span{
    float: left;
  }
}

.carselBtn:hover {
  background-color: #C27B3E !important;
  //transition: 0.3s;
 // padding: 0px 22px;
}

.btnblock_carList{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  // float: left;
  margin-top: 26px;
  gap:10px
}
.info-message-visit{
  // width: 90%;
  // background-color: rgb(104, 161, 30);
  // float: right;
  text-align: left;
 .notes{
  margin-top: 20.85px;
  font-family: Satoshi;
  font-size: 15px;
  font-weight: 700;
  line-height: 20.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #F66666;
 }
}

.ageBox-parent{
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  font-size: 14px;
  font-weight: 300;
  
}
.ageBox{
  margin-top: 10px;
}

/////////////////////////////////////////////////////Quotation/////////////////////////////////

.headerQuotation {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 32px;
}
.headerQuotation-txt {
  background: url("../../images/bill\ 1.png") no-repeat 0 center;
  padding-left: 26px;
  background-size: 17px;
  color: $para-grey;
  font-size: 20px;
  font-weight: 700;
  padding-top: 3px;
  width: 186px;
}
.headerQuotation-button{
  // width: 177px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 28px;
  background: #FFF;
  box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
  padding: 12px;
  cursor:pointer;
  @include flexCenter;
  gap:7px;
  transition: background-color 0.3s ease;
  border: 0.5px solid transparent;
  color: $paraHeadColor;
  font-size: 15px;
  font-weight: 500;
  &::after{
    @include generatePseudoElement("../../images/global-icon/arrow-down-sign-to-navigate.svg",5px,8px);
    transition:transform 0.3s ease;
  }

  &.clicked{
    border: 0.5px solid $myColor7;
    padding: 12px;
    background: $color-light-beige;
    box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
    &::after{
      transform: rotate(180deg);
    }
  }

  &:hover{
    @extend .clicked;
  }

}

.docket-options{
  color: $paraColor;
  font-size: 18px;
  font-weight: 400;
  margin-top:10px;
  position: absolute;
  right:0;
  @include flexStart;
  align-items: flex-start;
  flex-direction: column;
  gap:14px;
  padding: 16px 11px;
  border-radius: 7px;
  background: #FFF;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
  z-index:+1;
  .option-item{
    display: flex;
    width: 155px;
    padding: 8px 29px 8px 8px;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    span{
      @include flexCenter;
      justify-content: flex-start;
      @include remove-white-space;
    }

    &:hover{

      border-radius: 7px;
      background: $myColor8;
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
    }
  }
}


.quotation_body{
  width: 100%;
  float: left;
  min-height:269.88px;
  max-height: 405px;
  overflow-y:auto;
  overflow-x:hidden;
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 1.5s ease-in-out;
}

.priceadded{
  max-height:295px;
}

.quotation-loop-parent {
  width: 100%;
  float: left;

  .remove-button{
    display: flex;
    padding: 6px 9px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    background: #F67D7D;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    right: 0;
    transform: translateY(-3px);
    transition:  background-color 0.3s ease;
    margin-right: -12px;  
    span{
      @include remove-white-space;
      @include flexCenter;
      &::before {
            flex: 0 0 auto;
            @include generatePseudoElement("../../images/close2.png", 17px, 17px);
            margin-right: 8.5px;
      }
    }
    &:hover{
      background: $color-red-light;
    }
  }
}

.quotation-loop-scroll{
 
}


.quotation_body::-webkit-scrollbar {
  width: 7px;
  height: 250px;
}

.quotation_body::-webkit-scrollbar-track {
  border-radius: 4px;
  background: #e6e6e6;
}

.quotation_body::-webkit-scrollbar-thumb {
  width: 7px;
  height: 79px;
  border-radius: 4px;
  background: #696969;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}




.quotation-map-parent {
  width: calc($docketWidth * 0.82);
  float: left;
  color: #434343;
  font-size: 15px;
  font-style: normal;
  line-height: normal;
  font-weight: 500;
  padding: 0px 0 0px 0px;
  margin-top:0px;
  box-sizing:border-box;
  position: relative;
}
.quotation_width{
  width: 600px;
}
.quotation-map-parent::before {
  content: "";
}

.quotation-map-parent ul {padding:0px !important}
.quotation-map-parent ul li:nth-child(2) {
  padding:0px !important;
}
.quotation-map-parent ul li::before {
  padding:0px !important;
  flex: unset !important;
}


.css-55vwbm-MuiTimeline-root >.css-ha3bif-MuiTimelineItem-root::before {
  content: "";
  -webkit-flex: unset !important ;
  -ms-flex: 1;
  flex: unset !important;
  padding: 0px !important;
}


.quotation-map-child {
  width: 100%;
  // float: left;
  margin-bottom: 9px;
  font-family: Satoshi-Medium;
  font-weight: bold;
}

.transfer-pattern-container{
  height: auto;
  margin-bottom:35px;
}

.trans_date{
  @include flexSpace;
  align-items: center;
  height: auto;
  flex-wrap: wrap;
  position: relative;
  .transfer{
    margin-top: 4px;
    margin-bottom: 15px;
    background: url("../../images/global-icon/transfer-icon.svg") no-repeat 14px center;
    background-size: 20px;
    border-left: 3px solid #FFAB63;
    height: 14px;
    line-height: 15px;
  }
}
.transfer-pattern-content {
  padding-left: 16px;
  height: auto;

  // width:210px;
  .transfer-date {
    color: $myColor7;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 30px;

    &::before {
      margin-right: 9px;
      @include generatePseudoElement("../../images/calendardark.png", 11px, 11px);
      width: 12px;
      vertical-align: middle;
      position: relative;
      top: -3px;
    }
  }

  .trans-message-error{
    color: #C16161;
    font-size: 14px;
    font-weight: 700;
  }

  .trans-supplements-container{
    margin-top: 29px;

    .supplements-title{
      color: #434343;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 18px;
    }

    .supplements-details{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;

      .luggage-icon{
        @include generateIconClass('../../images/global-icon/luggage-supplement.png',14px,11px);
      }

      .baby-seat-icon{
        @include generateIconClass('../../images/global-icon/baby-seat-supplement.png',14px,11px);
      }
    }
  }
}

// .trans_date,
// .trans_points,
// .trans_per_car {
//   @include float_width;
// }

.adult_block_span {
  // @include float_width;
  position: relative;
}
.supplement_block_span {
  @include float_width;
  position: relative;
  margin-top: 18px;
  
 div{
 &:first-of-type{
   margin-top: 10px;
 }
 &:nth-of-type(2){
   margin-top: 5px;
 }
 &:nth-of-type(3){
   margin-top: 5px;
 }
 }
 .quationluggage_img {
  height: 20px;
  background-repeat: no-repeat;
  background-size: 10px;
  padding-left: 20px;
  background-position: 0 center;
  background-image: url("../../images/quotation_luggage.png");
  width: 250px;
}
 .quationbabyseat_img {
  height: 20px;
  background-repeat: no-repeat;
  background-size: 10px;
  padding-left: 20px;
  background-position: 0 center;
  background-image: url("../../images/quotation_babyseat.png");
  width: 250px;

}

}




.trans_date .quotation-map-child:nth-child(2) {
  background-image: url("../../images/calendardark.png");
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 15px 5px;
  padding-left: 20px;
  color: #0f7173;
  font-size: 14px;
  @include lineHeight;
  font-weight: 500;
  margin-bottom: 8px;
  font-family: Satoshi-Medium;
  font-weight: 500;
}
.trans_points {
  margin-bottom: 29px;
  color: $para-grey;
font-family: Satoshi;
font-size: 15px;
font-weight: 500;
line-height: normal;

}
.trans_points_pax{
  .person-icon{
    background: url("../../images/global-icon/user-grey.svg") no-repeat 0px center;
    padding-left: 16px;
    background-position-y: 5px;
    font-weight: 600;
    color: #4A4A4A;
    width: fit-content;
  }
  .group{
    display: inline-flex;
    column-gap: 10px;
  }

}
.trans_points-show-more{
  position: relative;
  display: inline-flex;
  top: 23px;
    .show-more-text{
        margin-left: 15px;
        font-size: 15px;
        font-style: italic;
        color: #616161;
    }

}

.quotation-map-child b{
  font-size: 16px;
  font-weight: 700;
}

.trans_points .quotation-map-child:nth-child(2) {
  background-image: url("../../images/quoarrow.png");
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 0 center;
  height: 16px;
  margin-bottom: 9px;
}
.trans_points .quotation-map-child:nth-child(3) {
  margin-bottom: 0;
}
.trans_per_car {
  color: #434343;
  font-size: 15px;
  font-weight: 500;
  @include lineHeight;
  font-family: Satoshi-Medium;
  margin-bottom: 15px;

  .trans_points_supplement{
    & > div:first-of-type{
      margin-top: 8px;
    }
     & >div{
      margin:2px 0;
     }
     .luggage_truck,.luggage_car{
      background: url("../../images/global-icon/luggage_truck.svg") no-repeat 0px center;
      background-position-y: 5px;
      width: fit-content;
      padding-left: 16px;
     }
     .baby-seat{
      background: url("../../images/global-icon/baby-seat.svg") no-repeat 0px center;
      background-position-y: 5px;
      width: fit-content;
      padding-left: 16px;
     }
  }
}
.quotation-total {
  float: left;
  width: 100%;
  padding: 8px 5px;
  box-sizing: border-box;
  border-style: dashed hidden dashed hidden;
  border-width: 1px;
  border-color: $para-grey;
  color: $para-grey;
  font-size: 14px;
  font-weight: 700;
  @include lineHeight;
  // margin-top: 40.64px; // bal 8px it was taken from meal plan margin bottom
  margin-top: 25px; // docket
}

.bookButtons-par button {
  color: #fff;
  font-size: 18px;
  @include lineHeight;
  font-weight: 700;
}


.bookButtons-par:nth-child(1) button {
  display: flex;
  padding: 14px 28px;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  border: 1px solid #16a391;
  background: #0f7173;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
}

.bookButtons-par:nth-child(2) button {
  display: flex;
  padding: 14px 28px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 9px;
  border: 1px solid #16a391;
  background: #0f7173;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  height: 41px;
}
.priceTag {
  width: 89px;
    position: absolute;
    z-index: 95;
    //top: 262px;
    bottom: 20px;
    border-radius: 14px;
    background: #fff;
    box-shadow: 2px 1px 6px -1px rgba(0, 0, 0, 0.23) inset;
    display: flex;
    flex-direction: column;
    text-align: center;
    right: 28px;
    box-sizing: border-box;
    color: #0f7173;
    padding: 15px 0px;
}
.priceTag:nth-child(2){
color:#434343
}
.priceTag_val {
  color: #0f7173;
  @include lineHeight;
  font-size: 17px;
  font-weight: 700;
}

.numOfPax::after {
  // background-image: url("../../")
}
.scrollAdded{
  overflow-y: auto;
  overflow-x: hidden;
}
.quationAdult_img {
  // background-image: url("../../images/quotation_adult.png");
  &::before {
      margin-right: 9px;
      @include generatePseudoElement("../../images/profil-de-lutilisateur.svg", 12px, 11px);
      width: 12px;
      vertical-align: middle;
      position: relative;
      top: 3px;
      top: -2px;
    }
  // background-image: url("../../images/profil-de-lutilisateur.svg");
  height: 13px;
  width: 150px;
  background-repeat: no-repeat;
  background-size: 14px;
  position: absolute;
  // padding-left: 20px;
  background-position: 0 center;
    font-size: 14px;
      font-weight: 500;
}

.quationcar_img {
  &::before {
      margin-right: 9px;
      @include generatePseudoElement("../../images/voiture-musclee.svg", 14px, 14px);
      width: 12px;
      vertical-align: middle;
      position: relative;
      top: 3px;
      top: -2px;
    }
  width: 250px;
  height: 22px;
  position: relative;
  // font-weight: 600;
}
.view_quotation {
  @include float_width;
  @include flexEnd;
  margin-top: 30px;

  &.disabled{
    div{
      cursor:not-allowed ;
      &:hover {
        color:#877e7e ;
        background-image: url("../../images/view_eye.png");
        &>span{
          border-bottom: 2px solid #877e7e;
        }
      }

    }
    span{
      
      &:hover{
        color:#877e7e ;
      }
    }
  }
}
.add_opacity{
  opacity: 0.3;
}

.view_quotation div {
  width: 145px;
  background-image: url("../../images/view_eye.png");
  background-repeat: no-repeat;
  padding-left: 28px;
  background-position: 0 center;
  background-size: 16px;
  color: #877e7e;
  @include lineHeight;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.view_quotation div:hover {
  color: #0F7173;
  transition: 0.3s;
  background-image: url("../../images/view_eye_green.png")
}

.view_quotation div:hover >span {
  border-bottom: 2px solid #0F7173;
}

.view_quotation div span {
  width: 100%;
  float: left;
  border-bottom: 2px solid #877e7e;
  height: 24px;
}

.quotation_option {
  width: 170px;
  height: 41px;
  padding: 9px 14px 10px 16px;
  background-image: url("../../images/paxdownarr2.png");
  background-repeat: no-repeat;
  border-radius: 9px;
  background-color: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
  background-position: 148px center;
}
.quotation_option:hover {
  background-color: #edeae0;
  transition: 0.3s;
}

.quotation_option_text {
  width: 120px;
  border-right: 1px solid #877e7e;
  color: #877e7e;
  font-size: 18px;
  @include lineHeight;
  font-weight: 400;
  cursor: pointer;
}

.quotation_option_popup {
  width: 170px;
  border-radius: 7px;
  background: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
  padding: 16px 11px;
  margin-top: 5px;
  color: #877e7e;
  font-size: 18px;
  @include lineHeight;
  font-weight: 400;
  float: left;
}
.quotation_option_popup div {
  cursor: pointer;
  padding: 7px 15px 8px 12px;
  float: left;
  height: 28px;
  width: 147px;
  display: flex;
  align-items: center;
}
.quotation_option_popup div:hover {
  border-radius: 7px;
  background: #e5f1f0;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
}

.locationArrival_plane{
  background-image: linear-gradient(rgba(255, 255, 255, 0.068), rgba(255, 255, 255, 0.192)),
  url("../../images/arrival-plane1.png");
    background-size: 29px;  
}
.locationBack_plane {
  background-image: linear-gradient(rgba(255, 255, 255, 0.062), rgba(255, 255, 255, 0.247)),
  url("../../images/departure-plane.png");
  background-size: 32px;
}
.selectedLocation{
  width:calc(100% - 25px);
  color: #0F7173;
}


.seldiv{
  background-color:#8CB5B0;
  background-image: url("../../images/selectedCar.png") ;
  background-repeat: no-repeat;
  background-position:80% center;
  padding: 0 0px 0 32px;
    display: unset;
}

.seldiv:hover{ background-color:#BF5858 !important;background:unset;padding: 0;}
.seldiv:hover::before{ content:"Unselect ?";padding:0;}
.seldiv:hover span{display:none}
  
  



.notseldiv{
  pointer-events: none;
  background: #C8C8C8;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.28);
  
}

.errpoints{
  border: 1px solid #FF5050;
  background-image: url("../../images/red_location.png");
  background-position: 20px center;
  background-repeat: no-repeat;
  .selLabel,.selLabel_details,.no_selLabel{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #FF5050;
  }
  
}

.errpoints:hover ,.errDate:hover{
  border: 1px solid #FF5050;  
  //background-color: #fff;
}

.errDate{
  border: 1px solid #FF5050;
  background-image: url("../../images/red_calendar.png");
  background-repeat: no-repeat;
  background-position: 30px center;
  .dateTextBox{
    p,div{
      color:#FF5050;
    }
  }
  
}


.hotel_btnblk{
    width: fit-content;
    color: #434343;
    float: right;
    position: absolute;
    bottom:0;
    right:0;
    
    &.hotel-card-btn{
      right:26px;
      position: relative;
      inset: unset;
      align-self: flex-end;
      margin-top: auto;
    }
  .seemore_btn{
      @include ex_search;
      background: #FFAB63;
      font-family: Satoshi;
      padding: 0 15px;
      min-width: 147px;
      width: fit-content;
      height: 36px;
      font-weight: 700;
      font-size: 18px;
      font-style: normal;
      line-height: normal;
      margin: 3px 0 0 14px;
      &:hover{
          background-color: #FFBA80;
          //transition: 0.3s;
      }
  }
  
  .add-quote-btn{
    @extend .seemore_btn;
    margin-left: 28px;
  }
  .add-quote-btn-multiple-room{
    @extend .seemore_btn;
    margin-left: 14px;
  }
  .paxContainer{
    float:left;
    &:has(~.selected){
      margin-right: 28px;
    }
  }
  .selected{ 
    background: #8CB5B0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:5px;
    transition: all 0.3s;
    &::after{
      content:"";
      width:18px;
      height:18px;
      position: relative;
      background: url("../../images/roomSelected.png");
      background-repeat: no-repeat;
    }
    &:hover{
      background-color: #FF5050;
      &::after{
        display: none;
      }
    }
  }

 .notseldiv{
  pointer-events: none;
    background: #C8C8C8;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.28);
 }
}

.discount-icon{
  margin-top:2px;
  @include generateIconClass("../../images/global-icon/discount.svg",28px,28px);
}
.discount-icon2{
  margin-top:2px;
  @include generateIconClass("../../images/global-icon/discount.svg",13px,13px);
  transform: translate(4px, -2px);
}
.include-icon{
  margin-top:2px;
  @include generateIconClass("../../images/global-icon/infoML.svg",13px,11px);
  transform: translate(4px, -2px);
}

.star-rating{
  // height:$critereaGlobalSearchHeight!important;
  height:61px!important;
}

.accom-select-date{
  width:fit-content!important;
  background-image: url("../../images/calendar/minusSeparator.svg");
  background-position: center center;
  background-repeat: no-repeat;
  // background-size: 5px 2px;
}

.center-self-absolute-x{
  left:50%;
  transform: translateX(-50%);
}

.flex-center{
  @include flexCenter;
}

.translate-x-left-animation{
  animation: translateXLeftAnimation 0.3s ease;
}

.translate-x-right-animation{
  animation: translateXRightAnimation 0.3s ease-in-out;
}

.visibility-none{
  visibility: hidden;
}

@keyframes wave-lines {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
.selected_btn{
  @extend .seemore_btn;
  margin-left: 28px;
  background: #8CB5B0 !important;
  color: #FFFFFF;
  &::after {
      @include generatePseudoElement("../../images/global-icon/check-mark.svg", 18px, 18px);
      transform: translate(5px, 3px);
      margin-left: 5px;
      transition: background-image 0.3s ease;
    }
  &:hover {
    transition: 300ms;
      background: #FF5050 !important;
    &::after {
      display: none;
    }
  }
}

    
@keyframes fadeInAnimation {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

@keyframes scaleYAnimation {
  from{
    transform: scaleY(0);
  }
  to{
    transform: scaleY(1);
  }
}

@keyframes  translateXLeftAnimation{
  from{
    transform: translateX(0);
  }
  50%{
    transform: translateX(-100%);
  }
  to{
    transform: translateX(0);
  }
}
@keyframes  translateXRightAnimation{
  from{
    transform: translateX(0);
  }
  50%{
    transform: translateX(100%);
  }
  to{
    transform: translateX(0);
  }
}

@keyframes autoScrollHorizontal {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.lazy-image-container {
  position: relative;
  width: 100%;
  height: auto; /* Adjust this as needed */
}

.lazy-image {
  display: block;
  width: 100%;
  height: auto;
}

.skeleton {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #e0e0e0; /* Light grey background for the skeleton */
  border-radius: 4px; /* Optional: add border-radius for rounded corners */
  animation: pulse 1.5s infinite ease-in-out;
}

.toggle-text-readmore {
  color: #007bff;
  cursor: pointer;
  font-weight: bold;
}

@keyframes pulse {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #c0c0c0;
  }
  100% {
    background-color: #e0e0e0;
  }
}

.lazy-image.loading {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.close-button-container{
  position: absolute;
  inset: calc(74.6px - 22px) 26px auto auto;
  width:auto;
  height: calc(100% - 34px - 32px);
}
.close-tab-button{
  width: 122px;
  padding: 8px 14px;
  @include flexCenter; 
  gap: 7px;
  border-radius: 32px;
  background:  $orange-color;
  box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
  transition: background-color 0.2s ease;
  color: #FFF;
  font-size: 16px;
  font-weight: 700;
  height: 32px;
  position:sticky;
  // inset: calc(74.6px - 22px) 26px auto auto;
  top: 130px;
  z-index: 119;
  // position: sticky;
  // top: 0;
  &::before{
    @include generatePseudoElement("../../images/global-icon/white-cross.svg",16px,16px);
  }

  &.fixed{
    position: sticky;
    top: calc($navbarHeight + 74.6px);
  }

  &:hover{
    background: #C87D3E;
  }
}

.icon-discount-normal{
  @include generateIconClass("../../images/global-icon/discount-normal.svg",29px,29px);
}
.infoRoom{
  color: #C16161;
  font-family: Satoshi;
    font-size: 14px;
    font-weight: 700;
    line-height: 18.9px;
    text-align: left;
}

.big-text-ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 210px;
}
.autocomplete_box{
  width: 246px;
  height: 104px;
  top: -31px;
  left: 305px;
  border-radius: 7px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 7px;
  background: rgba(248, 248, 248, 1);
  border-style: solid;
  border-color: rgba(140, 181, 176, 1);
  padding-top: 16px;
  padding-left: 19px;
  position: absolute;
  top: 0px;
  left: 600px;
  p{
    font-family: Satoshi;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
  }
  button{
    width: 49px;
    height: 30px;
    border-radius: 6px;
    border-width: 0.5px;
    gap: 10px;
    background: rgba(255, 255, 255, 1);
    border: 0.5px solid rgba(0, 170, 108, 1);
    &:nth-child(2) {
      margin-right: 6px;
      border: 0.5px solid rgba(193, 97, 97, 1);
    }
  }
}
