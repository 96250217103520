.optionBox-pax {
  width: 332px;
  //height: 319px;
  max-height: 9000px;
  border-radius: 31px;
  border: 0.5px solid #d9d9d9;
  background: #fff;
  box-shadow: 1px 4px 15px 0px rgba(116, 116, 116, 0.19);
  position: absolute;
  top: 67px;
  right: 0px;
  padding: 41px 21px 21px 28px; // malikah
  z-index: 90;
  box-sizing: border-box;
  transition: max-height 0.5s ease;


  .optionBox-scroll{
    height: 650px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding:7px 7px 0 7px;
    border-radius: 31px 31px 0 0;

    &:hover{
      visibility: visible;
    }
    .room-list {
      background: #F9F8F8;
      width: 100%;
      padding: 15px;
      box-sizing: border-box;
      border-radius: 16px;
      margin-bottom: 18px;
      box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);

      .room-no {
        display: flex;
        justify-content: space-between;
        div {
          font-weight: 600;
          color: #0F7173;
          display: inline-flex;
          justify-content: center;
          align-items: center;

          &::after{
            content:"";
            background-image: url('../../images/green-bed.png');
            background-repeat: no-repeat;
            background-size: 17px;
            width: 17px;
            height:17px;
            // position:;
            margin-left: 17px;
            top:3.5px
          }
        }
        button {
          background-color: rgb(255, 255, 255,1);
          color: #fff;
          width:auto;
          height:26px;
          padding: 7px 15px;
          border: 0.5px solid rgba(193, 97, 97, 1);
          border-radius: 13px;
          box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);
          color: rgba(193, 97, 97, 1);
          display: inline-flex;
          justify-content: center;
          align-items: center;
          transition: 0.3s;
          gap:5px;

          &::before{
            content:"";
            width:12px;
            height:12px;
            background-image: url("../../images/minus-red.png");
            // position: absolute;
            background-position: left center;
            background-repeat: no-repeat;
            background-size: 12px 12px;
            left:15px;
            top:15px;
            transition: 0.3s;
          }

          &:hover{
            border: 0.5px solid $color-red-light;
            background: #FFE8E8;
            box-shadow: 0px 1px 6.7px 0px rgba(0, 0, 0, 0.19);

            &::before{
              background-image: url("../../images/cross-red.png");
            }
          }
      }
    }
    }
  }
  .optionBox-scroll::-webkit-scrollbar {
    width: 2px;
    height: 250px;
    visibility: hidden;
  }
  
  .optionBox-scroll::-webkit-scrollbar-track {
    border-radius: 4px;
    background: #e6e6e6;
    visibility: hidden;
  }
  
  .optionBox-scroll::-webkit-scrollbar-thumb {
    width: 2px;
    height: 79px;
    border-radius: 4px;
    background: #696969;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    visibility: hidden;
  }
  .paxbox-row{
    display: flex;
    // grid-template-columns: 117px 52.23px 87.77px 33px;
    justify-content:space-between;
    align-items: center;
    margin-top: 17.85px;
        .label{
          // @extend .OptionValueBox;
          @include alignCenter;
          color: #434343;
          padding: 0;
          opacity: 1;
          font-size: 15px;
          font-weight: 700;
          margin-top: 0;
          // width: 177px;
        }
        .OptionValueBox-pax {
            // @extend .OptionValueBox;
            @include alignCenter;
            justify-content: space-between;
            color: #434343;
            padding: 0;
            opacity: 1;
            font-size: 15px;
            font-weight: 700;
            margin-top: 0;
            width: 177px;
        
        
            .pax_decrement {
              background: url("../../images/whiteMinus.png")no-repeat;
              background-size: $signPaxSize; // changed by malikah
              height: $incrementPaxHeight;
              // changed by malikah
              width: $incrementPaxWidth;
              transition: 0.15s linear;
              background-position: center center;
        
              &:hover {
                background-image: url("../../images/greenMinus.png");
              }
            }
        
            .pax_increment {
              background: url("../../images/whitePlus.png")no-repeat;
              background-size: $signPaxSize; // changed by malikah
              width: $incrementPaxWidth;
              height: $incrementPaxHeight;
              // changed by malikah
              transition: 0.15s linear;
              background-position: center center;
        
              &:hover {
                background-image: url("../../images/greenPlus.png");
        
              }
            }
        
            .paxValueBox {
              width: 70px;
              height: 31px;
              border-radius: 9px;
              background-color: #fff;
              box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
              @include flexCenter;
              color: #000;
              font-size: 15px;
              font-weight: 700;
        
              &:hover {
                background-color: #fff;
                border-radius: 9px;
              }
            }
          }
  }
  .paxbox-row:nth-child(1){
    margin-top: 0px;
  }
}

.pax-label{
  width: 100%;
  margin: 20px 0 10px 0;
  font-weight: 700;
}

.roomAdded {
  padding: 7px 7px 21px 7px;
  height: auto;
  .pax-age-added{
    padding: 0 14px 0px 14px;
  }
}
.age-section-container {
  margin-top: 21.85px;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 300;

  p {
    font-weight: 700;
    line-height: 18.9px;
  }

  .age-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .sub-age-container {
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      flex: 50%;

      .category-title {
        color: #434343;
        // leading-trim: both;
        // text-edge: cap;
        // font-family: Satoshi;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        // margin-bottom: 16px;
      }

      .select-age-wrapper {
        width: 67px;
        height: 34px;
        // display: flex;
        // justify-content: center;
        // align-items: center;

        .age-choice-container {
          width: 67px;
          height: 98px;
          flex-shrink: 0;
          border-radius: 10px;
          position: relative;
          background-color: #FFFFFF;
          color: #434343;
          overflow-y: auto;
          z-index:1000;
          margin-top: 9px;
          box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
          font-weight: 300;
          font-size: 18px;
          
          &::-webkit-scrollbar {
            width: 20px;
            height: auto;
          }

          &::-webkit-scrollbar-track {
            border-radius: 10px;
            background: #FFFFFF;
          }

          &::-webkit-scrollbar-thumb {
            min-height: 54px;
            border-radius: 10px;
            background: #D9D9D9;
            border: solid 8px transparent;
            background-clip: content-box;
          }

          div:first-child{
            margin-top:7px;
          }

          div{
            margin: 12px 0 0 13px;
            width: 29px;
            max-height: 21px;
            padding: 4px 7px;
            border-radius: 7px;
            // text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover{
              background: #EBEBEB;
              
              cursor: pointer;
            }
            
            &.selected{
              background: #0F7173;
              color:#FFFFFF;
            }
          }
        }

        .select-age {
          box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          border: none;
          background: #FFF;
          height: 100%;
          width: 100%;
          cursor: pointer;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 13px;
          font-size: 18px;
          font-weight: 400;
          line-height: 24.3px;
          color: #666666;

          &::after {
            content: "";
            background-image: url("../../images/calendar/arrowLightGrey.png");
            background-repeat: no-repeat;
            width: 9px;
            height: 9px;
            background-size: 9px 9px;
            background-position: center center;
            position: relative;
            margin-left:auto;
            margin-right:9px;
            // right: 10px+9px;
            transition: transform 0.2s ease;
          }

          &.clicked{
            box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
            background: #EBEBEB;

            &::after {
              transform: rotate(180deg);
            }
             
          }

          &:hover {
            // border-radius: 7px;
            box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.25);
            background: #EBEBEB;

            &::after {
              transform: rotate(180deg);
            }
          }

          &.disabled{
            background: #E6BBBB;
          }
        }
      }
    }
  }
  .errMsg{
    color: #C16161;
    font-size: 14px;
    font-weight: 300;
    margin: 15px 0 10px 0;
  }
}
  .age-section-container-booking{
    margin-top: 21.85px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 300;
    p {
      font-weight: 600;
      line-height: 18.9px;
      margin-bottom: 3px;
    }
    span.min-pax{
      color: #0F7173;
    }
    &-inner{
      display: flex;
      column-gap: 2px;
    }
  }
  

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes padding-out{
  from{
    padding-top:20px;
  }
  to{
    padding-top:0px;
  }
}


.fade-in {
  animation: fade-in 0.3s forwards;
}

.fade-out {
  animation: fade-out 0.3s forwards;
}

.fading-pax-height {
  animation: fade-in 0.35s ease-out;
}

// #box {
//   overflow: hidden;
//   transition: height 0.5s ease;
// }


@for $i from 1 through 10{
  .fade-in-#{$i*100}{
    @include fade-in($i*0.1);
  }
  .fade-out-#{$i*100}{
    @include fade-out($i*0.1);
  }

  .fade-in-visibility-#{$i*100}{
    @include fade-in($i*0.1);
    visibility: visible;
    transition: visibility #{$i*0.1}s ease;
  }
  .fade-out-visibility-#{$i*100}{
    @include fade-out($i*0.1);
    visibility: hidden;
    transition: visibility #{$i*0.1}s ease;
  }
}